import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import {
 Search,
 Notifications,
 Settings,
 PersonAdd,
 Logout,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import {
 Menu,
 MenuItem,
 Tooltip,
 useMediaQuery,
 Drawer as MuiTemporaryDrawer,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/action";
import LOGO from "../Assests/janta_logo.png";
import Notification from "./NotificationAPI";

const drawerWidth = 210;
const getBackgroundColor = (role) => {
 if (role === "superadmin") {
  return "#000C8E"; // Red for admin
 } else if (role === "distributor") {
  return "#5E5E5E"; // Default blue for user
 }
 return "#000C8E"; // Default fallback color
};
const openedMixin = (theme, role) => ({
 width: drawerWidth,
 transition: theme.transitions.create("width", {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.enteringScreen,
 }),
 overflowX: "hidden",
 backgroundColor: getBackgroundColor(role),
 color: "#ffffff",
});

const closedMixin = (theme, role) => ({
 transition: theme.transitions.create("width", {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
 }),
 overflowX: "hidden",
 width: `calc(${theme.spacing(7)} + 1px)`,
 [theme.breakpoints.up("sm")]: {
  width: `calc(${theme.spacing(8)} + 1px)`,
 },
 backgroundColor: getBackgroundColor(role),
 color: "#ffffff",
});

const DrawerHeader = styled("div")(({ theme }) => ({
 display: "flex",
 alignItems: "center",
 justifyContent: "flex-end",
 padding: theme.spacing(0, 1),
 ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
 shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
 zIndex: theme.zIndex.drawer + 1,
 backgroundColor: "#FFFFFF",
 boxShadow: "none",
 color: "#ffffff",
 transition: theme.transitions.create(["width", "margin"], {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
 }),
 ...(open && {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(["width", "margin"], {
   easing: theme.transitions.easing.sharp,
   duration: theme.transitions.duration.enteringScreen,
  }),
 }),
}));

const Drawer = styled(MuiDrawer, {
 shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, role }) => ({
 width: drawerWidth,
 flexShrink: 0,
 whiteSpace: "nowrap",
 boxSizing: "border-box",
 ...(open && {
  ...openedMixin(theme, role),
  "& .MuiDrawer-paper": openedMixin(theme, role),
 }),
 ...(!open && {
  ...closedMixin(theme, role),
  "& .MuiDrawer-paper": closedMixin(theme, role),
 }),
}));

export default function MiniDrawer({ children, menuItems }) {
 const { isAuthenticated, user, loading, error } = useSelector(
  (state) => state.auth
 );

 const role = user?.data?.role || "";
 console.log("role", role);
 // console.log("error:", error);
 const theme = useTheme();
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const location = useLocation();
 const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

 const [drawerOpen, setDrawerOpen] = useState(true);
 const [menuAnchorEl, setMenuAnchorEl] = useState(null);
 const [messageAPI, setMessageAPI] = useState(false); // State to control Snackbar visibility
 const [message, setMessage] = useState(""); // State for the message to show
 const [severity, setSeverity] = useState("success"); // State for Snackbar severity
 // Function to get the page title based on the current pathname
 const getPageTitle = () => {
  // Combine both admin and distributor menu items
  const allMenuItems = menuItems || []; // Ensure menuItems is not undefined
  // Find the matching menu item based on the current pathname
  const currentItem = allMenuItems.find(
   (item) => location.pathname === item.link
  );
  // Return the page title or a default value if no match is found
  return currentItem ? currentItem.text : "Dashboard";
 };

 const handleDrawerToggle = () => {
  setDrawerOpen(!drawerOpen);
 };

 const handleDrawerClose = () => {
  setDrawerOpen(false);
 };

 const handleMenuClick = (event) => {
  setMenuAnchorEl(event.currentTarget);
 };

 const handleMenuClose = () => {
  setMenuAnchorEl(null);
 };

 const handleMessageAPI = () => {
  setMessageAPI(false); // Close the Snackbar
 };

 // const handleLogout = () => {
 //   const redirect = () => {
 //     if (user.role === "superadmin") {
 //       navigate("/admin/login");
 //     } else if (user.role === "distributer") {
 //       navigate("/distributer/login");
 //     } else {
 //       navigate("/admin/login");
 //     }
 //   };

 //   dispatch(logoutUser(redirect));
 // };

 // const handleLogout = () => {
 //   const redirect = () => {
 //     if (user.role === "superadmin") {
 //       navigate("/admin/login");
 //     } else if (user.role === "distributor") {
 //       navigate("/distributor/login");
 //     }
 //     else {
 //       navigate("/admin/login"); // Fallback to /admin/login if role is unknown
 //     }
 //   };
 //   setMessageAPI(true);
 //   setMessage("Logout Successfully");
 //   setSeverity("success");
 //   setTimeout(() => {
 //     dispatch(logoutUser(redirect));
 //   }, 1000);
 // };
 const handleLogout = () => {
  const redirect = () => {
   if (user.role === "superadmin") {
    console.log("superadmin:", user.role);
    navigate("/admin/login");
   } else if (user.role === "distributor") {
    console.log("distributor:", user.role);
    navigate("/distributor/login");
   } else {
    navigate("/admin/login"); // Fallback to /admin/login
   }
  };

  // Dispatch the logout action and pass the redirect function
  dispatch(logoutUser(redirect));
 };

 const isMenuOpen = Boolean(menuAnchorEl);

 const handleProfileClick = () => {
  navigate("/admin/profile");
  handleMenuClose();
 };

 const renderDrawer = () => (
  <List>
   {menuItems?.map((item) => {
    const isActive = location.pathname === item.link;
    return (
     <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
      <ListItemButton
       component={Link}
       to={item.link}
       sx={{
        minHeight: 50,
        justifyContent: drawerOpen ? "initial" : "center",
        px: 2,
        backgroundColor: isActive ? "#FFBF2A" : "inherit",
        color: isActive ? "#000000" : "inherit",
        "&:hover": {
         backgroundColor: isActive ? "#FFBF2A" : "inherit",
        },
       }}
      >
       <ListItemIcon
        sx={{
         minWidth: drawerOpen ? "auto" : "initial",
         justifyContent: "center",
         alignItems: "center",
        }}
       >
        {isActive ? item.activeIcon : item.icon}
       </ListItemIcon>
       {drawerOpen && ( // Only show text when drawer is open
        <Typography
         fontSize={{ md: "0.8rem" }}
         sx={{ ml: 2 }} // Add space between icon and text
        >
         {item.text}
        </Typography>
       )}
      </ListItemButton>
     </ListItem>
    );
   })}
  </List>
 );

 return (
  <Box sx={{ display: "flex", width: "100%" }}>
   <CssBaseline />
   <AppBar position="fixed" open={drawerOpen}>
    <Toolbar>
     <IconButton
      // color="#000C8E"
      aria-label="open drawer"
      onClick={handleDrawerToggle}
      edge="start"
      sx={{
       marginRight: 5,
       color: "#000C8E",
      }}
     >
      <MenuIcon />
     </IconButton>
     {/* Dynamic Page Title */}
     <Typography variant="h6" noWrap component="div" color={"#000C8E"}>
      {getPageTitle()}
     </Typography>
     <Box sx={{ flexGrow: 1 }} />
     <Box
      sx={{
       position: "relative",
       borderRadius: "25px",
       backgroundColor: "#E9E9E9",
       marginRight: "16px",
       width: "200px",
       p: 0.4,
      }}
     >
      <InputBase
       placeholder="Search…"
       sx={{
        paddingLeft: "10px",
        width: "100%",
        color: "#494949",
       }}
       inputProps={{ "aria-label": "search" }}
      />
      <IconButton
       type="submit"
       sx={{ position: "absolute", right: 0, top: 0, color: "#494949" }}
      >
       <Search />
      </IconButton>
     </Box>
     <IconButton color="#000C8E">
      <Notifications />
     </IconButton>
     <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
      <Tooltip title="Account settings">
       <IconButton
        onClick={handleMenuClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={isMenuOpen ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
       >
        <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
       </IconButton>
      </Tooltip>
     </Box>
     <Menu
      anchorEl={menuAnchorEl}
      id="account-menu"
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      PaperProps={{
       elevation: 0,
       sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "& .MuiAvatar-root": {
         width: 32,
         height: 32,
         ml: -0.5,
         mr: 1,
        },
        "&::before": {
         content: '""',
         display: "block",
         position: "absolute",
         top: 0,
         right: 14,
         width: 10,
         height: 10,
         bgcolor: "background.paper",
         transform: "translateY(-50%) rotate(45deg)",
         zIndex: 0,
        },
       },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
     >
      <MenuItem onClick={handleProfileClick}>
       <Avatar /> Profile
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
       <Avatar /> My account
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleMenuClose}>
       <ListItemIcon>
        <PersonAdd fontSize="small" />
       </ListItemIcon>
       Add another account
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
       <ListItemIcon>
        <Settings fontSize="small" />
       </ListItemIcon>
       Settings
      </MenuItem>
      <MenuItem
       onClick={() => {
        handleMenuClose();
        handleLogout();
       }}
      >
       <ListItemIcon>
        <Logout fontSize="small" />
       </ListItemIcon>
       Logout
      </MenuItem>
     </Menu>
    </Toolbar>
    <Notification
     messageAPI={messageAPI}
     message={message}
     severity={severity}
     handleMessageAPI={handleMessageAPI}
    />
   </AppBar>

   {isMobile ? (
    <MuiTemporaryDrawer
     variant="temporary"
     open={drawerOpen}
     onClose={handleDrawerToggle}
     ModalProps={{
      keepMounted: true, // Better open performance on mobile.
     }}
     sx={{
      "& .MuiDrawer-paper": {
       boxSizing: "border-box",
       width: drawerWidth,
       backgroundColor: "#000C8E",
       color: "#ffffff",
      },
     }}
    >
     {renderDrawer()}
    </MuiTemporaryDrawer>
   ) : (
    <Drawer variant="permanent" open={drawerOpen} role={role}>
     <DrawerHeader>
      <Typography
       variant="h8"
       component="div"
       sx={{ flexGrow: 1, color: "#ffffff", paddingLeft: "16px" }}
      >
       Janta Ride
      </Typography>
     </DrawerHeader>
     <Divider sx={{ backgroundColor: "#FFFFFF" }} />
     {renderDrawer()}
    </Drawer>
   )}

   <Box
    component="main"
    sx={{
     flexGrow: 1,
     p: 3,
     backgroundColor: "#EDF2F7",
     minHeight: "100vh",
    }}
   >
    <DrawerHeader />
    {children}
   </Box>
  </Box>
 );
}
