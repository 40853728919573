import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  MenuItem,
  Modal,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DashboardHeaderCards from "./DashboardHeaderCards";
import { Dialog, DialogContent } from "@mui/material";
import DistributorForm from "./distributer/DistributorForm";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ImageModal from "./ImageModal";

const ManageCars = () => {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [totalPages, setTotalPages] = useState(0);
  const [block, setBlock] = useState(false);
  const [approve, setApprove] = useState(false);
  const [DetailedById, setDetailedById] = useState(null);
  const [cmtMessage, setCmtMessage] = useState("");
  const [totalDetails, setTotalDetails] = useState({});
  const [filters, setFilters] = useState({
    searchText: "",
    selectedStatus: "",
    selectedDate: "",
  });
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const cardData = [
    {
      title: "Total Added Cars",
      value: totalDetails.totalDrivers,
      icon: "/car.svg",
    },
    {
      title: "Cars Approved",
      value: totalDetails.totalDriversApproved,
      icon: "/distributors_approve.svg",
    },
    {
      title: "Cars Rejected",
      value: totalDetails.totalDriversRejected,
      icon: "/distribytor_reject.svg",
    },
  ];
  const location = useLocation();
  const { id } = location.state || {};

  useEffect(() => {
    fetchDistributors();
  }, [filters, page, pageSize]);

  const handleBlockOpen = (id) => {
    setBlock(true);
    getDetailSingle(id);
  };

  const handleBlockClose = () => {
    setBlock(false);
  };

  const handleBlockAPI = async (id, isCurrentlyBlocked) => {
    try {
      const newBlockState = !isCurrentlyBlocked;
      const res = await axios.put(
        `https://api.janatarides.com/api/admin/drivers/${id}`,
        {
          isBlocked: newBlockState, // Set the new block state
        },
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );

      fetchDistributors();
      handleBlockClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleApproveAPI = async (id, updateApprove) => {
    try {
      const res = await axios.put(
        `https://api.janatarides.com/api/admin/drivers/${id}`,
        {
          status: updateApprove,
          message: cmtMessage,
        },
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );
      fetchDistributors();
      handleApproveClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleOpenImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsImageModalOpen(true);
  };
  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };
  const handleApproveOpen = async (id) => {
    setApprove(true);
    getDetailSingle(id);
  };

  const getDetailSingle = async (id) => {
    try {
      const res = await axios.get(
        `https://api.janatarides.com/api/admin/drivers/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );
      setDetailedById(res.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleApproveClose = () => {
    setApprove(false);
  };
  const fetchDistributors = async () => {
    let api = id
      ? `api/admin/distributors/${id}/drivers?page=${page}&pageSize=${pageSize}`
      : `api/admin/drivers?page=${page}&pageSize=${pageSize}`;

    try {
      const res = await axios.get(`https://api.janatarides.com/${api}`, {
        headers: {
          Authorization: localStorage.getItem("admin_access"),
        },
      });
      const filteredData = res.data.data.filter((driver) => {
        const matchesSearchText =
          driver.driverName
            .toLowerCase()
            .includes(filters.searchText.toLowerCase()) ||
          (driver.mobile && driver.mobile.includes(filters.searchText));

        const matchesStatus = filters.selectedStatus
          ? driver.status === filters.selectedStatus
          : true;

        const matchesDate = filters.selectedDate
          ? new Date(driver.createdAt).toISOString().slice(0, 10) ===
            new Date(filters.selectedDate).toISOString().slice(0, 10)
          : true;

        return matchesSearchText && matchesStatus && matchesDate;
      });

      const rows = filteredData.map((driver) => ({
        id: driver._id,
        driverDetails: driver,
      }));
      setDetails(rows);
      setTotalPages(res.data.pagination.totalPages);
      setTotalDetails(res.data.pagination);
    } catch (err) {
      console.error("Error fetching distributors:", err);
    }
  };

  const handleDownloadDriver = async () => {
    try {
      const { data } = await axios.get(
        "https://api.janatarides.com/api/admin/drivers?limit=1000",
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );

      const exportData = data.data.map((dist) => ({
        ID: dist._id,
        Name: dist.driverName,
        Email: dist.email,
        Mobile: dist.mobile,
        Address: dist.address,
        Bank_Name: dist.bankName,
        Account_Number: dist.accountNumber,
        Account_HolderName: dist.accountHolderName,
        IFSC: dist.ifscCode,
        CarModel_Name: dist.carModelName,
        Registration_Number: dist.registrationNumber,
        Service_Type: dist.serviceType.join(", "),
        distributor: dist.distributor.name,
        vehicle_type: dist.vehicalType,
        Status: dist.status,
        Blocked: dist.isOnline === false ? "Published" : "Blocked",
        "Created At": new Date(dist.createdAt).toLocaleString(),
      }));

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Driver");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, "Driver.xlsx");
    } catch (error) {
      console.error("Error downloading driver:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage + 1); // DataGrid's page index starts at 0, API's page starts at 1
    fetchDistributors(newPage + 1, pageSize);
  };

  // Handle page size change
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    fetchDistributors(page, newPageSize);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const distributorColumns = [
    {
      field: "driverDetails",
      headerName: "Driver Details",
      // width: 350,
      flex: 1.5,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Driver Details
        </div>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: { md: "start", xl: "center" },
            gap: { md: "20px", xl: "50px" },
            flexDirection: { md: "column", xl: "row" },
          }}
          py={2}>
          <Box>
            <img
              // src={params.row.driverDetails?.profile || "/default_profile.png"} // Use a fallback image if profile is missing
              src={
                params.row.driverDetails.driverProfilePicUrl ||
                "/default_profile.png"
              }
              alt="Profile"
              style={{
                width: "80px",
                height: "80px",
                // backgroundColor: "#ccc",
                marginRight: "16px",
                objectFit: "cover",
              }}
            />
            <Typography
              fontSize={{ xs: 10, md: 12, xl: 13 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.driverDetails.driverName}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { md: "80px auto", xl: "100px auto" },

              gap: "7px",
            }}>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              ID :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.driverDetails._id}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              Email :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.driverDetails.email}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Mobile :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.driverDetails.mobile}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Address :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.driverDetails.address}
            </Typography>

            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Joined on :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {new Date(
                params.row.driverDetails.createdAt
              ).toLocaleDateString()}{" "}
              {new Date(
                params.row.driverDetails.createdAt
              ).toLocaleTimeString()}
            </Typography>

            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Service Type :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.driverDetails.serviceType.join(", ")}
            </Typography>

            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Distributor :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.driverDetails.distributor.name}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Vehicle Type
            </Typography>
            <Typography fontSize={{ xs: 8, md: 11, xl: 12 }}>
              {params.row.driverDetails.vehicalType}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "bankDetails",
      headerName: "Bank Details",
      // width: 500,
      flex: 1.3,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Bank Details
        </div>
      ),
      renderCell: (params) => (
        <Box
          py={2}
          sx={{
            display: "grid",
            gridTemplateColumns: { md: "100px auto", xl: "120px auto" },
            gap: "10px",
          }}>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            Name :
          </Typography>
          <Typography
            fontSize={{ xs: 10, md: 10, xl: 12 }}
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}>
            {params.row.driverDetails.accountHolderName}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            Bank Name :
          </Typography>
          <Typography
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}
            fontSize={{ xs: 10, md: 10, xl: 12 }}>
            {params.row.driverDetails.bankName}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            Account Number :
          </Typography>
          <Typography
            fontSize={{ xs: 10, md: 10, xl: 12 }}
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}>
            {params.row.driverDetails.accountNumber}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            IFSC :
          </Typography>
          <Typography
            fontSize={{ xs: 10, md: 10, xl: 12 }}
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}>
            {params.row.driverDetails.ifscCode}
          </Typography>
        </Box>
      ),
    },

    {
      field: "carDetails",
      headerName: "Car Details",
      // flex: 1,
      // width: 300,
      flex: 0.7,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Car Details
        </div>
      ),
      renderCell: (params) => (
        <Box>
          <img
            src={params.row.driverDetails.carFrontendPicUrl}
            alt="Profile"
            style={{
              width: "80px",
              height: "80px",
              backgroundColor: "#ccc",
              marginRight: "16px",
              objectFit: "cover",
            }}
          />
          <Box>
            <Typography fontSize={{ xs: 8, md: 11, xl: 12 }}>
              {" "}
              {params.row.driverDetails.carModelName}
            </Typography>
            <Typography fontSize={{ xs: 8, md: 11, xl: 12 }}>
              {params.row.driverDetails.registrationNumber}
            </Typography>
          </Box>
        </Box>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      // width: 140,
      flex: 0.5,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Status</div>
      ),
      renderCell: (params) => (
        <Box display={"flex"} flexDirection={"column"} gap={2} py={2}>
          <Box>
            {params.row.driverDetails.status === "approved" && (
              <Button
                sx={{
                  fontSize: { xs: 4, md: 8, xl: 13 },
                  backgroundColor: "#C6EBE5",
                  color: "#0DBA9F",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#C6EBE5",
                  },
                }}>
                Approved
              </Button>
            )}

            {params.row.driverDetails.status === "pending" && (
              <Button
                sx={{
                  fontSize: { xs: 4, md: 8, xl: 13 },
                  backgroundColor: "#D5C6F3",
                  color: "#4A00E0",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#D5C6F3",
                  },
                }}>
                Pending
              </Button>
            )}

            {params.row.driverDetails.status === "rejected" && (
              <Button
                sx={{
                  fontSize: { xs: 4, md: 8, xl: 13 },
                  backgroundColor: "#EECACA",
                  color: "#D66262",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#EECACA",
                  },
                }}>
                Rejected
              </Button>
            )}
          </Box>

          <Box>
            {params.row.driverDetails.isBlocked ? (
              <Button
                sx={{
                  fontSize: { xs: 4, md: 8, xl: 13 },
                  backgroundColor: "#EECACA",
                  color: "#D66262",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#EECACA",
                  },
                }}>
                Blocked
              </Button>
            ) : (
              <Button
                sx={{
                  fontSize: { xs: 4, md: 8, xl: 13 },
                  backgroundColor: "#C6EBE5",
                  color: "#0DBA9F",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#C6EBE5",
                  },
                }}>
                Published
              </Button>
            )}
          </Box>
        </Box>
      ),
    },

    {
      field: "action",
      headerName: "Action",
      // width: 140,
      flex: 0.6,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Action</div>
      ),
      renderCell: (params) => (
        <Box display={"flex"} flexDirection={"column"} gap={1} py={2}>
          <Button
            sx={{
              fontSize: { xs: 4, md: 8, xl: 13 },
              backgroundColor: "#C6EBE5",
              color: "#0DBA9F",
              fontWeight: "bold",
            }}
            onClick={() => handleApproveOpen(params.row.driverDetails._id)}>
            Approve
          </Button>

          <Button
            sx={{
              fontSize: { xs: 6, md: 10, xl: 13 },
              backgroundColor: "#EECACA",
              color: "#D66262",
              fontWeight: "bold",
            }}
            onClick={() => handleBlockOpen(params.row.driverDetails._id)}>
            {params.row.driverDetails.isBlocked ? "Unblock" : "Block"}
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <DashboardHeaderCards cardData={cardData} />
      <Box pt={4}>
        {/* <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FFC107",
              color: "black",
              height: "48px",
              padding: "0 16px",
              minWidth: "160px",
              fontWeight: "bold",
            }}
            onClick={handleOpen} // Attach the handleOpen function here
          >
            Add New Car
          </Button>
        </Box> */}

        <Grid container spacing={2} alignItems="center" py={4}>
          <Grid item xs={12} sm={3}>
            <TextField
              InputLabelProps={{
                sx: {
                  fontSize: { md: "0.7rem", lg: "1rem" }, // Responsive font sizes for label
                },
              }}
              label="Search by Name or Mobile"
              variant="outlined"
              fullWidth
              value={filters.searchText}
              onChange={(e) =>
                setFilters({ ...filters, searchText: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              InputLabelProps={{
                sx: {
                  fontSize: { md: "0.7rem", lg: "1rem" }, // Responsive font sizes for label
                },
              }}
              label="Select Car Status"
              variant="outlined"
              fullWidth
              select
              value={filters.selectedStatus}
              onChange={(e) =>
                setFilters({ ...filters, selectedStatus: e.target.value })
              }>
              <MenuItem value="">ALL</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="pending">Approval Pending</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </TextField>
          </Grid>

          {/* Date Picker */}
          <Grid item xs={12} sm={3}>
            <TextField
              InputLabelProps={{
                sx: {
                  fontSize: { md: "0.7rem", lg: "1rem" }, // Responsive font sizes for label
                },
              }}
              // label="dd/mm/yyyy"
              type="date"
              variant="outlined"
              fullWidth
              value={filters.selectedDate}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  selectedDate: e.target.value,
                })
              }
            />
          </Grid>

          {/* Export Button aligned with inputs */}
          <Grid item xs={12} sm={3} textAlign="right">
            <Button
              variant="contained"
              startIcon={
                <img
                  src={"/export.svg"}
                  alt="Export Icon"
                  style={{ width: "20px", height: "24px" }}
                />
              }
              sx={{
                backgroundColor: "#FFC107",
                color: "black",
                height: "48px",
                minWidth: "160px",
                fontWeight: "bold",
              }}
              onClick={() => handleDownloadDriver()}>
              Export
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* <Box
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: `calc(90vh - 150px)`,
        }}>
        <Box sx={{ flexGrow: 1, minHeight: "400px" }}> */}
      <DataGrid
        rows={details}
        columns={distributorColumns}
        getRowId={(row) => row.driverDetails._id}
        pageSize={pageSize}
        rowCount={totalPages * pageSize} // Set the total number of rows
        paginationMode="server" // Enable server-side pagination
        onPageChange={handlePageChange} // Handle page changes
        onPageSizeChange={handlePageSizeChange} // Handle page size changes
        page={page - 1} // DataGrid uses 0-indexed page, API uses 1-indexed
        autoHeight={"auto"} // Set auto height for the grid
        getRowHeight={() => "auto"}
        pagination
        sx={{
          width: "100%", // Ensure the grid takes full width of the container
          "& .MuiDataGrid-columnHeader": {
            fontWeight: "bold",
            fontSize: { xs: "0.6rem", md: "0.8rem" }, // Responsive font size
          },
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal", // Wrap text for better responsiveness
            padding: { xs: "8px", md: "16px" }, // Responsive padding
          },
        }}
      />
      {/* </Box>
      </Box> */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        {/* <DialogTitle>Add New Car</DialogTitle> */}
        <DialogContent sx={{ backgroundColor: "transparent" }}>
          <DistributorForm />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions> */}
      </Dialog>

      <Modal open={block} onClose={handleBlockClose}>
        {DetailedById ? (
          <Box
            sx={{
              p: 3,
              width: { md: "60%", xl: "30%" },
              margin: "auto",
              mt: "20vh",
              borderRadius: "md",
              backgroundColor: "#D9D9D9",
            }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <IconButton onClick={handleBlockClose} sx={{ color: "red" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography
              fontWeight={"bold"}
              fontSize={18}
              textAlign={"center"}
              mb={3}>
              Block/Unblock Cars/Driver
            </Typography>

            <Typography textAlign={"center"} mb={6}>
              {DetailedById.isBlocked
                ? "Do you want to unblock this Car/Driver?"
                : "Do you want to block this Car/Driver??"}
            </Typography>

            <Box textAlign={"center"}>
              <Typography textAlign={"center"}>
                {DetailedById.registrationNumber}
              </Typography>
              <Typography textAlign={"center"}>
                Driver : {DetailedById.driverName}
              </Typography>
              <Typography textAlign={"center"}>Distributor:</Typography>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              mt={2}>
              <Button
                sx={{
                  backgroundColor: "#F4E6C4",
                  color: "black",
                  fontWeight: "bold",
                  p: 2,
                }}
                onClick={handleBlockClose}>
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#FFBF2A",
                  color: "black",
                  fontWeight: "bold",
                  p: 2,
                }}
                onClick={() => {
                  // Add your block logic here
                  handleBlockAPI(DetailedById._id, DetailedById.isBlocked);
                }}>
                {DetailedById.isBlocked ? "Unblock" : "Block"}
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography>Loading...</Typography> // Fallback to show loading state or empty content
        )}
      </Modal>

      <Modal open={approve} onClose={handleApproveClose}>
        {DetailedById ? (
          <Box
            sx={{
              p: 4,
              width: { md: "80%", xl: "60%" },
              margin: "auto",
              mt: "5vh",
              borderRadius: "8px",
              backgroundColor: "#F5F5F5",
              boxShadow: 24,
              maxHeight: "90vh",
              overflowY: "auto",
            }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <IconButton onClick={handleApproveClose} sx={{ color: "red" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            {/* Modal Title */}
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 18, md: 20, xl: 24 }}
              textAlign={"center"}
              mb={3}>
              Approve Vehicle/Driver
            </Typography>

            {/* Distributor Details */}

            <Box display="flex" gap={12} mb={3}>
              <Box>
                <Typography
                  fontWeight="bold"
                  fontSize={{ xs: 13, md: 14, xl: 15 }}
                  mb={2}>
                  Driver Details
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mb={3}
                  gap={6}>
                  <Box
                    onClick={() =>
                      handleOpenImageModal(DetailedById.driverProfilePicUrl)
                    }
                    style={{ cursor: "pointer" }}>
                    <Box
                      sx={{
                        width: { xs: 100, lg: 120 },
                        height: { xs: 100, lg: 120 },
                        backgroundColor: "white",
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}>
                      <img
                        src={
                          DetailedById?.driverProfilePicUrl ||
                          "/default_profile.png"
                        }
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Typography
                      fontWeight="medium"
                      fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      {DetailedById.driverName}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "100px auto",
                      gap: "10px",
                    }}>
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      ID
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {DetailedById._id}
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      Email
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {DetailedById.email}
                    </Typography>
                    {/* static name and bank name */}
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      Mobile
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {DetailedById.mobile}
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      Address
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {DetailedById.address}
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      Joined on
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {new Date(DetailedById.createdAt).toLocaleDateString()}
                    </Typography>

                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      Distributor
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {DetailedById.distributor?.name || "N/A"}
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      Vehicle Type
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {DetailedById.vehicalType}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Bank Details */}
              <Box display="flex" flexDirection={"column"} gap={1}>
                <Typography
                  fontWeight="bold"
                  fontSize={{ xs: 10, md: 14, xl: 15 }}>
                  Bank Details
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "120px auto",
                    gap: "10px",
                  }}>
                  <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                    Name
                  </Typography>
                  <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                    {DetailedById.accountHolderName}
                  </Typography>
                  <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                    Bank Name
                  </Typography>
                  <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                    {DetailedById.bankName}
                  </Typography>
                  {/* static name and bank name */}
                  <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                    Account Number
                  </Typography>
                  <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                    {DetailedById.accountNumber}
                  </Typography>
                  <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                    IFSC Code
                  </Typography>
                  <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                    {DetailedById.ifscCode}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Horizontal Divider */}
            <Box mb={3}>
              <hr />
            </Box>

            <Box pb={10}>
              <Typography
                fontSize={{ xs: 10, md: 14, xl: 15 }}
                fontWeight={"bold"}>
                Service Choosen
              </Typography>
              <Typography
                fontSize={{ xs: 10, md: 12, xl: 14 }}
                fontWeight={"md"}>
                {DetailedById.serviceType &&
                  DetailedById.serviceType.join(", ")}
              </Typography>
            </Box>

            {/* Document Upload Section */}
            <Box>
              <Typography fontWeight={"bold"} mb={4}>
                Vehicle Details
              </Typography>
              <Box display={"flex"} flexDirection={"column"} gap={6} mb={5}>
                {/* Aadhar Card */}

                <Box display={"flex"} gap={10} flexWrap={"wrap"}>
                  <Box textAlign="center">
                    <Box
                      onClick={() =>
                        handleOpenImageModal(DetailedById.carFrontendPicUrl)
                      }
                      style={{ cursor: "pointer" }}>
                      <Box
                        sx={{
                          width: { xs: 100, lg: 120 },
                          height: { xs: 100, lg: 120 },
                          backgroundColor: "white",
                          mb: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                        }}>
                        <img
                          src={DetailedById.carFrontendPicUrl}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "4px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                        {DetailedById.carModelName}
                      </Typography>
                      <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                        {DetailedById.registrationNumber}
                      </Typography>
                    </Box>
                  </Box>

                  {/* PAN Card */}
                  <Box
                    textAlign="center"
                    onClick={() =>
                      handleOpenImageModal(DetailedById.carRcPicUrl)
                    }
                    style={{ cursor: "pointer" }}>
                    <Box
                      sx={{
                        width: { xs: 100, lg: 120 },
                        height: { xs: 100, lg: 120 },
                        backgroundColor: "white",
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}>
                      <img
                        src={DetailedById.carRcPicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      Vehicle RC
                    </Typography>
                  </Box>

                  <Box
                    textAlign="center"
                    onClick={() =>
                      handleOpenImageModal(DetailedById.drivingLicencePicUrl)
                    }
                    style={{ cursor: "pointer" }}>
                    <Box
                      sx={{
                        width: { xs: 100, lg: 120 },
                        height: { xs: 100, lg: 120 },
                        backgroundColor: "white",
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}>
                      <img
                        src={DetailedById.drivingLicencePicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      Driving Licence
                    </Typography>
                  </Box>

                  <Box
                    textAlign="center"
                    onClick={() =>
                      handleOpenImageModal(DetailedById.insurancePicUrl)
                    }
                    style={{ cursor: "pointer" }}>
                    <Box
                      sx={{
                        width: { xs: 100, lg: 120 },
                        height: { xs: 100, lg: 120 },
                        backgroundColor: "white",
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}>
                      <img
                        src={DetailedById.insurancePicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      Vehicle Insurance
                    </Typography>
                  </Box>

                  {DetailedById.vehicalType === "car" && (
                    <Box
                      textAlign="center"
                      onClick={() =>
                        handleOpenImageModal(DetailedById.fitnessPicUrl)
                      }
                      style={{ cursor: "pointer" }}>
                      <Box
                        sx={{
                          width: { xs: 100, lg: 120 },
                          height: { xs: 100, lg: 120 },
                          backgroundColor: "white",
                          mb: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                        }}>
                        <img
                          src={DetailedById.fitnessPicUrl}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "4px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                        Vehicle Fitness
                      </Typography>
                    </Box>
                  )}

                  <Box
                    textAlign="center"
                    onClick={() =>
                      handleOpenImageModal(DetailedById.pollutionPicUrl)
                    }
                    style={{ cursor: "pointer" }}>
                    <Box
                      sx={{
                        width: { xs: 100, lg: 120 },
                        height: { xs: 100, lg: 120 },
                        backgroundColor: "white",
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}>
                      <img
                        src={DetailedById.pollutionPicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      Vehicle Pollution
                    </Typography>
                  </Box>

                  <Box
                    textAlign="center"
                    onClick={() =>
                      handleOpenImageModal(DetailedById.checkbookPicUrl)
                    }
                    style={{ cursor: "pointer" }}>
                    <Box
                      sx={{
                        width: { xs: 100, lg: 120 },
                        height: { xs: 100, lg: 120 },
                        backgroundColor: "white",
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}>
                      <img
                        src={DetailedById.checkbookPicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      Check Book
                    </Typography>
                  </Box>
                </Box>

                <Box display={"flex"} gap={10}>
                  <Box
                    textAlign="center"
                    onClick={() =>
                      handleOpenImageModal(DetailedById.aadhaarFrontPicUrl)
                    }
                    style={{ cursor: "pointer" }}>
                    <Box
                      sx={{
                        width: { xs: 100, lg: 120 },
                        height: { xs: 100, lg: 120 },
                        backgroundColor: "white",
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}>
                      <img
                        src={DetailedById.aadhaarFrontPicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      Aadhaar front
                    </Typography>
                  </Box>

                  <Box
                    textAlign="center"
                    onClick={() =>
                      handleOpenImageModal(DetailedById.aadhaarBackPicUrl)
                    }
                    style={{ cursor: "pointer" }}>
                    <Box
                      sx={{
                        width: { xs: 100, lg: 120 },
                        height: { xs: 100, lg: 120 },
                        backgroundColor: "white",
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}>
                      <img
                        src={DetailedById.aadhaarBackPicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      Aadhar Back
                    </Typography>
                  </Box>

                  <Box
                    textAlign="center"
                    onClick={() =>
                      handleOpenImageModal(DetailedById.panCardPicUrl)
                    }
                    style={{ cursor: "pointer" }}>
                    <Box
                      sx={{
                        width: { xs: 100, lg: 120 },
                        height: { xs: 100, lg: 120 },
                        backgroundColor: "white",
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}>
                      <img
                        src={DetailedById.panCardPicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      Pan Card
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Comments Section */}
            {/* <Box mb={3}>
            <Typography fontWeight="bold">Comments</Typography>
            <Box
              sx={{
                width: "100%",
                height: 60,
                backgroundColor: "#FFFFFF",
                borderRadius: 2,
                border: "1px solid #CCCCCC",
                p: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <CameraAltIcon sx={{ mr: 1 }} />
             <TextareaAutosize />
            </Box>
          </Box> */}

            <Box
              sx={{
                backgroundColor: "#D9D9D9",
                borderRadius: 2,
                py: 2,
                px: 2,
              }}>
              <TextField
                name="cmtMessage"
                value={cmtMessage}
                fontSize={{ xs: 10, md: 14, xl: 15 }}
                sx={{
                  width: "100%",
                  height: "10vh",
                  border: "none",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", // Hide the border
                    },
                    "&:hover fieldset": {
                      border: "none", // Hide border on hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "none", // Hide border on focus
                    },
                  },
                }}
                label="Comments"
                variant="outlined" // Keep the variant if needed
                onChange={(e) => setCmtMessage(e.target.value)}
              />
            </Box>

            {/* Action Buttons */}
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"space-between"}
              mt={10}>
              <Box display="flex" alignItems={"center"} gap={8}>
                <Button
                  fontSize={{ xs: 10, md: 14, xl: 15 }}
                  sx={{
                    backgroundColor: "#BEBEBE", // Gray color for cancel button
                    color: "#5E5E5E",
                    fontWeight: "bold",
                    p: 1.5,
                    minWidth: 120, // Larger button width
                  }}
                  onClick={handleApproveClose}>
                  Cancel
                </Button>
                <Button
                  fontSize={{ xs: 10, md: 14, xl: 15 }}
                  sx={{
                    backgroundColor: "#F8D7DA", // Light red for reject button
                    color: "#C41212",
                    fontWeight: "bold",
                    p: 1.5,
                    minWidth: 120,
                  }}
                  onClick={() => {
                    // Add your reject logic here
                    handleApproveAPI(DetailedById._id, "rejected");
                  }}>
                  Reject
                </Button>
              </Box>

              <Button
                fontSize={{ xs: 10, md: 14, xl: 15 }}
                sx={{
                  backgroundColor: "#C2EBC7", // Greenish background for approve button
                  color: "#00B69B",
                  fontWeight: "bold",
                  p: 1.5,
                  minWidth: 120,
                }}
                onClick={() => {
                  // Add your approve logic here
                  handleApproveAPI(DetailedById._id, "approved");
                }}>
                Approve
              </Button>
            </Box>
            <ImageModal
              isOpen={isImageModalOpen}
              onClose={handleCloseImageModal}
              imageUrl={selectedImage}
            />
          </Box>
        ) : (
          <Typography>Loading...</Typography> // Fallback to show loading state or empty content
        )}
      </Modal>
    </Box>
  );
};

export default ManageCars;
