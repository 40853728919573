// src/components/NotificationItem.js
import React from "react";
import { Box, Typography, Paper } from "@mui/material";

const NotificationItem = ({ type, title, message, user, timestamp }) => {
 console.log("user:", user);
 return (
  <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
   <Box display="flex" justifyContent="space-between" mb={1}>
    <Typography variant="subtitle2" color="textSecondary">
     {type}
    </Typography>
    <Typography variant="caption" color="textSecondary">
     {timestamp}
    </Typography>
   </Box>
   <Typography variant="h6" gutterBottom>
    {title}
   </Typography>
   <Typography variant="body2" color="textSecondary" paragraph>
    {message}
   </Typography>
   <Typography variant="caption" color="textSecondary">
    From: {user}
   </Typography>
  </Paper>
 );
};

export default NotificationItem;

// // NotificationItem.js
// import React from 'react';
// import { Box, Typography, IconButton, Avatar } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';

// const NotificationItem = ({ type, title, message, user, timestamp }) => {
//   const getColor = (type) => {
//     switch (type) {
//       case 'Joined New User':
//         return 'green';
//       case 'Message':
//         return 'orange';
//       case 'Comment':
//         return 'purple';
//       case 'Connect':
//         return 'blue';
//       default:
//         return 'gray';
//     }
//   };

//   return (
//     <Box display="flex" alignItems="center" p={2} bgcolor="#fff" borderRadius={2} mb={2}>
//       <Avatar sx={{ bgcolor: getColor(type), marginRight: 2 }}>{user.charAt(0)}</Avatar>
//       <Box flexGrow={1}>
//         <Typography variant="subtitle2" color={getColor(type)}>
//           {title}
//         </Typography>
//         <Typography variant="body2" color="textSecondary">
//           {message}
//         </Typography>
//         <Typography variant="caption" color="textSecondary">
//           {user} • {timestamp}
//         </Typography>
//       </Box>
//       <IconButton size="small">
//         <CloseIcon />
//       </IconButton>
//     </Box>
//   );
// };

// export default NotificationItem;
