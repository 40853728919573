import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminPanel from "./components/AdminDashboard/AdminPanel";
import DistributorPanel from "./components/distributer/DistributorPanel";
import PrivateRoute from "./components/auth/PrivateRoute";
import LoginWithCharacter from "./Loginpage";
import { loadUser } from "./redux/auth/action";
import RegisterDistributor from "./components/RegisterDistributor";

function App() {
 const dispatch = useDispatch();
 const { isAuthenticated, user } = useSelector((state) => state.auth);

 useEffect(() => {
  // Check both admin and distributor tokens
  const adminToken = localStorage.getItem("admin_access");
  const distributorToken = localStorage.getItem("distributor_access");

  // If either token is found, load the user
  if (adminToken) {
   dispatch(loadUser("admin_access"));
  } else if (distributorToken) {
   dispatch(loadUser("distributor_access"));
  }
 }, [dispatch]);

 const location = useLocation();

 return (
  <Routes>
   {/* Public Routes */}
   <Route path="/admin/login" element={<LoginWithCharacter />} />
   <Route path="/distributor/login" element={<LoginWithCharacter />} />
   <Route path="/distributor/register" element={<RegisterDistributor />} />

   {/* Admin Protected Routes */}
   <Route element={<PrivateRoute role="superadmin" />}>
    <Route path="/admin/*" element={<AdminPanel />} />
   </Route>

   {/* Distributor Protected Routes */}
   <Route element={<PrivateRoute role="distributor" />}>
    <Route path="/distributor/*" element={<DistributorPanel />} />
   </Route>

   {/* Root Route - Redirect based on authentication and role */}
   <Route
    path="/"
    element={
     isAuthenticated ? (
      user?.data?.role === "superadmin" ? (
       <Navigate to="/admin" />
      ) : (
       <Navigate to="/distributor" />
      )
     ) : location.pathname.includes("/distributor") ? (
      <Navigate to="/distributor/login" />
     ) : (
      <Navigate to="/admin/login" />
     )
    }
   />
  </Routes>
 );
}

export default App;

// import React, { useEffect } from "react";
// import { Route, Routes, Navigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import AdminPanel from "./components/AdminDashboard/AdminPanel";
// import DistributorPanel from "./components/distributer/DistributorPanel";
// import PrivateRoute from "./components/auth/PrivateRoute";
// import LoginWithCharacter from "./Loginpage";
// import { loadUser } from "./redux/auth/action";
// import RegisterDistributor from "./components/RegisterDistributor";

// function App() {
//  const dispatch = useDispatch();
//  const { isAuthenticated, user, loading } = useSelector((state) => state.auth);

//  //  useEffect(() => {
//  //   const token = localStorage.getItem("token");
//  //   // const token =
//  //   //   localStorage.getItem("admin_access") ||
//  //   //   localStorage.getItem("distributor_access");

//  //   if (token) {
//  //    dispatch(loadUser(token));
//  //   }
//  //  }, [dispatch]);
//  useEffect(() => {
//   // Check both admin and distributor tokens
//   const adminToken = localStorage.getItem("admin_access");
//   const distributorToken = localStorage.getItem("distributor_access");

//   // If either token is found, load the user
//   if (adminToken) {
//    dispatch(loadUser("admin_access"));
//   } else if (distributorToken) {
//    dispatch(loadUser("distributor_access"));
//   }
//  }, [dispatch]);
//  // if (loading) {
//  //   return <div>Loading...</div>; // You can replace this with a loader/spinner
//  // }
//  console.log("user", user);

//  return (
//   <Routes>
//    {/* Public Routes */}
//    <Route path="/admin/login" element={<LoginWithCharacter />} />
//    <Route path="/distributor/login" element={<LoginWithCharacter />} />
//    <Route path="/distributor/register" element={<RegisterDistributor />} />

//    {/* Admin Protected Routes */}
//    <Route element={<PrivateRoute role="superadmin" />}>
//     <Route path="/admin/*" element={<AdminPanel />} />
//    </Route>

//    {/* Distributer Protected Routes */}
//    <Route element={<PrivateRoute role="distributor" />}>
//     {/* <Route> */}
//     <Route path="/distributor/*" element={<DistributorPanel />} />
//     {/* Corrected */}
//    </Route>

//    {/* Root Route - Redirect based on authentication and role */}
//    <Route
//     path="/"
//     // element={
//     //  isAuthenticated ? (
//     //   user?.data?.role === "superadmin" ? (
//     //    <Navigate to="/admin" />
//     //   ) : (
//     //    <Navigate to="/distributor" />
//     //   )
//     //  ) : (
//     //   <Navigate to="/admin/login" />
//     //  )
//     // }
//     element={
//      isAuthenticated ? (
//       user?.data?.role === "superadmin" ? (
//        <Navigate to="/admin" />
//       ) : (
//        <Navigate to="/distributor" />
//       )
//      ) : (
//       <Navigate to="/admin/login" /> // Default to /admin/login if not authenticated
//      )
//     }
//    />
//   </Routes>
//  );
// }

// export default App;
