import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import ManageDistributors from "./ManageDistributors";
import AdminReports from "./AdminReports";
import AdminSettings from "./AdminSettings";
import AdminNotifications from "./AdminNotifications";
import MiniDrawer from "../MiniDrawer";
import AdminBannerUpload from "./AdminBannerUpload";
import ManageRides from "../ManageRides";
import ManageCars from "../ManageCars";
import ManageUsers from "../ManageUsers";
import Wh_Dashboard from "../.././Assests/wh_dashboard.svg";
import bl_Dashboard from "../.././Assests/bl_dashboard.svg";
import Wh_Setting from "../.././Assests/wh_setting.svg";
import Wh_Transaction from "../.././Assests/wh_transaction.svg";
import Wh_banner from "../.././Assests/wh_banner.svg";
import Wh_People from "../.././Assests/wh_people.svg";
import Wh_Car from "../.././Assests/wh_car.svg";
import bl_Car from "../.././Assests/bl_car.svg";
import Bl_People from "../.././Assests/bl_people.svg";
import Bl_People1 from "../.././Assests/bl_people1.svg";
import Wh_People1 from "../.././Assests/wh_people1.svg";
import Wh_Notification from "../.././Assests/wh_notification.svg";
import Bl_Ride from "../.././Assests/bl_ride.svg";
import Wh_Ride from "../.././Assests/wh_ride.svg";
import bl_Setting from "../.././Assests/bl_setting.svg";

import bl_Transaction from "../.././Assests/bl_transaction.svg";
import Bl_Banner from "../.././Assests/bl_banner.svg";
import Bl_Notification from "../.././Assests/bl_notification.svg";
import ManageTransaction from "../ManageTransaction";
import ManageWithdrawal from "../ManageWithdrawal";

const adminMenuItems = [
  {
    text: "Dashboard",
    link: "/admin",
    icon: <img src={Wh_Dashboard} alt="Dashboard" />,
    activeIcon: <img src={bl_Dashboard} alt="people" />,
  },
  {
    text: "Manage Distributors",
    link: "/admin/manage-distributors",
    icon: <img src={Wh_People} alt="people" />,
    activeIcon: <img src={Bl_People} alt="people" />,
  },
  {
    text: "Manage Cars",
    link: "/admin/manage-cars",
    icon: <img src={Wh_Car} alt="people" />,
    activeIcon: <img src={bl_Car} alt="people" />,
  },
  {
    text: "Manage Users",
    link: "/admin/manage-users",
    icon: <img src={Wh_People1} alt="people" />,
    activeIcon: <img src={Bl_People1} alt="people" />,
  },

  {
    text: "Manage Rides",
    link: "/admin/manage-rides",
    icon: <img src={Wh_Ride} alt="people" />,
    activeIcon: <img src={Bl_Ride} alt="people" />,
  },
  {
    text: "Manage Transactions",
    link: "/admin/manage-transaction",
    icon: <img src={Wh_Transaction} alt="Transections" />,
    activeIcon: <img src={bl_Transaction} alt="people" />,
  },
  {
    text: "Manage Withdrawal",
    link: "/admin/manage-withdrawal",
    icon: <img src={Wh_Transaction} alt="Withdrawals" />,
    activeIcon: <img src={bl_Transaction} alt="people" />,
  },

  {
    text: "Setting",
    link: "/admin/setting",
    icon: <img src={Wh_Setting} alt="setting" />,
    activeIcon: <img src={bl_Setting} alt="people" />,
  },
  {
    text: "Banner",
    link: "/admin/banner",
    icon: <img src={Wh_banner} alt="banner" />,
    activeIcon: <img src={Bl_Banner} alt="people" />,
  },

  {
    text: "Notification",
    link: "/admin/notifications",
    icon: <img src={Wh_Notification} alt="Wh_Notification" />,
    activeIcon: <img src={Bl_Notification} alt="people" />,
  },
];

function AdminPanel() {
  return (
    <MiniDrawer menuItems={adminMenuItems}>
      <Routes>
        <Route path="/" element={<AdminDashboard />} />
        <Route path="/manage-distributors" element={<ManageDistributors />} />
        <Route path="/manage-rides" element={<ManageRides />} />
        <Route path="/manage-cars" element={<ManageCars />} />
        <Route path="/manage-users" element={<ManageUsers />} />

        <Route path="/reports" element={<AdminReports />} />
        <Route path="/settings" element={<AdminSettings />} />
        <Route path="/notifications" element={<AdminNotifications />} />
        <Route path="/banner" element={<AdminBannerUpload />} />
        <Route path="/manage-transaction" element={<ManageTransaction />} />
        <Route path="/manage-withdrawal" element={<ManageWithdrawal />} />
        {/* <Route path="/help" element={<AdminHelp />} />*/}
        <Route path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
    </MiniDrawer>
  );
}

export default AdminPanel;
