import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard";
import Cars from "./Cars";
import DistributerStatements from "./DistributerStatements";
import DistributerWithdrawal from "./DistributerWithdrawal";
import DistributerNotification from "./DistributerNotification";
import DistributorHelp from "./DistributorHelp";
import Distributer from "./Distributer";
import Reports from "../Reports";
import DistributorForm from "./DistributorForm";
import MiniDrawer from "../MiniDrawer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpIcon from "@mui/icons-material/Help";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfilePage from "./ProfilePage";
import ManageCars from "../ManageCars";
import Wh_Dashboard from "../../Assests/bl_dist_dashboad.svg";
import Bl_Dashboard from "../../Assests/wh_dist_dashboard.svg";
import Wh_car from "../../Assests/wh_dist_car.svg";
import Bl_car from "../../Assests/bl_dist_car.svg";
import Wh_statement from "../../Assests/wh_dist_withdrawal.svg";
import Bl_statement from "../../Assests/bl_dist_statement.svg";
import Wh_withdraw from "../../Assests/wh_dist_with.svg";
import bl_withdraw from "../../Assests/bl_dist_withdraw.svg";
import Wh_notification from "../../Assests/wh_dist_notigication.svg";
import Bl_notification from "../../Assests/bl_dist_notification.svg";
import Wh_help from "../../Assests/wh_dist_help.svg";
import Bl_help from "../../Assests/bl_dist_help.svg";
import Wh_profile from "../../Assests/wh_dist_profile.svg";
import Bl_profile from "../../Assests/bl_dist_profile.svg";
import DistributorCars from "./DistributorCar";
const distributorMenuItems = [
 {
  text: "Dashboard",
  link: "/distributor",
  icon: <img src={Wh_Dashboard} alt="Dashboard" />,
  activeIcon: <img src={Bl_Dashboard} alt="people" />,
 },
 {
  text: "Manage Vehicle",
  link: "/distributor/cars",
  icon: <img src={Wh_car} alt="Dashboard" />,
  activeIcon: <img src={Bl_car} alt="people" />,
 },
 {
  text: "Statements",
  link: "/distributor/statement",
  icon: <img src={Wh_statement} alt="Dashboard" />,
  activeIcon: <img src={Bl_statement} alt="people" />,
 },
 {
  text: "Withdrawal",
  link: "/distributor/withdrawal",
  icon: <img src={Wh_withdraw} alt="Dashboard" />,
  activeIcon: <img src={bl_withdraw} alt="people" />,
 },
 {
  text: "Notification",
  link: "/distributor/notification",
  icon: <img src={Wh_notification} alt="Dashboard" />,
  activeIcon: <img src={Bl_notification} alt="people" />,
 },
 {
  text: "Help",
  link: "/distributor/help",
  icon: <img src={Wh_help} alt="Dashboard" />,
  activeIcon: <img src={Bl_help} alt="people" />,
 },
 {
  text: "Profile",
  link: "/distributor/profile",
  icon: <img src={Wh_profile} alt="Dashboard" />,
  activeIcon: <img src={Bl_profile} alt="people" />,
 },
];

function DistributorPanel() {
 return (
  <MiniDrawer menuItems={distributorMenuItems}>
   <Routes>
    <Route path="/" element={<Dashboard />} />
    {/* <Route path="/cars" element={<Cars />} /> */}
    <Route path="/cars" element={<DistributorCars />} />
    <Route path="/manage-cars" element={<ManageCars />} />
    <Route path="/statement" element={<DistributerStatements />} />
    <Route path="/withdrawal" element={<DistributerWithdrawal />} />
    <Route path="/notification" element={<DistributerNotification />} />
    <Route path="/help" element={<DistributorHelp />} />
    <Route path="/distributor" element={<Distributer />} /> {/* Corrected */}
    <Route path="/reports" element={<Reports />} />
    <Route path="/distributor-form" element={<DistributorForm />} />
    <Route path="/profile" element={<ProfilePage />} />
   </Routes>
  </MiniDrawer>
 );
}

export default DistributorPanel;
