// DistributorNotificationList.js
import React from "react";
import { Box, Typography } from "@mui/material";
import NotificationItem from "../notifications/NotificationItem";
import NotificationList from "../notifications/NotificationList";
// import NotificationItem from '../components/NotificationItem'; // Ensure correct import path

const DistributorNotificationList = () => {
 return (
  <Box p={4} bgcolor="#f5f7fa" minHeight="100vh">
   <NotificationList />
  </Box>
 );
};

export default DistributorNotificationList;
