import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import Notification from "../NotificationAPI";

const DistributorFormAdmin = ({ open, onClose, onDistributorAdded }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    phone: "",
  });
  const [messageAPI, setMessageAPI] = useState(false); // State to control Snackbar visibility
  const [message, setMessage] = useState(""); // State for the message to show
  const [severity, setSeverity] = useState("success");
  // const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://api.janatarides.com/api/admin/distributors",
        formData, // Send the actual form data
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );

      // console.log("Post Response:", res); // Log the response for debugging

      // Reset form data
      setFormData({
        email: "",
        password: "",
        name: "",
        phone: "",
      });

      if (res.status === 201) {
        // Use `status` to check for success
        setMessage(res.data.message || "Distributor created successfully!"); // Access the message from `res.data`
        setSeverity("success"); // Show success message
        setMessageAPI(true);
        setTimeout(() => {
          onDistributorAdded();
          onClose();
        }, 1500);
      } else {
        setMessage(res.data.message || "Failed to register distributor");
        setSeverity("error"); // Show error message
        setMessageAPI(true); // Show notification
        console.log("check", res.data.message);
      }
    } catch (err) {
      // Improved error handling
      setMessage(
        err.response?.data?.message ||
          "An error occurred while creating the distributor."
      ); // Get the error message from the response
      setSeverity("error");
      setMessageAPI(true); // Show notification
    }
  };

  const handleMessageAPI = () => {
    setMessageAPI(false); // Close the Snackbar
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "400px",
          bgcolor: "background.paper",
          p: 4,
          boxShadow: 24,
          borderRadius: 2,
        }}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton onClick={onClose} sx={{ color: "red" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          variant="h6"
          textAlign={"center"}
          component="h2"
          fontWeight={"bold"}
          mb={1}>
          Add Distributor
        </Typography>
        {/* {error && <Typography color="error">{error}</Typography>} */}
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} mb={2}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                fullWidth
                required
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <TextField
                label="Email"
                name="email"
                variant="outlined"
                type="email"
                fullWidth
                required
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <TextField
                label="Mobile"
                name="phone"
                variant="outlined"
                fullWidth
                required
                value={formData.phone}
                onChange={(e) => {
                  // Only allow numbers
                  const phone = e.target.value.replace(/\D/g, "");
                  setFormData({ ...formData, phone });
                }}
                inputProps={{ maxLength: 10 }}
                error={formData.phone.length > 0 && formData.phone.length < 10} // Ensure 10 digits
                helperText={
                  formData.phone.length > 0 && formData.phone.length < 10
                    ? "Phone number must be exactly 10 digits"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <TextField
                label="Password"
                name="password"
                variant="outlined"
                type="password"
                fullWidth
                required
                value={formData.password}
                onChange={handleChange}
              />
            </Grid>
            <Box
              width={"90%"}
              margin={"auto"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              py={2}>
              <Button
                sx={{
                  fontSize: { xs: 10, md: 12, xl: 14 },
                  backgroundColor: "#979797",
                  color: "#5E5E5E",
                  fontWeight: "bold",
                  p: 2,
                }}
                onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{
                  fontSize: { xs: 10, md: 12, xl: 14 },
                  backgroundColor: "#BEE3DD",
                  color: "#00B69B",
                  fontWeight: "bold",
                  p: 2,
                }}>
                Create
              </Button>
            </Box>
          </Grid>
        </form>

        <Notification
          messageAPI={messageAPI}
          message={message}
          severity={severity}
          handleMessageAPI={handleMessageAPI}
        />
      </Box>
    </Modal>
  );
};

export default DistributorFormAdmin;
