// components/admin/AdminSettings.js
import React from "react";
import AdminBannerUpload from "./AdminBannerUpload";

function AdminSettings() {
 return (
  <div>
   <h2>Settings</h2>
   {/* Add settings management functionality here */}
  </div>
 );
}

export default AdminSettings;
