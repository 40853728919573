import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "./redux/auth/action";
import Notification from "./components/NotificationAPI";

const LoginWithCharacter = () => {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [messageAPI, setMessageAPI] = useState(false);
  const [messages, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDistributer = location.pathname === "/distributor/login";

  // Get authentication state from Redux store
  const { isAuthenticated, user, error, message, loading } = useSelector(
    (state) => state.auth
  );

  console.log("check", message, isAuthenticated);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password)); // Dispatch login action
  };

  // Handle password visibility toggle
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // Redirect user after successful login
  useEffect(() => {
    if (isAuthenticated) {
      console.log("Welcome message:", message);
      // You can show a notification here or set it to state
      setMessage(message);
      setSeverity("success");
      setMessageAPI(true);
      setTimeout(() => {
        navigate(user?.data?.role === "superadmin" ? "/admin" : "/distributor");
      }, 1500);
    }

    if (error) {
      console.error("Login error:", error);
      setMessage(error);
      setSeverity("error");
      setMessageAPI(true);
    }
  }, [isAuthenticated, user, navigate]);

  // useEffect(() => {
  //   if (error) {
  //     console.error("Login error:", error);
  //     setMessage(error);
  //     setSeverity("error");
  //     setMessageAPI(true);
  //   }
  // }, [error]);

  const handleMessageAPI = () => {
    setMessageAPI(false);
    // setMessage(""); // Reset message state
    // setSeverity("success"); // Reset severity
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "#f4f5f7",
        flexDirection: { xs: "column", lg: "row" },
      }}>
      {/* Left Section with Image */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}>
        <img
          src="/distributor_login.svg"
          alt="3D Character"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>

      {/* Right Section with Login Form */}
      <Box
        sx={{
          flex: 0.6,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: { xs: 3, md: 6 },
        }}>
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 3, md: 6 },
            width: { xs: "100%", md: "400px" },
            backgroundColor: "#ffffff",
          }}>
          <Box sx={{ textAlign: "center", marginBottom: 4, gap: 2 }}>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 16, md: 28, xl: 30 }}>
              {isDistributer ? "Distributor Login" : "Admin Login"}
            </Typography>
          </Box>

          <form autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="filled"
              fullWidth
              size="small"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              variant="filled"
              fullWidth
              size="small"
              margin="normal"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                backgroundColor: "#FFC107",
                color: "black",
                marginTop: "20px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#FFA000",
                },
              }}>
              {loading ? "Signing In" : "Login"}
            </Button>

            {isDistributer && (
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                }}
                onClick={() => navigate("/distributor/register")}>
                <Button variant="text" size="small">
                  Create an account
                </Button>
              </Box>
            )}
          </form>
        </Paper>
      </Box>
      <Notification
        messageAPI={messageAPI}
        message={messages}
        severity={severity}
        handleMessageAPI={handleMessageAPI}
      />
    </Box>
  );
};

export default LoginWithCharacter;
