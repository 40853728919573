// src/redux/auth/actions.js
import axios from "axios";
import * as types from "./actionTypes";

// Login User
export const login = (email, password) => async (dispatch) => {
 dispatch({ type: types.LOGIN_REQUEST });

 try {
  let apiURL, tokenName, refreshTokenName;
  const currentPath = window.location.pathname;

  // Determine API and token names based on the current path
  if (currentPath === "/admin/login") {
   apiURL = "https://api.janatarides.com/api/admin/login";
   tokenName = "admin_access";
   refreshTokenName = "admin_refresh_token";
  } else if (currentPath === "/distributor/login") {
   apiURL = "https://api.janatarides.com/api/distributors/login";
   tokenName = "distributor_access";
   refreshTokenName = "distributor_refresh_token";
  }

  // Make API request
  const res = await axios.post(apiURL, { email, password });

  // Extract message from response
  const { message } = res.data;

  // Extract token and refresh_token from the data object
  const { token, refresh_token } = res.data.data;

  // Log the message to verify
  console.log("redux", message);

  // Store tokens in localStorage
  localStorage.setItem(tokenName, token);
  localStorage.setItem(refreshTokenName, refresh_token);

  // Dispatch LOGIN_SUCCESS and pass the success message
  dispatch({
   type: types.LOGIN_SUCCESS,
   payload: { token, message },
  });
  console.log("tokenName login", tokenName);

  // Load user with the tokenName
  dispatch(loadUser(tokenName));
 } catch (error) {
  dispatch({
   type: types.LOGIN_FAILURE,
   payload: error.response?.data?.message || error.message,
  });
 }
};

// Load User
// export const loadUser = (tokenName) => async (dispatch) => {
//   dispatch({ type: types.LOAD_USER_REQUEST });
//   console.log("tokenName loaduser", tokenName);

//   try {
//     let apiURL;
//     const currentPath = window.location.pathname;

//     // Determine API based on the current path
//     if (currentPath === "/admin/login") {
//       apiURL = "https://api.janatarides.com/api/admin/profile";
//     } else if (currentPath === "/distributor/login") {
//       apiURL = "https://api.janatarides.com/api/distributors/";
//     }

//     // Get token from localStorage based on the tokenName passed
//     let token = localStorage.getItem(tokenName);

//     // If the token is not available, handle accordingly
//     if (!token) {
//       throw new Error("No token found");
//     }

//     const res = await axios.get(apiURL, {
//       headers: {
//         Authorization: token,
//       },
//     });

//     dispatch({ type: types.LOAD_USER_SUCCESS, payload: res.data });
//   } catch (error) {
//     dispatch({ type: types.LOAD_USER_FAILURE, payload: error.message });
//   }
// };

export const loadUser = (tokenName) => async (dispatch) => {
 dispatch({ type: types.LOAD_USER_REQUEST });

 try {
  let apiURL, token;

  // Get current pathname to determine whether it's admin or distributor
  const currentPath = window.location.pathname;

  if (currentPath.includes("/admin")) {
   apiURL = "https://api.janatarides.com/api/admin/profile";
   token = localStorage.getItem("admin_access");
  } else if (currentPath.includes("/distributor")) {
   apiURL = "https://api.janatarides.com/api/distributors/";
   token = localStorage.getItem("distributor_access");
  }

  if (!token) {
   throw new Error("No token found");
  }

  const res = await axios.get(apiURL, {
   headers: {
    Authorization: `Bearer ${token}`, // Make sure to use `Bearer` for token in Authorization header
   },
  });

  dispatch({ type: types.LOAD_USER_SUCCESS, payload: res.data });
 } catch (error) {
  dispatch({ type: types.LOAD_USER_FAILURE, payload: error.message });
 }
};
// // Logout User
// export const logoutUser = (redirect) => (dispatch) => {
//  // Clear tokens for both admin and distributor
//  localStorage.removeItem("admin_access");
//  localStorage.removeItem("admin_refresh_token");
//  localStorage.removeItem("distributor_access");
//  localStorage.removeItem("distributor_refresh_token");

//  dispatch({ type: "LOGOUT" });

//  // Use the redirect callback passed as a parameter
//  if (redirect) {
//   redirect();
//  }
// };
// src/redux/auth/actions.js

// src/redux/auth/actions.js

export const logoutUser = () => (dispatch) => {
 // Clear both admin and distributor tokens
 localStorage.removeItem("admin_access");
 localStorage.removeItem("admin_refresh_token");
 localStorage.removeItem("distributor_access");
 localStorage.removeItem("distributor_refresh_token");

 dispatch({ type: "LOGOUT" });

 // Determine where to navigate based on the current path
 if (window.location.pathname.includes("/distributor")) {
  window.location.href = "/distributor/login";
 } else {
  window.location.href = "/admin/login";
 }
};

// // src/redux/auth/actions.js
// import axios from "axios";
// import * as types from "./actionTypes";

// // Login User
// export const login = (email, password) => async (dispatch) => {
//   dispatch({ type: types.LOGIN_REQUEST });

//   try {
//     let apiURL, tokenName, refreshTokenName;
//     const currentPath = window.location.pathname;

//     // Determine API and token names based on the current path
//     if (currentPath === "/admin/login") {
//       apiURL = "https://api.janatarides.com/api/admin/login";
//       tokenName = "admin_access";
//       refreshTokenName = "admin_refresh_token";
//     } else if (currentPath === "/distributor/login") {
//       apiURL = "https://api.janatarides.com/api/distributors/login";
//       tokenName = "distributor_access";
//       refreshTokenName = "distributor_refresh_token";
//     }

//     const res = await axios.post(apiURL, { email, password });
//     const { token, refresh_token } = res.data.data;

//     // Store tokens in localStorage
//     localStorage.setItem(tokenName, token);
//     localStorage.setItem(refreshTokenName, refresh_token);

//     // Dispatch LOGIN_SUCCESS and load the user data
//     dispatch({ type: types.LOGIN_SUCCESS, payload: { token } });
//     dispatch(loadUser(tokenName)); // Pass tokenName to loadUser
//   } catch (error) {
//     dispatch({ type: types.LOGIN_FAILURE, payload: error.message });
//   }
// };

// // Load User
// export const loadUser = (tokenName) => async (dispatch) => {
//   dispatch({ type: types.LOAD_USER_REQUEST });
//   console.log("tokenname", tokenName);

//   try {
//     let apiURL;
//     const currentPath = window.location.pathname;

//     // Determine API based on the current path
//     if (currentPath === "/admin/login") {
//       apiURL = "https://api.janatarides.com/api/admin/profile";
//     } else if (currentPath === "/distributor/login") {
//       apiURL = "https://api.janatarides.com/api/distributors/";
//     }

//     // Get token from localStorage based on the tokenName passed
//     let token = localStorage.getItem(tokenName);
//     // If the token is not available, try refreshing it using the refresh_token
//     if (!token) {
//       const refreshTokenName =
//         tokenName === "admin_access"
//           ? "admin_refresh_token"
//           : "distributor_refresh_token";
//       const refreshToken = localStorage.getItem(refreshTokenName);

//       if (refreshToken) {
//         // Use refresh_token to get a new access token
//         const refreshRes = await axios.post(
//           "https://api.janatarides.com/api/refresh_token", // Assuming refresh token API
//           { refresh_token: refreshToken }
//         );
//         token = refreshRes.data.data.token;

//         // Update token in localStorage
//         localStorage.setItem(tokenName, token);
//       }
//     }

//     const res = await axios.get(apiURL, {
//       headers: {
//         Authorization: token,
//       },
//     });

//     console.log("check user", res);
//     dispatch({ type: types.LOAD_USER_SUCCESS, payload: res.data.data });
//   } catch (error) {
//     dispatch({ type: types.LOAD_USER_FAILURE, payload: error.message });
//   }
// };

// // Logout User
// export const logoutUser = (redirect) => (dispatch) => {
//   // Clear tokens for both admin and distributor
//   localStorage.removeItem("admin_access");
//   localStorage.removeItem("admin_refresh_token");
//   localStorage.removeItem("distributor_access");
//   localStorage.removeItem("distributor_refresh_token");

//   dispatch({ type: "LOGOUT" });

//   // Use the redirect callback passed as a parameter
//   if (redirect) {
//     redirect();
//   }
// };
