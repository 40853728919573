import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Modal,
  InputAdornment,
  MenuItem,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { DataGrid } from "@mui/x-data-grid";
import DashboardHeaderCards from "./DashboardHeaderCards";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";

const cardData = [
  {
    title: "Total Users",
    value: "78",
    color: "#1976d2",

    icon: "/user.svg",
    route: "/distributer/statement",
  },
  {
    title: "Users Approved",
    value: 49,
    color: "#388e3c",
    //   icon: <DirectionsCarIcon sx={{ fontSize: 40, color: "#388e3c" }} />,
    icon: "/distributors_approve.svg",

    route: "/distributer/cars",
  },
  {
    title: "Users Rejected",
    value: "29",
    color: "#d32f2f",
    icon: "/user_reject.svg",

    //   icon: <TrendingDownIcon sx={{ fontSize: 40, color: "#d32f2f" }} />,
    route: "/distributer/cars",
  },
];
const ManageUsers = () => {
  const [blockUser, setBlockUser] = useState(false);
  const [userData, setUserData] = useState([]);

  const handleBlockOpen = () => {
    setBlockUser(true);
  };

  const handleBlockClose = () => {
    setBlockUser(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  // Fetch distributors with driver counts
  const fetchUser = async () => {
    try {
      const res = await axios.get(
        "https://api.janatarides.com/api/admin/users",
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );

      const formattedRows = res.data.data.map((user) => ({
        id: user._id,
        userDetails: user,
      }));
      setUserData(formattedRows);
      console.log("getdata", res.data.data);
    } catch (err) {
      console.error("Error fetching distributors:", err);
    }
  };

  const usersColumns = [
    {
      field: "userDetails",
      headerName: "Users Details",
      flex: 4,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Users Details
        </div>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: { xs: 4, md: 8, xl: 8 },
            backgroundColor: "transparent",
            mb: 4,
          }}>
          {/* Profile Image */}
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={5}
            alignItems={"center"}>
            <Box>
              <img
                src={params.row.userDetails.profile}
                alt="Profile"
                style={{
                  width: "80px",
                  height: "80px",
                  backgroundColor: "#ccc",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
              <Typography fontSize={{ xs: 10, md: 13, xl: 14 }}>
                {params.row.userDetails.name}
              </Typography>
            </Box>

            <Box sx={{ flex: "1", minWidth: "100px" }}>
              <Typography
                fontWeight={"bold"}
                fontSize={{ xs: 6, md: 9, xl: 13 }}>
                {params.row.userDetails._id}
              </Typography>
              <Typography fontWeight={"bold"} fontSize={16}>
                {params.row.userDetails.name}
              </Typography>
            </Box>
          </Box>

          {/* Email and Mobile */}
          <Box>
            <Typography
              fontSize={{ xs: 6, md: 9, xl: 13 }}
              fontWeight={"medium"}>
              {params.row.userDetails.email}
            </Typography>
            <Typography
              fontSize={{ xs: 6, md: 9, xl: 13 }}
              fontWeight={"medium"}>
              {params.row.userDetails.mobile}
            </Typography>
          </Box>

          {/* Address */}
          <Box>
            <Typography
              fontSize={{ xs: 6, md: 9, xl: 13 }}
              fontWeight={"medium"}
              sx={{
                wordWrap: "break-word",
                whiteSpace: "normal",
                padding: 1,
              }}>
              {params.row.userDetails.address}
            </Typography>
          </Box>

          {/* Number of Rides and Join Date */}
          <Box>
            <Typography
              fontSize={{ xs: 6, md: 9, xl: 13 }}
              fontWeight={"medium"}>
              No. of Rides: {params.row.userDetails.numberOfRides || "N/A"}
            </Typography>
            <Typography
              fontSize={{ xs: 6, md: 9, xl: 13 }}
              fontWeight={"medium"}>
              Joined on:{" "}
              {new Date(
                params.row.userDetails.createdAt
              ).toLocaleDateString() || "N/A"}
            </Typography>
          </Box>
        </Box>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Status</div>
      ),
      renderCell: (params) => (
        <Box>
          {params.row.userDetails.isBlocked ? (
            <Button
              sx={{
                fontSize: { xs: 4, md: 8, xl: 13 },
                backgroundColor: "#EECACA",
                color: "#D66262",
                fontWeight: "bold",
                pointerEvents: "none",
                "&:hover": {
                  backgroundColor: "#EECACA",
                },
              }}>
              Blocked
            </Button>
          ) : (
            <Button
              sx={{
                fontSize: { xs: 4, md: 8, xl: 13 },
                backgroundColor: "#C6EBE5",
                color: "#0DBA9F",
                fontWeight: "bold",
                pointerEvents: "none",
                "&:hover": {
                  backgroundColor: "#C6EBE5",
                },
              }}>
              Approved
            </Button>
          )}

          <Typography fontSize={{ xs: 6, md: 9, xl: 13 }}>
            Last Ride: 20 March 2024
          </Typography>
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      // width: 240,
      flex: 0.8,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Action</div>
      ),
      renderCell: (params) => (
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Button
            sx={{
              fontSize: { xs: 7, md: 9, xl: 13 },
              backgroundColor: "#EECACA",
              color: "#D66262",
              fontWeight: "bold",
            }}
            onClick={() => handleBlockOpen(params.row.userDetails.id)}>
            {params.row.userDetails.isBlocked ? "Unblock" : "Block"}
          </Button>

          <Typography
            fontSize={{ xs: 6, md: 9, xl: 13 }}
            textAlign={"center"}
            fontWeight={"bold"}>
            View Rides
          </Typography>
        </Box>
      ),
    },
  ];
  return (
    <Box>
      <DashboardHeaderCards cardData={cardData} />

      <Box py={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <TextField
              label="Search by Name or Mobile"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              label="Select User Status"
              variant="outlined"
              fullWidth
              select
              InputProps={{
                style: { height: "48px" },
              }}>
              <MenuItem value="service1">ALL</MenuItem>
              <MenuItem value="service2">Approved</MenuItem>
              <MenuItem value="service2">Panding</MenuItem>
              <MenuItem value="service2">Block</MenuItem>
            </TextField>
          </Grid>

          {/* Date Picker */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="dd/mm/yyyy"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/* Export Button aligned with inputs */}
          <Grid item xs={12} sm={3} textAlign="right">
            <Button
              variant="contained"
              startIcon={
                <img
                  src={"/export.svg"}
                  alt="Export Icon"
                  style={{ width: "20px", height: "24px" }}
                />
              }
              sx={{
                backgroundColor: "#FFC107",
                color: "black",
                height: "48px",
                minWidth: "160px",
                fontWeight: "bold",
              }}>
              Export
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={userData}
          columns={usersColumns}
          getRowId={(row) => row.userDetails._id}
          pageSize={10}
          autoHeight={false}
          rowHeight={200}
        />
      </Box>

      <Modal open={blockUser} onClose={handleBlockClose}>
        <Box
          sx={{
            p: 3,
            maxWidth: "30%",
            margin: "auto",
            mt: "20vh",
            borderRadius: "md",
            backgroundColor: "#D9D9D9",
          }}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <IconButton onClick={handleBlockClose} sx={{ color: "red" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            fontWeight={"bold"}
            fontSize={18}
            textAlign={"center"}
            mb={3}>
            Block/Unblock users
          </Typography>

          <Typography textAlign={"center"} mb={6}>
            Do you want to block Car
          </Typography>

          <Box textAlign={"center"}>
            <Typography textAlign={"center"}>DL 9C AB 1234</Typography>
            <Typography textAlign={"center"}>Driver : Ram Singh</Typography>
            <Typography textAlign={"center"}>
              Distributor: Ashta Rides
            </Typography>
          </Box>

          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-evenly"}
            mt={2}>
            <Button
              sx={{
                backgroundColor: "#F4E6C4",
                color: "black",
                fontWeight: "bold",
                p: 2,
              }}
              onClick={handleBlockClose}>
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#FFBF2A",
                color: "black",
                fontWeight: "bold",
                p: 2,
              }}
              onClick={() => {
                // Add your block logic here
                handleBlockClose();
              }}>
              Block
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ManageUsers;
