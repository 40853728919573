import React from "react";
import { Snackbar, Alert } from "@mui/material";

const Notification = ({ messageAPI, message, severity, handleMessageAPI }) => {
  return (
    <Snackbar
      open={messageAPI}
      autoHideDuration={1400}
      onClose={handleMessageAPI}
      anchorOrigin={{ vertical: "top", horizontal: "right" }} // Position at top-right
    >
      <Alert
        onClose={handleMessageAPI}
        severity={severity}
        sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
