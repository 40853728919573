import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import { loadUser } from "../../redux/auth/action";
import Notification from "../NotificationAPI";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { user, loading, isAuthenticated } = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState("");
  const [messageAPI, setMessageAPI] = useState(false);
  const [severity, setSeverity] = useState("success");

  console.log("first", user);
  useEffect(() => {
    if (isAuthenticated && !user) {
      dispatch(loadUser(localStorage.getItem("distributor_access")));
    } else if (user) {
      setUserData(user.data);
    }
  }, [dispatch, isAuthenticated, user]);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      // Store the actual file in state, not the URL
      setUserData((prevState) => ({
        ...prevState,
        [name]: file, // Store the file object instead of URL
      }));
    }
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    setIsEditing(false);
    const formData = new FormData();
    formData.append("name", userData.name);
    formData.append("email", userData.email);
    formData.append("phone", userData.phone);
    formData.append("address", userData.address);
    formData.append("accountHolderName", userData.accountHolderName);
    formData.append("bankName", userData.bankName);
    formData.append("accountNumber", userData.accountNumber);
    formData.append("ifscCode", userData.ifscCode);

    // Append files only if they are instances of File
    if (userData.profile_url instanceof File) {
      formData.append("profile_url", userData.profile_url);
    }

    if (userData.checkbookPicUrl instanceof File) {
      formData.append("checkbookPicUrl", userData.checkbookPicUrl);
    }

    if (userData.aadhaarFrontPicUrl instanceof File) {
      formData.append("aadhaarFrontPicUrl", userData.aadhaarFrontPicUrl);
    }

    if (userData.aadhaarBackPicUrl instanceof File) {
      formData.append("aadhaarBackPicUrl", userData.aadhaarBackPicUrl);
    }

    if (userData.panCardPicUrl instanceof File) {
      formData.append("panCardPicUrl", userData.panCardPicUrl);
    }
    try {
      // Send the FormData object
      const res = await axios.put(
        "https://api.janatarides.com/api/distributors",
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("distributor_access"),
            "Content-Type": "multipart/form-data", // Ensure multipart/form-data header
          },
        }
      );

      // Check if the response is successful and has a message
      if (res?.status === 200 && res?.data?.message) {
        // console.log("Response message:", res.data.message);
        // dispatch(loadUser(localStorage.getItem("distributor_access")));
        setUserData(res.data.data);
        setMessage(res.data.message);
        setSeverity("success");
      } else {
        setMessage("Unexpected response from server");
        setSeverity("warning");
      }
    } catch (error) {
      // Handle error response more precisely
      if (error.response) {
        // The request was made, and the server responded with a status code outside the 2xx range
        setMessage(error.response.data?.message || "Server Error");
      } else if (error.request) {
        // The request was made but no response was received
        setMessage("No response received from the server");
      } else {
        // Something happened in setting up the request that triggered an error
        setMessage("Request failed: " + error.message);
      }
      setSeverity("error");
    } finally {
      // Show the Snackbar in all cases
      setMessageAPI(true);
    }
  };
  const handleMessageAPI = () => {
    setMessageAPI(false); // Close the Snackbar
  };

  const handleCancel = () => {
    // Revert changes to original user data
    setUserData(user.data);
    setIsEditing(false);
  };

  if (loading || !userData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
            <Avatar
              src={userData.profile_url || "default_avatar.png"}
              alt={userData.name}
              sx={{
                width: { xs: 100, md: 150 },
                height: { xs: 100, md: 150 },
                margin: "auto",
              }}
            />
            {isEditing && (
              <Box sx={{ mt: 2 }}>
                <Button variant="outlined" component="label" sx={{ mt: 1 }}>
                  Change Profile Picture
                  <input
                    type="file"
                    name="profile_url"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="h5">
              {isEditing ? (
                <TextField
                  name="name"
                  value={userData.name}
                  onChange={handleChange}
                  label="Name"
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%" }}
                />
              ) : (
                userData.name
              )}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Email
              </Typography>
              {isEditing ? (
                <TextField
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
              ) : (
                <Typography variant="body1">{userData.email}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Mobile
              </Typography>
              {isEditing ? (
                <TextField
                  name="phone"
                  value={userData.phone || ""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
              ) : (
                <Typography variant="body1">{userData.phone}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Address
              </Typography>
              {isEditing ? (
                <TextField
                  name="address"
                  value={userData.address || ""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              ) : (
                <Typography variant="body1">{userData.address}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Account Holder Name
              </Typography>
              {isEditing ? (
                <TextField
                  name="accountHolderName"
                  value={userData.accountHolderName || ""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              ) : (
                <Typography variant="body1">
                  {userData.accountHolderName}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Bank Name
              </Typography>
              {isEditing ? (
                <TextField
                  name="bankName"
                  value={userData.bankName || ""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              ) : (
                <Typography variant="body1">{userData.bankName}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Account Number
              </Typography>
              {isEditing ? (
                <TextField
                  name="accountNumber"
                  value={userData.accountNumber || ""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              ) : (
                <Typography variant="body1">
                  {userData.accountNumber}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                IFSC Code
              </Typography>
              {isEditing ? (
                <TextField
                  name="ifscCode"
                  value={userData.ifscCode || ""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              ) : (
                <Typography variant="body1">{userData.ifscCode}</Typography>
              )}
            </Grid>

            {/* Document 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Cheque Book
              </Typography>
              {isEditing ? (
                <TextField
                  name="checkbookPicUrl"
                  type="file"
                  onChange={handleFileChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              ) : userData.checkbookPicUrl ? (
                <a
                  href={userData.checkbookPicUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  View Cheque Book
                </a>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Not Uploaded
                </Typography>
              )}
            </Grid>

            {/* Document 3 */}
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Aadhar Front
              </Typography>
              {isEditing ? (
                <TextField
                  name="aadhaarFrontPicUrl"
                  type="file"
                  onChange={handleFileChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              ) : userData.aadhaarFrontPicUrl ? (
                <a
                  href={userData.aadhaarFrontPicUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  View Aadhar Front
                </a>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Not Uploaded
                </Typography>
              )}
            </Grid>

            {/* Document 4 */}
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Aadhar Back
              </Typography>
              {isEditing ? (
                <TextField
                  name="aadhaarBackPicUrl"
                  type="file"
                  onChange={handleFileChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              ) : userData.aadhaarBackPicUrl ? (
                <a
                  href={userData.aadhaarBackPicUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  View Aadhar Back
                </a>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Not Uploaded
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Pan Card Pic
              </Typography>
              {isEditing ? (
                <TextField
                  name="panCardPicUrl"
                  type="file"
                  onChange={handleFileChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              ) : userData.panCardPicUrl ? (
                <a
                  href={userData.panCardPicUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  Pan Card
                </a>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Not Uploaded
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ textAlign: "right", mt: 4 }}>
          {isEditing ? (
            <>
              <Button
                sx={{
                  ml: 2,
                  background: "#FFBF2A",
                  color: "black",
                  fontWeight: "bold",
                  p: 1,
                }}
                onClick={handleSave}>
                Save
              </Button>
              <Button
                // variant="outlined"
                // color="secondary"

                sx={{
                  ml: 2,
                  background: "#F4E6C4",
                  color: "black",
                  fontWeight: "bold",
                  p: 1,
                }}
                onClick={handleCancel}>
                Cancel
              </Button>
            </>
          ) : (
            <Button
              sx={{
                ml: 2,
                background: "#FFBF2A",
                color: "black",
                fontWeight: "bold",
                p: 1,
              }}
              onClick={toggleEdit}>
              Edit Profile
            </Button>
          )}
        </Box>
      </Paper>
      <Notification
        messageAPI={messageAPI}
        message={message}
        severity={severity}
        handleMessageAPI={handleMessageAPI}
      />
    </Box>
  );
};

export default ProfilePage;
