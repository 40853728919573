import React from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import DashboardHeaderCards from "./DashboardHeaderCards";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Fetch distributors with driver counts
const cardData = [
  {
    title: "Total Rides",
    value: "78",
    icon: "/ride_total.svg",
    
  },
  {
    title: "Rides Ongoing",
    value: 49,
    icon: "/ongoing_ride.svg",
  },
  {
    title: "Rides Cancelled",
    value: "29",
    icon: "/ride_cancel.svg",
  },
];
const ManageRides = () => {
  const rows = [
    {
      userDetails: {
        id: "6678cas4...",
        email: "superadcxmn@arv.com",
        mobile: "+98xxxxxxxx",
        address: "",
        name: "Aastha",
        numberOfRides: "",
        profile: "https://via.placeholder.com/80",
      },
      driverDetails: {
        id: "6678cas4...",
        name: "Chandan Singh",
        email: "chandan.singh@arv.com",
        mobile: "+91-987654321",
        address: "Shadi Pur Metro Station",
        distributor: "ABBC Distributors",
        profile: "https://via.placeholder.com/80",
      },

      rideDetails: {
        rideType: "Intercity",
        from: "CP",
        to: "Karol Bagh",
        distance: "5 KM",
        fare: "₹. 250",
      },
      status: {
        currentStatus: "On Going",
      },
    },
  ];

  const distributorColumns = [
    {
      field: "userDetails",
      headerName: "Users Details",
      width: 380,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Users Details
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Box>
            <img
              src={params.row.userDetails.profile}
              alt="Profile"
              style={{
                width: "80px",
                height: "80px",
                backgroundColor: "#ccc",
                marginRight: "16px",
                objectFit: "cover",
              }}
            />
            <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
              {params.row.userDetails.name}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "70px auto",
              gap: "7px",
            }}>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, md: 12, xl: 14 }}>
              ID:{" "}
            </Typography>
            <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
              {params.row.userDetails.id}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, md: 12, xl: 14 }}>
              Email:{" "}
            </Typography>
            <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
              {params.row.userDetails.email}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, md: 12, xl: 14 }}>
              Mobile:{" "}
            </Typography>
            <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
              {params.row.userDetails.mobile}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, md: 12, xl: 14 }}>
              Address:{" "}
            </Typography>
            <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
              {params.row.userDetails.address}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, md: 12, xl: 14 }}>
              No. of Rides:{" "}
            </Typography>
            <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}></Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "driverDetails",
      headerName: "Driver Details",
      width: 400,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Driver Details
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Box>
            <img
              src={params.row.driverDetails.profile}
              alt="Profile"
              style={{
                width: "80px",
                height: "80px",
                backgroundColor: "#ccc",
                marginRight: "16px",
                objectFit: "cover",
              }}
            />
            <Typography fontSize={{ xs: 10, md: 11, xl: 14 }}>
              {params.row.driverDetails.name}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "90px auto",
              gap: "7px",
            }}>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, md: 12, xl: 14 }}>
              ID:{" "}
            </Typography>
            <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
              {params.row.driverDetails.id}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, md: 12, xl: 14 }}>
              Email:{" "}
            </Typography>
            <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
              {params.row.driverDetails.email}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, md: 12, xl: 14 }}>
              Mobile:{" "}
            </Typography>
            <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
              {params.row.driverDetails.mobile}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, md: 12, xl: 14 }}>
              Address:{" "}
            </Typography>
            <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
              {params.row.driverDetails.address}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, md: 12, xl: 14 }}>
              Distributor:{" "}
            </Typography>
            <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
              {params.row.driverDetails.distributor}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "rideDetails",
      headerName: "Ride Details",
      // flex: 1,
      width: 200,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Ride Details
        </div>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "80px auto",
            gap: "7px",
          }}>
          <Typography fontWeight={"bold"} fontSize={{ xs: 10, md: 12, xl: 14 }}>
            Ride :
          </Typography>
          <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
            Intercity
          </Typography>

          <Typography fontWeight={"bold"} fontSize={{ xs: 10, md: 12, xl: 14 }}>
            From :
          </Typography>
          <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>CP </Typography>

          <Typography fontWeight={"bold"} fontSize={{ xs: 10, md: 12, xl: 14 }}>
            To :
          </Typography>
          <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>
            Patel Nagar
          </Typography>

          <Typography fontWeight={"bold"} fontSize={{ xs: 10, md: 12, xl: 14 }}>
            Distance :
          </Typography>
          <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>5 KM</Typography>
          <Typography fontWeight={"bold"} fontSize={{ xs: 10, md: 12, xl: 14 }}>
            Fair :
          </Typography>
          <Typography fontSize={{ xs: 9, md: 11, xl: 13 }}>₹. 250 </Typography>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      // width:200,
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Status</div>
      ),
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Status</div>
      ),
      renderCell: (params) => (
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          {/* <Button
              sx={{
                backgroundColor: "#C6EBE5",
                color: "#0DBA9F",
                fontWeight: "bold",
              }}>
              Completed
            </Button> */}
          <Button
            sx={{
              fontSize: { xs: 10, md: 12, xl: 13 },
              backgroundColor: "#C6C9E3",
              color: "#000C8E",
              fontWeight: "bold",
              pointerEvents: "none", // Disable cursor change
              "&:hover": {
                backgroundColor: "#C6EBE5", // Prevent hover color change
              },
            }}>
            On Going
          </Button>

          {/* <Button
              sx={{
                backgroundColor: "#EECACA",
                color: "#D66262",
                fontWeight: "bold",
              }}>
              Cancelled
            </Button> */}
        </Box>
      ),
    },
  ];
  const fetchRider = async () => {
    try {
      const res = await axios.get(
        "https://api.janatarides.com/api/rides//admin/rides",
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      console.log("getdata", res.data);
    } catch (err) {
      console.error("Error fetching distributors:", err);
    }
  };

  React.useEffect(() => {
    fetchRider();
  }, []);

  return (
    <Box>
      <DashboardHeaderCards cardData={cardData} />

      <Box py={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <TextField
              label="Search by Name or Mobile"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              label="Select Ride Status"
              variant="outlined"
              fullWidth
              select
              InputProps={{
                style: { height: "48px" },
              }}>
              <MenuItem value="service1">ALL</MenuItem>
              <MenuItem value="service2">On Going</MenuItem>
              <MenuItem value="service2">Completed</MenuItem>
              <MenuItem value="service2">Cancelled</MenuItem>
            </TextField>
          </Grid>

          {/* Date Picker */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="dd/mm/yyyy"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/* Export Button aligned with inputs */}
          <Grid item xs={12} sm={3} textAlign="right">
            <Button
              variant="contained"
              startIcon={
                <img
                  src={"/export.svg"}
                  alt="Export Icon"
                  style={{ width: "20px", height: "24px" }}
                />
              }
              sx={{
                backgroundColor: "#FFC107",
                color: "black",
                height: "48px",
                minWidth: "160px",
                fontWeight: "bold",
              }}>
              Export
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={distributorColumns}
          getRowId={(row) => row.driverDetails.id}
          pageSize={1}
          autoHeight={false}
          rowHeight={220}
        />
      </Box>
    </Box>
  );
};

export default ManageRides;
