import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ImageModal = ({ isOpen, onClose, imageUrl }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)", // Center the modal
          maxWidth: "80%", // Limit the modal's width to 80% of the viewport
          width: "100%",
          maxHeight: "80vh", // Limit the height of the modal to 80% of the viewport
          p: 2,
          backgroundColor: "#F5F5F5",
          borderRadius: "8px",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {/* Close button at the top-right corner */}
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width="100%">
          <IconButton onClick={onClose} sx={{ color: "red" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Image with responsive size */}
        <Box
          component="img"
          src={imageUrl}
          alt="Document"
          sx={{
            maxWidth: "100%",
            maxHeight: "70vh", // Restrict image height to 70% of the viewport height
            objectFit: "contain", // Keep the image aspect ratio
          }}
        />
      </Box>
    </Modal>
  );
};

export default ImageModal;
