import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ role }) => {
 const { isAuthenticated, user, loading } = useSelector((state) => state.auth);
 const location = useLocation(); // Get the current location path

 // Check if user is still loading
 if (loading) {
  return <div>Loading...</div>; // You can replace this with a loader component if needed
 }

 // If user is not authenticated, redirect based on route type
 if (!isAuthenticated || !user) {
  // If it's an admin route, redirect to /admin/login
  if (location.pathname.includes("/admin")) {
   return <Navigate to="/admin/login" />;
  }
  // If it's a distributor route, redirect to /distributor/login
  if (location.pathname.includes("/distributor")) {
   return <Navigate to="/distributor/login" />;
  }
 }

 // If the user exists but does not have the expected role, redirect accordingly
 if (user.data?.role !== role) {
  if (user.data?.role === "superadmin") {
   return <Navigate to="/admin" />;
  } else if (user.data?.role === "distributor") {
   return <Navigate to="/distributor" />; // Corrected
  }
 }

 // If authenticated and has the correct role, render the protected component
 return <Outlet />;
};

export default PrivateRoute;

// // src/components/auth/PrivateRoute.js
// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import { useSelector } from "react-redux";

// const PrivateRoute = ({ role }) => {
//   const { isAuthenticated, user, loading } = useSelector((state) => state.auth);

//   // Check if user is still loading
//   if (loading) {
//     return <div>Loading...</div>; // You can replace this with a loader component if needed
//   }

//   // If user is not authenticated, redirect to login
//   if (!isAuthenticated || !user) {
//     return <Navigate to="/admin/login" />;
//   }

//   // If the user exists but does not have the expected role, redirect accordingly
//   if (user.data?.role !== role) {
//     if (user.data?.role === "superadmin") {
//       return <Navigate to="/admin" />;
//     } else if (user.data?.role === "distributor") {
//       return <Navigate to="/distributor" />; // Corrected
//     }
//   }

//   // If authenticated and has the correct role, render the protected component
//   return <Outlet />;
// };

// export default PrivateRoute;
