import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import DashboardHeaderCards from "./DashboardHeaderCards";
import { DataGrid } from "@mui/x-data-grid";
const cardData = [
  {
    title: "Revenue",
    value: "₹ 1,23,678",
    color: "#1976d2",

    icon: "/revanue.svg",
    route: "/distributer/statement",
  },
  {
    title: "Withdrawal Done",
    value: "₹ 1,03,678",
    color: "#388e3c",
    //   icon: <DirectionsCarIcon sx={{ fontSize: 40, color: "#388e3c" }} />,
    icon: "/withdrawal.svg",
    route: "/distributer/cars",
  },
  {
    title: "Withdrawal Pending ",
    value: "₹ 20,678",
    color: "#d32f2f",
    icon: "/pending.svg",
    //   icon: <TrendingDownIcon sx={{ fontSize: 40, color: "#d32f2f" }} />,
    route: "/distributer/cars",
  },
];
const ManageWithdrawal = () => {
  const rows = [
    {
      userDetails: {
        id: "6678cas4...",
        email: "superadcxmn@arv.com",
        mobile: "+98xxxxxxxx",
        address: "",
        name: "Aastha",
        numberOfRides: "",
        profile: "https://via.placeholder.com/80",
      },
      status: {
        currentStatus: "On Going",
      },
    },
  ];
  const distributorColumns = [
    {
      field: "userDetails",
      headerName: "Users",
      //   flex:1,
      width: 220,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>User</div>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 8, // Add a little space between items
            backgroundColor: "transparent",
            mb: 4,
          }}>
          {/* Profile Image */}
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={2}
            alignItems={"center"}>
            {/* Fixed width for image */}
            <img
              src={"/account.svg"}
              alt="Profile"
              style={{
                width: "50px",
                height: "50px",

                objectFit: "cover",
                borderRadius: "4px",
              }}
            />

            <Box sx={{}}>
              <Typography
                fontWeight={"bold"}
                fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
                Chandan singh
              </Typography>
              <Typography
                fontWeight={"bold"}
                fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}
                sx={{
                  wordWrap: "break-word", // Allow text to wrap to the next line
                  whiteSpace: "normal", // Prevent text from sticking to one line
                }}>
                ABBC Distrubutors
              </Typography>
            </Box>
          </Box>
        </Box>
      ),
    },

    {
      field: "balanceAmount",
      headerName: "balanceAmount",
      width: 140,

      renderHeader: () => (
        <div
          style={{
            fontWeight: "bold",
            fontSize: "0.8rem",
            // textAlign: "center",
          }}>
          Balance Amount
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ minWidth: 150, backgroundColor: "transparent" }}>
          <Typography
            // sx={{ textAlign: "center" }}
            fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}
            fontWeight={"bold"}>
            ₹20, 678/-
          </Typography>
        </Box>
      ),
    },

    {
      field: "withdrawal",
      headerName: "withdrawal",
      width: 200,

      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Withdrawal Req. Amount
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ minWidth: 150, backgroundColor: "transparent" }}>
          <Typography
            // sx={{ textAlign: "center" }}
            fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}
            fontWeight={"bold"}>
            ₹10, 678/-
          </Typography>
        </Box>
      ),
    },

    {
      field: "bankDetails",
      headerName: "bankDetails",
      width: 280,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Bank Details
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ minWidth: 150, backgroundColor: "transparent" }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "120px auto",
              gap: "8px",
            }}>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
              Name{" "}
            </Typography>
            <Typography fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
              : Nabanita Goswami
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
              Account Number{" "}
            </Typography>
            <Typography fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
              : 40410100010945
            </Typography>
            {/* static name and bank name */}

            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
              IFSC{" "}
            </Typography>
            <Typography fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
              : BARB0NEWBAR
            </Typography>
          </Box>
        </Box>
      ),
    },

    {
      field: "requestDate",
      headerName: "requestDate",
      width: 160,

      renderHeader: () => (
        <div
          style={{
            fontWeight: "bold",
            fontSize: "0.8rem",
            textAlign: "center",
          }}>
          Req. Date
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ minWidth: 150, backgroundColor: "transparent" }}>
          <Typography
            // sx={{ textAlign: "center" }}
            fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}
            fontWeight={"bold"}>
            18 sep 2024: 08:35 PM
          </Typography>
        </Box>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      // flex: 1,
      width: 120,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Status</div>
      ),
      renderCell: (params) => (
        <Box sx={{ backgroundColor: "transparent" }}>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Button
              sx={{
                fontSize: { xs: 10, md: 11, xl: 13 },
                backgroundColor: "#F2DEC6",
                color: "#DB7500",
                fontWeight: "bold",
                pointerEvents: "none", // Disable cursor change
                "&:hover": {
                  backgroundColor: "#F2DEC6", // Prevent hover color change
                },
              }}>
              Pending
            </Button>
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <DashboardHeaderCards cardData={cardData} />
      <Box py={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <TextField
              label="Search by Name or Mobile"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              label="Select Status"
              variant="outlined"
              fullWidth
              select
              InputProps={{
                style: { height: "48px" },
              }}>
              <MenuItem value="service1">ALL</MenuItem>
              <MenuItem value="service2">Pending</MenuItem>
              <MenuItem value="service2">Completed</MenuItem>
            </TextField>
          </Grid>

          {/* Date Picker */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="dd/mm/yyyy"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/* Export Button aligned with inputs */}
          <Grid item xs={12} sm={3} textAlign="right">
            <Button
              variant="contained"
              startIcon={
                <img
                  src={"/export.svg"}
                  alt="Export Icon"
                  style={{ width: "20px", height: "24px" }}
                />
              }
              sx={{
                backgroundColor: "#FFC107",
                color: "black",
                height: "48px",
                minWidth: "160px",
                fontWeight: "bold",
              }}>
              Export
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={distributorColumns}
          getRowId={(row) => row.userDetails.id}
          pageSize={1}
          autoHeight={false}
          rowHeight={240}
        />
      </Box>
    </Box>
  );
};

export default ManageWithdrawal;
