import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  IconButton,
  Modal,
  Button,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DistributorFormAdmin from "./DistributorFormAdmin";
import DashboardHeaderCards from "../DashboardHeaderCards";
import { useNavigate } from "react-router-dom";
import ImageModal from "../ImageModal";
const ManageDistributors = () => {
  const [distributors, setDistributors] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [totalPages, setTotalPages] = useState(0);
  const [openDistributorModal, setOpenDistributorModal] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [cmtMessage, setCmtMessage] = useState("");
  const [block, setBlock] = useState(false);
  const [approve, setApprove] = useState(false);
  const [DetailedById, setDetailedById] = useState(null);
  const [distributorFilters, setDistributorFilters] = useState({
    searchTerm: "",
    date: "",
    status: "",
  });
  const [totalDetails, setTotalDetails] = useState({});
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const navigation = useNavigate();
  const handleBlockOpen = (id) => {
    setBlock(true);
    getDetailSingle(id);
  };

  const handleBlockClose = () => {
    setBlock(false);
  };

  const handleApproveOpen = async (id) => {
    setApprove(true);
    getDetailSingle(id);
  };

  const handleOpenImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  const getDetailSingle = async (id) => {
    try {
      const res = await axios.get(
        `https://api.janatarides.com/api/admin/distributors/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );
      setDetailedById(res.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleApproveAPI = async (id) => {
    try {
      const res = await axios.put(
        `https://api.janatarides.com/api/admin/distributors/${id}`,
        {
          status: "approved",
        },
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );
      fetchDistributors();
      handleApproveClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRejectAPI = async (id) => {
    try {
      const res = await axios.put(
        `https://api.janatarides.com/api/admin/distributors/${id}`,
        {
          status: "rejected",
          message: cmtMessage,
        },
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );
      fetchDistributors();
      handleApproveClose();
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleBlockAPI = async (id, isCurrentlyBlocked) => {
    try {
      const newBlockState = !isCurrentlyBlocked;
      const res = await axios.put(
        `https://api.janatarides.com/api/admin/distributors/${id}`,
        {
          isBlocked: newBlockState, // Set the new block state
        },
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );

      fetchDistributors();
      handleBlockClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleApproveClose = () => {
    setApprove(false);
  };

  useEffect(() => {
    fetchDistributors(page, pageSize);
  }, [page, pageSize]);

  const fetchDistributors = async (page = 1, limit = 10) => {
    try {
      const res = await axios.get(
        `https://api.janatarides.com/api/admin/distributors?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );
      setDistributors(res.data.data);
      setTotalPages(res.data.pagination.totalPages);
      setTotalDetails(res.data.pagination);
    } catch (err) {
      console.error("Error fetching distributors:", err);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    fetchDistributors(newPage + 1, pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    fetchDistributors(page, newPageSize);
  };

  const handleOpenDistributorModal = () => {
    setOpenDistributorModal(true);
  };

  const handleCloseDistributorModal = () => {
    setOpenDistributorModal(false);
  };

  const handleDistributorAdded = () => {
    fetchDistributors();
  };

  const filteredDistributors = distributors.filter((distributor) => {
    const matchesSearchTerm =
      distributor.name
        .toLowerCase()
        .includes(distributorFilters.searchTerm.toLowerCase()) ||
      (distributor.mobile &&
        distributor.mobile.includes(distributorFilters.searchTerm));

    const matchesDate = distributorFilters.date
      ? new Date(distributor.createdAt).toLocaleDateString() ===
        new Date(distributorFilters.date).toLocaleDateString()
      : true;

    const matchesStatus = distributorFilters.status
      ? distributor.status === distributorFilters.status
      : true;

    return matchesSearchTerm && matchesDate && matchesStatus;
  });

  const handleDownloadDistributors = async () => {
    try {
      const { data } = await axios.get(
        "https://api.janatarides.com/api/admin/distributors?limit=1000",
        {
          headers: {
            Authorization: localStorage.getItem("admin_access"),
          },
        }
      );

      const exportData = data.data.map((dist) => ({
        ID: dist._id,
        Name: dist.name,
        Email: dist.email,
        Mobile: dist.phone,
        Address: dist.address,
        Bank_Name: dist.bankName,
        Account_Number: dist.accountNumber,
        Account_HolderName: dist.accountHolderName,
        IFSC: dist.ifscCode,
        Status: dist.status,
        Blocked: dist.isBlocked === false ? "Published" : "Blocked",
        "Created At": new Date(dist.createdAt).toLocaleString(),
      }));

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Distributors");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, "Distributors.xlsx");
    } catch (error) {
      console.error("Error downloading distributors:", error);
    }
  };

  const handleDistributorAPI = (id) => {
    navigation("/admin/manage-cars", { state: { id } });
  };
  const distributorColumns = [
    {
      field: "details",
      headerName: "Distributor Details",
      // width: 460,
      flex: 1.1,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Distributor Details
        </div>
      ),
      renderCell: (params) => (
        <Box py={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: { md: "start", xl: "center" },
              gap: { md: "20px", xl: "50px" },
              flexDirection: { md: "column", xl: "row" },
            }}>
            <Box>
              <img
                src={params.row.profile_url}
                alt="Profile"
                style={{
                  width: "80px",
                  height: "80px",
                  marginRight: "16px",
                  objectFit: "cover",
                }}
              />
              <Typography
                fontSize={{ xs: 10, md: 12, xl: 13 }}
                sx={{
                  wordWrap: "break-word", // Allow text to wrap to the next line
                  whiteSpace: "normal", // Prevent text from sticking to one line
                }}>
                {params.row.name}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                // gridTemplateColumns: "80px auto",
                gridTemplateColumns: { md: "70px auto", xl: "80px auto" },
                gap: "7px",
              }}>
              <Typography
                fontWeight={"bold"}
                fontSize={{ xs: 8, md: 11, xl: 13 }}>
                ID :
              </Typography>
              <Typography
                fontSize={{ xs: 8, md: 11, xl: 12 }}
                sx={{
                  wordWrap: "break-word", // Allow text to wrap to the next line
                  whiteSpace: "normal", // Prevent text from sticking to one line
                }}>
                {params.row._id}
              </Typography>
              <Typography
                fontWeight={"bold"}
                fontSize={{ xs: 8, md: 11, xl: 13 }}>
                Email :
              </Typography>
              <Typography
                fontSize={{ xs: 8, md: 11, xl: 12 }}
                sx={{
                  wordWrap: "break-word", // Allow text to wrap to the next line
                  whiteSpace: "normal", // Prevent text from sticking to one line
                }}>
                {params.row.email}
              </Typography>
              <Typography
                fontWeight={"bold"}
                fontSize={{ xs: 8, md: 11, xl: 13 }}>
                Mobile :
              </Typography>
              <Typography
                fontSize={{ xs: 8, md: 11, xl: 12 }}
                sx={{
                  wordWrap: "break-word", // Allow text to wrap to the next line
                  whiteSpace: "normal", // Prevent text from sticking to one line
                }}>
                {params.row.phone}
              </Typography>
              <Typography
                fontWeight={"bold"}
                fontSize={{ xs: 8, md: 11, xl: 13 }}>
                Address :
              </Typography>
              <Typography
                fontSize={{ xs: 8, md: 11, xl: 12 }}
                width={"90%"}
                sx={{
                  wordWrap: "break-word", // Allow text to wrap to the next line
                  whiteSpace: "normal", // Prevent text from sticking to one line
                }}>
                {params.row.address}
              </Typography>
              {/* <Typography
                fontWeight={"bold"}
                fontSize={{ xs: 11, md: 11, xl: 13 }}>
                No. of Cars{" "}:
              </Typography>
              <Typography fontSize={{ xs: 10, md: 12, xl: 13 }}>
                {50}
              </Typography> */}
              <Typography
                fontWeight={"bold"}
                fontSize={{ xs: 8, md: 11, xl: 13 }}>
                Joined on :
              </Typography>
              <Typography
                fontSize={{ xs: 8, md: 11, xl: 12 }}
                sx={{
                  wordWrap: "break-word", // Allow text to wrap to the next line
                  whiteSpace: "normal", // Prevent text from sticking to one line
                }}>
                {new Date(params.row.createdAt).toLocaleDateString()}{" "}
                {new Date(params.row.createdAt).toLocaleTimeString()}
              </Typography>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      field: "bankDetails",
      headerName: "Bank Details",
      // width: 500,
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Bank Details
        </div>
      ),
      renderCell: (params) => (
        <Box
          py={2}
          sx={{
            display: "grid",
            gridTemplateColumns: { md: "100px auto", xl: "120px auto" },
            gap: "10px",
          }}>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            Name :
          </Typography>
          <Typography
            fontSize={{ xs: 10, md: 10, xl: 12 }}
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}>
            {params.row.accountHolderName}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            Bank Name :
          </Typography>
          <Typography
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}
            fontSize={{ xs: 10, md: 10, xl: 12 }}>
            {params.row.bankName}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            Account Number :
          </Typography>
          <Typography
            fontSize={{ xs: 10, md: 10, xl: 12 }}
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}>
            {params.row.accountNumber}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            IFSC :
          </Typography>
          <Typography
            fontSize={{ xs: 10, md: 10, xl: 12 }}
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}>
            {params.row.ifscCode}
          </Typography>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      // width: 140,
      flex: 0.4,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Status</div>
      ),
      renderCell: (params) => (
        <Box display={"flex"} flexDirection={"column"} gap={2} py={2}>
          <Box>
            {params.row.status === "approved" && (
              <Button
                sx={{
                  fontSize: { xs: 6, md: 10, xl: 13 },
                  backgroundColor: "#C6EBE5",
                  color: "#0DBA9F",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#C6EBE5",
                  },
                }}>
                Approved
              </Button>
            )}

            {params.row.status === "pending" && (
              <Button
                sx={{
                  fontSize: { xs: 6, md: 10, xl: 13 },
                  backgroundColor: "#D5C6F3",
                  color: "#4A00E0",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#D5C6F3",
                  },
                }}>
                Pending
              </Button>
            )}

            {params.row.status === "rejected" && (
              <Button
                sx={{
                  fontSize: { xs: 6, md: 10, xl: 13 },
                  backgroundColor: "#EECACA",
                  color: "#D66262",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#EECACA",
                  },
                }}
                onClick={handleBlockOpen}>
                Rejected
              </Button>
            )}
          </Box>

          <Box>
            {params.row.isBlocked ? (
              <Button
                sx={{
                  fontSize: { xs: 6, md: 10, xl: 13 },
                  backgroundColor: "#EECACA",
                  color: "#D66262",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#EECACA",
                  },
                }}>
                Blocked
              </Button>
            ) : (
              <Button
                sx={{
                  fontSize: { xs: 6, md: 10, xl: 13 },
                  backgroundColor: "#C6EBE5",
                  color: "#0DBA9F",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#C6EBE5",
                  },
                }}>
                Published
              </Button>
            )}
          </Box>
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      // width: 140,
      flex: 0.5,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Action</div>
      ),
      renderCell: (params) => (
        <Box display={"flex"} flexDirection={"column"} gap={1} py={2}>
          <Button
            sx={{
              fontSize: { xs: 6, md: 10, xl: 13 },
              backgroundColor: "#C6EBE5",
              color: "#0DBA9F",
              fontWeight: "bold",
            }}
            onClick={() => handleApproveOpen(params.row._id)}>
            Approve
          </Button>

          <Button
            sx={{
              fontSize: { xs: 6, md: 10, xl: 13 },
              backgroundColor: "#EECACA",
              color: "#D66262",
              fontWeight: "bold",
            }}
            onClick={() => handleBlockOpen(params.row._id)}>
            {params.row.isBlocked ? "Unblock" : "Block"}
          </Button>

          <Typography
            sx={{
              color: "#333333",
              fontWeight: "bold",
              cursor: "pointer",
              textAlign: "center",
            }}
            fontSize={{ xs: 6, md: 12, xl: 13 }}
            onClick={() => handleDistributorAPI(params.row._id)}>
            View Cars
          </Typography>
        </Box>
      ),
    },
  ];

  const cardData = [
    {
      title: "Total Distributors",
      value: totalDetails.totalDistributors,
      icon: "/total_distributors.svg",
    },
    {
      title: "Distributors Approved",
      value: totalDetails.totalDistributorsApproved,
      icon: "/distributors_approve.svg",
    },
    {
      title: "Distributors Rejected",
      value: totalDetails.totalDistributorsRejected,
      icon: "/distribytor_reject.svg",
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          mb: "2",
        }}>
        <IconButton onClick={handleOpenDistributorModal} color="#FFBF2A">
          <Button
            sx={{
              backgroundColor: "#FFC107",
              color: "black",
              height: "48px",
              minWidth: "160px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#FFB300",
              },
            }}>
            Add Distributor
          </Button>
        </IconButton>
      </Box>

      <DashboardHeaderCards cardData={cardData} />

      <Grid container spacing={2} py={6}>
        <Grid item xs={12} sm={3}>
          <TextField
            InputLabelProps={{
              sx: {
                fontSize: { md: "0.7rem", lg: "1rem" }, // Responsive font sizes for label
              },
            }}
            label="Search by Name or Mobile"
            variant="outlined"
            fullWidth
            value={distributorFilters.searchTerm}
            onChange={(e) =>
              setDistributorFilters({
                ...distributorFilters,
                searchTerm: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            InputLabelProps={{
              sx: {
                fontSize: { md: "0.7rem", lg: "1rem" }, // Responsive font sizes for label
              },
            }}
            label="Select Distributor Status"
            variant="outlined"
            fullWidth
            select
            value={distributorFilters.status} // Add this line
            onChange={(e) =>
              setDistributorFilters({
                ...distributorFilters,
                status: e.target.value,
              })
            }>
            <MenuItem value="">ALL</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="pending">Approval Pending</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            InputLabelProps={{
              shrink: true,
              sx: {
                fontSize: { md: "0.7rem", lg: "1rem" }, // Responsive font sizes for label
              },
            }}
            // label="Date"
            type="date"
            variant="outlined"
            fullWidth
            value={distributorFilters.date}
            onChange={(e) =>
              setDistributorFilters({
                ...distributorFilters,
                date: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={3} textAlign="right">
          <Button
            variant="contained"
            startIcon={
              <img
                src={"/export.svg"}
                alt="Export Icon"
                style={{ width: "20px", height: "24px" }}
              />
            }
            sx={{
              backgroundColor: "#FFC107",
              color: "black",
              height: "48px",
              minWidth: "160px",
              fontWeight: "bold",
            }}
            onClick={() => handleDownloadDistributors()}>
            Export
          </Button>
        </Grid>
      </Grid>

      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: `calc(90vh - 150px)`,
        }}> */}
      {/* <Box sx={{ flexGrow: 1, minHeight: "500px" }}> */}
      <DataGrid
        rows={filteredDistributors}
        columns={distributorColumns}
        getRowId={(row) => row._id}
        pageSize={pageSize}
        rowCount={totalPages * pageSize} // Set the total number of rows
        paginationMode="server" // Enable server-side pagination
        onPageChange={handlePageChange} // Handle page changes
        onPageSizeChange={handlePageSizeChange} // Handle page size changes
        page={page - 1} // DataGrid uses 0-indexed page, API uses 1-indexed
        autoHeight={"auto"} // Set auto height for the grid
        getRowHeight={() => "auto"}
        pagination
        sx={{
          width: "100%", // Ensure the grid takes full width of the container
          "& .MuiDataGrid-columnHeader": {
            fontWeight: "bold",
            fontSize: { xs: "0.6rem", md: "0.8rem" }, // Responsive font size
          },
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal", // Wrap text for better responsiveness
            padding: { xs: "8px", md: "16px" }, // Responsive padding
          },
        }}
      />

      {/* </Box> */}
      {/* </Box> */}

      {/* Distributor Modal */}
      <DistributorFormAdmin
        open={openDistributorModal}
        onClose={handleCloseDistributorModal}
        onDistributorAdded={handleDistributorAdded}
      />

      <Modal open={block} onClose={handleBlockClose}>
        {DetailedById ? (
          <Box
            sx={{
              p: 3,
              maxWidth: { xs: "90%", lg: "30%" },
              margin: "auto",
              mt: "20vh",
              borderRadius: "md",
              backgroundColor: "#D9D9D9",
            }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <IconButton onClick={handleBlockClose} sx={{ color: "red" }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography
              fontWeight={"bold"}
              fontSize={18}
              textAlign={"center"}
              mb={3}>
              Block/Unblock Distributors
            </Typography>

            <Typography textAlign={"center"} mb={3}>
              {DetailedById.isBlocked
                ? "Do you want to unblock this distributor?"
                : "Do you want to block this distributor?"}
            </Typography>

            <Typography textAlign={"center"} mb={6}>
              {DetailedById.name}
            </Typography>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              mt={2}>
              <Button
                sx={{
                  backgroundColor: "#F4E6C4",
                  color: "black",
                  fontWeight: "bold",
                  p: 1.4,
                  fontSize: { xs: 10, md: 12, xl: 14 },
                }}
                onClick={handleBlockClose}>
                Cancel
              </Button>

              <Button
                sx={{
                  backgroundColor: "#FFBF2A",
                  color: "black",
                  fontWeight: "bold",
                  p: 1.4,
                  fontSize: { xs: 10, md: 12, xl: 14 },
                }}
                onClick={() => {
                  // Call handleBlockAPI with the current state of isBlocked
                  handleBlockAPI(DetailedById._id, DetailedById.isBlocked);
                }}>
                {DetailedById.isBlocked ? "Unblock" : "Block"}
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography>Loading...</Typography> // Fallback to show loading state or empty content
        )}
      </Modal>

      <Modal open={approve} onClose={handleApproveClose}>
        {DetailedById ? (
          <Box
            sx={{
              p: 4,
              maxWidth: { xs: "90%", lg: "60%" },
              margin: "auto",
              mt: "5vh",
              borderRadius: "8px",
              backgroundColor: "#F5F5F5",
              boxShadow: 24,
              maxHeight: "90vh",
              overflowY: "auto",
            }}>
            {/* Modal Header */}
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <IconButton onClick={handleApproveClose} sx={{ color: "red" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 18, md: 20, xl: 24 }}
              textAlign={"center"}
              mb={3}>
              Approve Distributor
            </Typography>

            {/* Distributor Details */}
            <Box
              display="flex"
              gap={{ xs: 5, lg: 12 }}
              mb={3}
              flexDirection={{ xs: "column", lg: "row" }}>
              <Box>
                <Typography
                  fontWeight="bold"
                  fontSize={{ xs: 13, md: 14, xl: 15 }}
                  mb={3}>
                  Distributor Details
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mb={3}
                  gap={4}
                  flexDirection={{ xs: "column", lg: "row" }}>
                  <Box>
                    <Box
                      sx={{
                        width: { xs: 100, lg: 120 },
                        height: { xs: 100, lg: 120 },
                        backgroundColor: "white",
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}>
                      <img
                        src={DetailedById.profile_url}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <Typography
                      fontWeight="medium"
                      fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      {DetailedById.name}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "90px auto",
                      gap: "10px",
                    }}>
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      ID
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {DetailedById._id}
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      Email
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {DetailedById.email}
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      Mobile
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {DetailedById.phone}
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      Address
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {DetailedById.address}
                    </Typography>
                    {/* <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      No. of Cars
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      50
                    </Typography>{" "} */}
                    {/* Replace with real value if available */}
                    <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                      Joined on
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                      {new Date(DetailedById.createdAt).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Bank Details */}
              <Box display="flex" flexDirection={"column"}>
                <Typography
                  fontWeight="bold"
                  fontSize={{ xs: 10, md: 14, xl: 15 }}
                  mb={3}>
                  Bank Details
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "120px auto",
                    gap: "10px",
                  }}>
                  <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                    Name
                  </Typography>
                  <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                    {DetailedById.accountHolderName}
                  </Typography>

                  <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                    Bank Name
                  </Typography>
                  <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                    {DetailedById.bankName}
                  </Typography>

                  <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                    Account Number
                  </Typography>
                  <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                    {DetailedById.accountNumber}
                  </Typography>

                  <Typography fontSize={{ xs: 10, md: 14, xl: 15 }}>
                    IFSC Code
                  </Typography>
                  <Typography fontSize={{ xs: 10, md: 12, xl: 14 }}>
                    {DetailedById.ifscCode}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Horizontal Divider */}
            <Box mb={3}>
              <hr />
            </Box>

            {/* Document Upload Section */}
            <Box
              display="flex"
              gap={{ xs: 5, lg: 10 }}
              mb={3}
              flexDirection={{ xs: "column", lg: "row" }}>
              {/* Aadhaar Card */}
              <Box
                onClick={() =>
                  handleOpenImageModal(DetailedById.aadhaarFrontPicUrl)
                }
                style={{ cursor: "pointer" }}>
                <Box
                  sx={{
                    width: { xs: 100, lg: 120 },
                    height: { xs: 100, lg: 120 },
                    backgroundColor: "white",
                    mb: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                  }}>
                  <img
                    src={DetailedById.aadhaarFrontPicUrl}
                    alt="Aadhaar Front"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                    }}
                  />
                </Box>
                <Typography
                  fontWeight="medium"
                  fontSize={{ xs: 10, md: 14, xl: 15 }}>
                  Aadhaar Card
                </Typography>
              </Box>

              {/* PAN Card */}
              <Box
                onClick={() => handleOpenImageModal(DetailedById.panCardPicUrl)}
                style={{ cursor: "pointer" }}>
                <Box
                  sx={{
                    width: { xs: 100, lg: 120 },
                    height: { xs: 100, lg: 120 },
                    backgroundColor: "white",
                    mb: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                  }}>
                  <img
                    src={DetailedById.panCardPicUrl}
                    alt="PAN Card"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                    }}
                  />
                </Box>
                <Typography
                  fontWeight="medium"
                  fontSize={{ xs: 10, md: 14, xl: 15 }}>
                  PAN Card
                </Typography>
              </Box>
            </Box>

            {/* Comments Section */}
            <Box
              sx={{
                backgroundColor: "#D9D9D9",
                borderRadius: 2,
                py: 1,
                px: 1,
              }}>
              <TextField
                name="cmtMessage"
                value={cmtMessage}
                fontSize={{ xs: 10, md: 14, xl: 15 }}
                sx={{
                  width: "100%",
                  border: "none",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { border: "none" },
                    "&:hover fieldset": { border: "none" },
                    "&.Mui-focused fieldset": { border: "none" },
                  },
                }}
                label="Comments"
                variant="outlined"
                onChange={(e) => setCmtMessage(e.target.value)}
              />
            </Box>

            {/* Action Buttons */}
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"space-between"}
              mt={4}>
              <Box display="flex" alignItems={"center"} gap={8}>
                <Button
                  sx={{
                    backgroundColor: "#BEBEBE",
                    color: "#5E5E5E",
                    fontSize: { xs: 10, md: 14, xl: 15 },
                    fontWeight: "bold",
                    p: { xs: 1, lg: 1.5 },
                  }}
                  onClick={handleApproveClose}>
                  Cancel
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#F8D7DA",
                    color: "#C41212",
                    fontSize: { xs: 10, md: 14, xl: 15 },
                    fontWeight: "bold",
                    p: { xs: 1, lg: 1.5 },
                  }}
                  onClick={() => {
                    handleRejectAPI(DetailedById._id);
                  }}>
                  Reject
                </Button>
              </Box>

              <Button
                sx={{
                  backgroundColor: "#C6EBE5",
                  color: "#0DBA9F",
                  fontSize: { xs: 10, md: 14, xl: 15 },
                  fontWeight: "bold",
                  p: { xs: 1, lg: 1.5 },
                }}
                onClick={() => {
                  // Add your approve logic here
                  handleApproveAPI(DetailedById._id);
                }}>
                Approve
              </Button>
            </Box>
            <ImageModal
              isOpen={isImageModalOpen}
              onClose={handleCloseImageModal}
              imageUrl={selectedImage}
            />
          </Box>
        ) : (
          <Typography>Loading...</Typography> // Fallback to show loading state or empty content
        )}
      </Modal>
    </Box>
  );
};

export default ManageDistributors;
