import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  InputAdornment,
  MenuItem,
  Modal,
  IconButton,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { DataGrid } from "@mui/x-data-grid";
import DashboardHeaderCards from "../DashboardHeaderCards";
import { DialogContent } from "@mui/material";
import DistributorForm from "../distributer/DistributorForm";
import axios from "axios";
import DistributorEditForm from "./DistributorEditForm";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import ImageModal from "../ImageModal";

const DistributorCars = () => {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [totalPages, setTotalPages] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [singleDetails, setSingleDetails] = useState({});
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [block, setBlock] = useState(false);
  const [selectedBlcokDriver, setSelectedBlcokDriver] = useState(null);
  const [totalDetails, setTotalDetails] = useState({});
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const handleBlockOpen = (driver) => {
    setSelectedBlcokDriver(driver); // Store the selected distributor
    setBlock(true);
  };

  const handleBlockClose = () => {
    setBlock(false);
    setSelectedBlcokDriver(null); // Reset the selected distributor
  };

  const handleBlockAPI = async (id, isCurrentlyBlocked) => {
    try {
      const newBlockState = !isCurrentlyBlocked;
      const res = await axios.patch(
        `https://api.janatarides.com/api/distributors/drivers/${id}`,
        {
          isBlocked: newBlockState,
        },
        {
          headers: {
            Authorization: localStorage.getItem("distributor_access"),
          },
        }
      );
      fetchDistributors();
      handleBlockClose();
    } catch (err) {
      console.error("Error fetching distributors:", err);
    }
  };

  const handleEditOpen = (id) => {
    setSelectedDriverId(id);
    setIsEditMode(true);
  };

  const handleEditClose = () => {
    setIsEditMode(false);
    setSelectedDriverId(null);
  };

  const handleViewOpen = async (id) => {
    setIsViewMode(true);
    try {
      const res = await axios.get(
        `https://api.janatarides.com/api/distributors/drivers/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("distributor_access"),
          },
        }
      );
      // console.log("res",res.data.data)
      setSingleDetails(res.data.data);
    } catch (error) {
      console.log("errro", error);
    }
  };

  const handleViewClose = () => {
    setIsViewMode(false);
  };
  const fetchDistributors = async (page = 1, limit = 10) => {
    try {
      const res = await axios.get(
        `https://api.janatarides.com/api/distributors/drivers?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: localStorage.getItem("distributor_access"),
          },
        }
      );
      setDetails(res.data.data);
      // Update pagination details from the API response
      setTotalPages(res.data.pagination.totalPages);
      setTotalDetails(res.data.pagination);
      console.log(
        "refresh token",
        localStorage.getItem("distributor_refresh_token")
      );
    } catch (err) {
      console.error("Error fetching distributors:", err);
    }
  };

  const handleDownloadDriver = async () => {
    try {
      const { data } = await axios.get(
        "https://api.janatarides.com//api/distributors/drivers?limit=1000",
        {
          headers: {
            Authorization: localStorage.getItem("distributor_access"),
          },
        }
      );

      const exportData = data.data.map((dist) => ({
        ID: dist._id,
        Name: dist.driverName,
        Email: dist.email,
        Mobile: dist.mobile,
        Address: dist.address,
        Bank_Name: dist.bankName,
        Account_Number: dist.accountNumber,
        Account_HolderName: dist.accountHolderName,
        IFSC: dist.ifscCode,
        CarModel_Name: dist.carModelName,
        Registration_Number: dist.registrationNumber,
        vehicle_type: dist.vehicalType,
        Service_Type: dist.serviceType.join(", "),
        Status: dist.status,
        Blocked: dist.isOnline === false ? "Published" : "Blocked",
        "Created At": new Date(dist.createdAt).toLocaleString(),
      }));

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Driver");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, "Driver.xlsx");
    } catch (error) {
      console.error("Error downloading driver:", error);
    }
  };

  const cardData = [
    {
      title: "Total Added Vehicles",
      value: totalDetails.totalDrivers,

      icon: "/car.svg",
    },
    {
      title: "Vehicles Approved",
      value: totalDetails.totalApprovedDrivers,

      icon: "/distributors_approve.svg",
    },
    {
      title: "Vehicles Rejected",
      value: totalDetails.totalRejectedDrivers,

      icon: "/distribytor_reject.svg",
    },
  ];

  const handlePageChange = (newPage) => {
    setPage(newPage + 1); // DataGrid's page index starts at 0, API's page starts at 1
    fetchDistributors(newPage + 1, pageSize);
  };

  // Handle page size change
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    fetchDistributors(page, newPageSize);
  };

  useEffect(() => {
    // Fetch data on component mount or when page/pageSize changes
    fetchDistributors(page, pageSize);
  }, [page, pageSize]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const rows = details.map((driver) => ({
    ...driver,
    bankDetails: {
      accountHolderName: driver.accountHolderName,
      accountNumber: driver.accountNumber,
      bankName: driver.bankName,
      ifscCode: driver.ifscCode,
    },
    carDetails: {
      carpic: driver.carFrontendPicUrl,
      model: driver.carModelName,
      registration: driver.registrationNumber,
    },
  }));

  const distributorColumns = [
    {
      field: "driverDetails",
      headerName: "Driver Details",
      // width: 350,
      flex: 1.5,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Driver Details
        </div>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: { md: "start", xl: "center" },
            gap: { md: "20px", xl: "50px" },
            flexDirection: { md: "column", xl: "row" },
          }}
          py={2}>
          <Box>
            <img
              // src={params.row.driverDetails?.profile || "/default_profile.png"} // Use a fallback image if profile is missing
              src={params.row.driverProfilePicUrl || "/default_profile.png"}
              alt="Profile"
              style={{
                width: "80px",
                height: "80px",
                // backgroundColor: "#ccc",
                marginRight: "16px",
                objectFit: "cover",
              }}
            />
            <Typography
              fontSize={{ xs: 10, md: 12, xl: 13 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.driverName}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { md: "80px auto", xl: "100px auto" },

              gap: "7px",
            }}>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              ID :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row._id}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              Email :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.email}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Mobile :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.mobile}
            </Typography>
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Address :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.address}
            </Typography>

            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Joined on :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {new Date(params.row.createdAt).toLocaleDateString()}{" "}
              {new Date(params.row.createdAt).toLocaleTimeString()}
            </Typography>

            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Service Type :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.serviceType.join(", ")}
            </Typography>

            {/* <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              Distributor :
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
            </Typography> */}
            <Typography
              fontWeight={"bold"}
              fontSize={{ xs: 8, md: 11, xl: 13 }}>
              vehicle Type
            </Typography>
            <Typography
              fontSize={{ xs: 8, md: 11, xl: 12 }}
              sx={{
                wordWrap: "break-word", // Allow text to wrap to the next line
                whiteSpace: "normal", // Prevent text from sticking to one line
              }}>
              {params.row.vehicalType}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "bankDetails",
      headerName: "Bank Details",
      // width: 500,
      flex: 1.3,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Bank Details
        </div>
      ),
      renderCell: (params) => (
        <Box
          py={2}
          sx={{
            display: "grid",
            gridTemplateColumns: { md: "100px auto", xl: "120px auto" },
            gap: "10px",
          }}>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            Name :
          </Typography>
          <Typography
            fontSize={{ xs: 10, md: 10, xl: 12 }}
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}>
            {params.row.bankDetails.accountHolderName}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            Bank Name :
          </Typography>
          <Typography
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}
            fontSize={{ xs: 10, md: 10, xl: 12 }}>
            {params.row.bankDetails.bankName}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            Account Number :
          </Typography>
          <Typography
            fontSize={{ xs: 10, md: 10, xl: 12 }}
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}>
            {params.row.bankDetails.accountNumber}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={{ xs: 11, md: 11, xl: 13 }}>
            IFSC :
          </Typography>
          <Typography
            fontSize={{ xs: 10, md: 10, xl: 12 }}
            sx={{
              wordWrap: "break-word", // Allow text to wrap to the next line
              whiteSpace: "normal", // Prevent text from sticking to one line
            }}>
            {params.row.bankDetails.ifscCode}
          </Typography>
        </Box>
      ),
    },

    {
      field: "carDetails",
      headerName: "Car Details",
      // flex: 1,
      // width: 300,
      flex: 0.7,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Car Details
        </div>
      ),
      renderCell: (params) => (
        <Box>
          <img
            src={params.row.carDetails.carpic}
            alt="Profile"
            style={{
              width: "80px",
              height: "80px",
              marginRight: "16px",
              objectFit: "cover",
            }}
          />
          <Box>
            <Typography fontSize={{ xs: 8, md: 11, xl: 12 }}>
              {" "}
              {params.row.carDetails.model}
            </Typography>
            <Typography fontSize={{ xs: 8, md: 11, xl: 12 }}>
              {params.row.carDetails.registration}
            </Typography>
          </Box>
        </Box>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      // width: 140,
      flex: 0.5,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Status</div>
      ),
      renderCell: (params) => (
        <Box display={"flex"} flexDirection={"column"} gap={2} py={2}>
          <Box>
            {params.row.status === "approved" && (
              <Button
                sx={{
                  fontSize: { xs: 4, md: 8, xl: 13 },
                  backgroundColor: "#C6EBE5",
                  color: "#0DBA9F",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#C6EBE5",
                  },
                }}>
                Approved
              </Button>
            )}

            {params.row.status === "pending" && (
              <Button
                sx={{
                  fontSize: { xs: 4, md: 8, xl: 13 },
                  backgroundColor: "#D5C6F3",
                  color: "#4A00E0",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#D5C6F3",
                  },
                }}>
                Pending
              </Button>
            )}

            {params.row.status === "rejected" && (
              <Button
                sx={{
                  fontSize: { xs: 4, md: 8, xl: 13 },
                  backgroundColor: "#EECACA",
                  color: "#D66262",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#EECACA",
                  },
                }}>
                Rejected
              </Button>
            )}
          </Box>

          <Box>
            {params.row.isBlocked ? (
              <Button
                sx={{
                  fontSize: { xs: 4, md: 8, xl: 13 },
                  backgroundColor: "#EECACA",
                  color: "#D66262",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#EECACA",
                  },
                }}>
                Blocked
              </Button>
            ) : (
              <Button
                sx={{
                  fontSize: { xs: 4, md: 8, xl: 13 },
                  backgroundColor: "#C6EBE5",
                  color: "#0DBA9F",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "#C6EBE5",
                  },
                }}>
                Published
              </Button>
            )}
          </Box>
        </Box>
      ),
    },

    {
      field: "action",
      headerName: "Action",
      // width: 280,
      flex: 0.5,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Action</div>
      ),
      renderCell: (params) => (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
          justifyContent={"flex-start"}
          gap={2}>
          <Typography
            sx={{
              color: "black",
              fontWeight: "bold",

              fontSize: { xs: 10, md: 12, xl: 15 },
              cursor: "pointer",
            }}
            onClick={() => handleEditOpen(params.row._id)}>
            Edit
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontWeight: "bold",

              fontSize: { xs: 10, md: 12, xl: 15 },
              cursor: "pointer",
            }}
            onClick={() => handleBlockOpen(params.row)}>
            {params.row.isBlocked ? "Unblock" : "Block"}
          </Typography>{" "}
          <Typography
            sx={{
              color: "black",
              fontWeight: "bold",

              fontSize: { xs: 10, md: 12, xl: 15 },
              cursor: "pointer",
            }}
            onClick={() => handleViewOpen(params.row._id)}>
            View
          </Typography>
        </Box>
      ),
    },
  ];

  const handleOpenImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsImageModalOpen(true);
  };
  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  return (
    <Box>
      <DashboardHeaderCards cardData={cardData} />
      <Box mt={1}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FFC107",
              color: "black",
              // height: "48px",
              py: 2,
              px: 4,
              // minWidth: "160px",
              fontWeight: "bold",
              fontSize: { md: "0.8rem", xl: "0.9rem" },
            }}
            onClick={handleOpen} // Attach the handleOpen function here
          >
            Add New Car
          </Button>
        </Box>

        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Search by Name or Mobile"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                sx: {
                  fontSize: { md: "0.7rem", lg: "1rem" }, // Responsive font sizes for label
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              label="Select Service Type"
              variant="outlined"
              fullWidth
              select
              InputLabelProps={{
                sx: {
                  fontSize: { md: "0.7rem", lg: "1rem" }, // Responsive font sizes for label
                },
              }}>
              <MenuItem value="service1">ALL</MenuItem>
              <MenuItem value="service2">Astha Rides</MenuItem>
              <MenuItem value="service3">Self Rides</MenuItem>
              <MenuItem value="service4">Sharing Rides</MenuItem>
              <MenuItem value="service5">InterCity Service</MenuItem>
              <MenuItem value="service6">Out of Station</MenuItem>
              <MenuItem value="service7">Hill Station Rides</MenuItem>
            </TextField>
          </Grid>

          {/* Date Picker */}
          <Grid item xs={12} sm={3}>
            <TextField
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                sx: {
                  fontSize: { md: "0.7rem", lg: "1rem" }, // Responsive font sizes for label
                },
              }}
            />
          </Grid>

          {/* Export Button aligned with inputs */}
          <Grid item xs={12} sm={3} textAlign="right">
            <Button
              variant="contained"
              startIcon={
                <img
                  src={"/export.svg"}
                  alt="Export Icon"
                  style={{ width: "20px", height: "24px" }}
                />
              }
              sx={{
                backgroundColor: "#FFC107",
                color: "black",
                // height: "48px",
                py: 1.2,
                px: 4,
                // minWidth: "160px",
                fontWeight: "bold",
                fontSize: { md: "0.9rem", xl: "1rem" },
              }}
              onClick={() => handleDownloadDriver()}>
              Export
            </Button>
          </Grid>
        </Grid>
      </Box>

      <DataGrid
        rows={rows}
        columns={distributorColumns}
        getRowId={(row) => row._id}
        pageSize={pageSize}
        rowCount={totalPages * pageSize} // Set the total number of rows
        paginationMode="server" // Enable server-side pagination
        onPageChange={handlePageChange} // Handle page changes
        onPageSizeChange={handlePageSizeChange} // Handle page size changes
        page={page - 1} // DataGrid uses 0-indexed page, API uses 1-indexed
        autoHeight={"auto"} // Set auto height for the grid
        getRowHeight={() => "auto"}
        pagination
        sx={{
          width: "100%", // Ensure the grid takes full width of the container
          "& .MuiDataGrid-columnHeader": {
            fontWeight: "bold",
            fontSize: { xs: "0.6rem", md: "0.8rem" }, // Responsive font size
          },
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal", // Wrap text for better responsiveness
            padding: { xs: "8px", md: "16px" }, // Responsive padding
          },
        }}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        {/* <DialogTitle>Add New Car</DialogTitle> */}
        <DialogContent sx={{ backgroundColor: "transparent" }}>
          <DistributorForm
            handleClose={handleClose}
            handleRefernce={fetchDistributors}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isEditMode}
        onClose={handleEditClose}
        maxWidth="sm"
        fullWidth>
        <DialogContent sx={{ backgroundColor: "transparent" }}>
          <DistributorEditForm
            handleClose={handleEditClose}
            handleRefernce={fetchDistributors}
            driverId={selectedDriverId}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isViewMode}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth>
        {singleDetails ? (
          <DialogContent
            sx={{
              backgroundColor: "#f9f9f9", // Light background for better contrast
              borderRadius: "12px", // Soft rounded corners
              padding: "20px", // Added padding for breathing space
            }}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={handleViewClose} sx={{ color: "#f44336" }}>
                {" "}
                {/* Red for Close icon */}
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography
              textAlign="center"
              variant="h5"
              fontWeight="bold"
              pb={2}
              sx={{ color: "#333" }}>
              {" "}
              {/* Darker text color */}
              View Driver Details
            </Typography>
            <Grid container spacing={3}>
              {" "}
              {/* Spacing increased for better layout */}
              <Grid item xs={12} sm={6}>
                <Typography
                  fontSize={{ xs: 12, sm: 14, xl: 16 }}
                  color="#4e4e4e">
                  {" "}
                  {/* Consistent color */}
                  <strong>Driver Name:</strong> {singleDetails.driverName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontSize={{ xs: 12, sm: 14, xl: 16 }}
                  color="#4e4e4e">
                  <strong>Mobile:</strong> {singleDetails.mobile}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontSize={{ xs: 12, sm: 14, xl: 16 }}
                  color="#4e4e4e">
                  <strong>IFSC Code:</strong> {singleDetails.ifscCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontSize={{ xs: 12, sm: 14, xl: 16 }}
                  color="#4e4e4e">
                  <strong>Account Number:</strong> {singleDetails.accountNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontSize={{ xs: 12, sm: 14, xl: 16 }}
                  color="#4e4e4e">
                  <strong>
                    {singleDetails.vehicalType === "car"
                      ? "Car Model:"
                      : singleDetails.vehicalType === "auto"
                      ? "Auto Model:"
                      : "Bike Model:"}
                  </strong>{" "}
                  {singleDetails.carModelName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontSize={{ xs: 12, sm: 14, xl: 16 }}
                  color="#4e4e4e">
                  <strong>Registration Number:</strong>{" "}
                  {singleDetails.registrationNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontSize={{ xs: 12, sm: 14, xl: 16 }}
                  color="#4e4e4e">
                  <strong>Service Type:</strong>{" "}
                  {singleDetails.serviceType &&
                  singleDetails.serviceType.length > 0
                    ? typeof singleDetails.serviceType === "string"
                      ? JSON.parse(singleDetails.serviceType).join(", ")
                      : singleDetails.serviceType.join(", ")
                    : "N/A"}
                </Typography>
              </Grid>
              {/* Display URLs with images */}
              <Grid
                mt={4}
                container
                spacing={2}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {[
                  {
                    name: "driverProfilePicUrl",
                    label: "Driver Photo",
                    url: singleDetails.driverProfilePicUrl,
                  },
                  {
                    name: "carRcPicUrl",
                    label: "Car RC",
                    url: singleDetails.carRcPicUrl,
                  },
                  {
                    name: "insurancePicUrl",
                    label: "Insurance",
                    url: singleDetails.insurancePicUrl,
                  },
                  {
                    name: "pollutionPicUrl",
                    label: "Pollution",
                    url: singleDetails.pollutionPicUrl,
                  },
                  {
                    name: "fitnessPicUrl",
                    label: "Fitness",
                    url: singleDetails.fitnessPicUrl,
                    showForvehicalType: "car", // Only show this for car
                  },
                  {
                    name: "drivingLicencePicUrl",
                    label: "Driver License",
                    url: singleDetails.drivingLicencePicUrl,
                  },
                  {
                    name: "carFrontendPicUrl",
                    label: "Car Frontend",
                    url: singleDetails.carFrontendPicUrl,
                  },
                  {
                    name: "aadhaarFrontPicUrl",
                    label: "Aadhaar Front",
                    url: singleDetails.aadhaarFrontPicUrl,
                  },
                  {
                    name: "aadhaarBackPicUrl",
                    label: "Aadhaar Back",
                    url: singleDetails.aadhaarBackPicUrl,
                  },
                  {
                    name: "panCardPicUrl",
                    label: "Pan",
                    url: singleDetails.panCardPicUrl,
                  },
                  {
                    name: "checkbookPicUrl",
                    label: "Checkbook",
                    url: singleDetails.checkbookPicUrl,
                  },
                ].map((field) => {
                  const { vehicalType } = singleDetails; // Access vehicalType from singleDetails

                  // Function to replace the label "Car" with the current vehicalType dynamically
                  const updatedLabel = field.label.replace(
                    "Car",
                    vehicalType === "car"
                      ? "Car"
                      : vehicalType === "auto"
                      ? "Auto"
                      : vehicalType === "bike"
                      ? "Bike"
                      : "Vehicle"
                  );

                  return (
                    field.url &&
                    // Add condition for "Fitness" field if vehicalType is "car"
                    (!field.showForvehicalType ||
                      field.showForvehicalType === vehicalType) && (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={3}
                        key={field.name}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                          marginBottom: "20px",
                        }}>
                        <Box width={"100%"} height={120}>
                          <img
                            src={field.url}
                            alt={field.label}
                            style={{
                              width: "80%",
                              height: "100%",
                              borderRadius: "8px",
                              cursor: "pointer",
                              border: "1px solid #ccc",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              objectFit: "cover",
                            }}
                            onClick={() => handleOpenImageModal(field.url)}
                          />
                        </Box>
                        <Typography
                          fontSize={{ xs: 8, sm: 10, xl: 13 }}
                          color="#4e4e4e"
                          style={{ marginBottom: "8px" }}>
                          <strong>{updatedLabel}</strong>{" "}
                          {/* Use updated label here */}
                        </Typography>
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </Grid>

            <Button
              variant="contained"
              sx={{
                mt: 3,
                backgroundColor: "#FFC107",
                color: "#000",
                fontWeight: "bold",
                fontSize: { xs: 12, md: 14, xl: 16 },
                "&:hover": { backgroundColor: "#e0a800" },
              }}
              onClick={handleViewClose}>
              Close
            </Button>

            <ImageModal
              isOpen={isImageModalOpen}
              onClose={handleCloseImageModal}
              imageUrl={selectedImage}
            />
          </DialogContent>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Dialog>

      <Modal open={block} onClose={handleBlockClose}>
        {selectedBlcokDriver ? (
          <Box
            sx={{
              p: 3,
              width: { xs: "60%", lg: "30%" },
              margin: "auto",
              mt: "20vh",
              borderRadius: "md",
              backgroundColor: "#D9D9D9",
            }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <IconButton onClick={handleBlockClose} sx={{ color: "red" }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography
              fontWeight={"bold"}
              fontSize={18}
              textAlign={"center"}
              mb={3}>
              Block/Unblock Distributors
            </Typography>

            <Typography textAlign={"center"} mb={3}>
              {selectedBlcokDriver.isBlocked
                ? "Do you want to unblock this distributor?"
                : "Do you want to block this distributor?"}
            </Typography>

            <Typography textAlign={"center"} mb={6}>
              {selectedBlcokDriver.driverName}
            </Typography>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              mt={2}>
              <Button
                sx={{
                  backgroundColor: "#F4E6C4",
                  color: "black",
                  fontWeight: "bold",
                  p: 1.4,
                  fontSize: { xs: 10, md: 12, xl: 14 },
                }}
                onClick={handleBlockClose}>
                Cancel
              </Button>

              <Button
                sx={{
                  backgroundColor: "#FFBF2A",
                  color: "black",
                  fontWeight: "bold",
                  p: 1.4,
                  fontSize: { xs: 10, md: 12, xl: 14 },
                }}
                onClick={() => {
                  // Call handleBlockAPI with the current state of isBlocked
                  handleBlockAPI(
                    selectedBlcokDriver._id,
                    selectedBlcokDriver.isBlocked
                  );
                }}>
                {selectedBlcokDriver.isBlocked ? "Unblock" : "Block"}
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography>Loading ...</Typography>
        )}
      </Modal>
    </Box>
  );
};

export default DistributorCars;
