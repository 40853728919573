import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import DashboardHeaderCards from "./DashboardHeaderCards";
import { DataGrid } from "@mui/x-data-grid";
const cardData = [
  {
    title: "Total Amount",
    value: "78",
    color: "#1976d2",

    icon: "/total_amt.svg",
    route: "/distributer/statement",
  },
  {
    title: "Company Profit",
    value: 49,
    color: "#388e3c",
    //   icon: <DirectionsCarIcon sx={{ fontSize: 40, color: "#388e3c" }} />,
    icon: "/company_profit.svg",

    route: "/distributer/cars",
  },
  {
    title: "Distributor Profit",
    value: "29",
    color: "#d32f2f",
    icon: "/profile_transaction.svg",

    //   icon: <TrendingDownIcon sx={{ fontSize: 40, color: "#d32f2f" }} />,
    route: "/distributer/cars",
  },
];
const ManageTransaction = () => {
  const rows = [
    {
      userDetails: {
        id: "6678cas4...",
        email: "superadcxmn@arv.com",
        mobile: "+98xxxxxxxx",
        address: "",
        name: "Aastha",
        numberOfRides: "",
        profile: "https://via.placeholder.com/80",
      },
      status: {
        currentStatus: "On Going",
      },
    },
  ];
  const distributorColumns = [
    {
      field: "driverDetails",
      headerName: "driverDetails",
      //   flex: 5,
      width: 340,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Driver Details
        </div>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 8, // Add a little space between items
            backgroundColor: "transparent",
            mb: 4,
          }}>
          {/* Profile Image */}
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={10}
            alignItems={"center"}>
            <Box>
              <Typography fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }} mb={1}>
                DL9C AK 1234
              </Typography>
              <Typography
                fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}
                sx={{
                  wordWrap: "break-word", // Allow text to wrap to the next line
                  whiteSpace: "normal", // Prevent text from sticking to one line
                }}>
                Maruti Dezire LXI
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }} mb={1}>
                Chandan singh
              </Typography>
              <Typography
                fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}
                sx={{
                  wordWrap: "break-word", // Allow text to wrap to the next line
                  whiteSpace: "normal", // Prevent text from sticking to one line
                }}>
                Distributor: ABBC Distrubutors
              </Typography>
            </Box>
          </Box>
        </Box>
      ),
    },

    {
      field: "userDetails",
      headerName: "userDetails",
      width: 180,
      //   flex: 2,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          User Details
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ minWidth: 150, backgroundColor: "transparent" }}>
          <Typography
            sx={{ textAlign: "center" }}
            fontSize={{ xs: 10, sm: 11, md: 11, xl: 14 }}>
            Ram Singh
          </Typography>
        </Box>
      ),
    },

    {
      field: "location",
      headerName: "location",
      width: 180,
      //   flex: 3,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Location</div>
      ),
      renderCell: (params) => (
        <Box sx={{ minWidth: 150, backgroundColor: "transparent" }}>
          <Typography
            sx={{ textAlign: "center" }}
            fontSize={{ xs: 10, sm: 11, md: 11, xl: 14 }}>
            Tilak Nagar, new Delhi
          </Typography>
          <Typography
            sx={{ textAlign: "center" }}
            fontSize={{ xs: 10, sm: 11, md: 11, xl: 14 }}>
            Dhula Kauon, New Delhi
          </Typography>
        </Box>
      ),
    },

    {
      field: "distance",
      headerName: "distance",
      width: 120,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Distance</div>
      ),
      renderCell: (params) => (
        <Box sx={{ minWidth: 150, backgroundColor: "transparent" }}>
          <Typography fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
            12 km{" "}
          </Typography>
        </Box>
      ),
    },

    {
      field: "invoice",
      headerName: "invoice",
      width: 100,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Invoice amt
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ minWidth: 150, backgroundColor: "transparent" }}>
          <Typography fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
            ₹350/-
          </Typography>
        </Box>
      ),
    },

    {
      field: "company",
      headerName: "Company",
      width: 130,
      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Company % (20):
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ minWidth: 150, backgroundColor: "transparent" }}>
          <Typography fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
            ₹70/-
          </Typography>
        </Box>
      ),
    },

    {
      field: "distrubutor",
      headerName: "Distrubutor",
      width: 140,

      renderHeader: () => (
        <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
          Distrubutor % (15):
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ minWidth: 150, backgroundColor: "transparent" }}>
          <Typography fontSize={{ xs: 10, sm: 11, md: 11, xl: 13 }}>
            ₹10.5/-
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <DashboardHeaderCards cardData={cardData} />
      <Box py={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <TextField
              label="Search by Name or Mobile"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              label="Select Payment Type"
              variant="outlined"
              fullWidth
              select
              InputProps={{
                style: { height: "48px" },
              }}>
              <MenuItem value="service1">ALL</MenuItem>
              <MenuItem value="service2">Cash</MenuItem>
              <MenuItem value="service2">Online</MenuItem>
            </TextField>
          </Grid>

          {/* Date Picker */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="dd/mm/yyyy"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/* Export Button aligned with inputs */}
          <Grid item xs={12} sm={3} textAlign="right">
            <Button
              variant="contained"
              startIcon={
                <img
                  src={"/export.svg"}
                  alt="Export Icon"
                  style={{ width: "20px", height: "24px" }}
                />
              }
              sx={{
                backgroundColor: "#FFC107",
                color: "black",
                height: "48px",
                minWidth: "160px",
                fontWeight: "bold",
              }}>
              Export
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={distributorColumns}
          getRowId={(row) => row.userDetails.id}
          pageSize={1}
          autoHeight={false}
          rowHeight={200}
        />
      </Box>
    </Box>
  );
};

export default ManageTransaction;
