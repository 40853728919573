import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Make sure to import useNavigate from 'react-router-dom'

const DashBoardDistributorCards = ({ cardData }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      {cardData?.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
          <Card
            sx={{
              borderRadius: 2,
              // mb: 3,
              boxShadow: 3,
              textAlign: "center",
              cursor: "pointer",
              py: 3,
              px: 2,
              height: 200,
            }}
            onClick={() => navigate(card.route)}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box width={"40%"}>
                  <img src={card.icon} alt="" style={{ width: "50%" }} />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start">
                  <Typography
                    fontSize={{ xs: 12, md: 15, xl: 17 }}
                    textAlign={"left"}
                    sx={{ fontWeight: "600" }}>
                    {card.title}
                  </Typography>
                  <Typography
                    fontSize={{ xs: 12, md: 20, xl: 22 }}
                    sx={{ fontWeight: "bold" }}
                    color={"#000C8E"}>
                    {card.value}
                  </Typography>
                  <Typography
                    textAlign={"left"}
                    fontSize={{ xs: 8, md: 12, xl: 13 }}>
                    {card.tag}
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    sx={{ pt: 0.5, color: card.changeColor }}
                  >
                    {card.change}
                  </Typography> */}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default DashBoardDistributorCards;
           