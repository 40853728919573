// src/redux/actionTypes.js

export const ADD_DRIVER_REQUEST = 'ADD_DRIVER_REQUEST';
export const ADD_DRIVER_SUCCESS = 'ADD_DRIVER_SUCCESS';
export const ADD_DRIVER_FAILURE = 'ADD_DRIVER_FAILURE';

export const FETCH_DRIVERS_REQUEST = 'FETCH_DRIVERS_REQUEST';
export const FETCH_DRIVERS_SUCCESS = 'FETCH_DRIVERS_SUCCESS';
export const FETCH_DRIVERS_FAILURE = 'FETCH_DRIVERS_FAILURE';

export const DELETE_DRIVER_REQUEST = 'DELETE_DRIVER_REQUEST';
export const DELETE_DRIVER_SUCCESS = 'DELETE_DRIVER_SUCCESS';
export const DELETE_DRIVER_FAILURE = 'DELETE_DRIVER_FAILURE';

export const UPDATE_DRIVER_REQUEST = 'UPDATE_DRIVER_REQUEST';
export const UPDATE_DRIVER_SUCCESS = 'UPDATE_DRIVER_SUCCESS';
export const UPDATE_DRIVER_FAILURE = 'UPDATE_DRIVER_FAILURE';
