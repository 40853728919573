import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Toolbar,
  IconButton,
  Modal,
  TextField,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const Distributer = () => {
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    name: "",
    password: "",
  });

  useEffect(() => {
    axios
      .get("/api/distributors") // Replace with your API endpoint
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/distributors", formData);
      setData((prevData) => [...prevData, response.data]);
      handleClose();
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phone", headerName: "Phone", width: 150 },
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5">Total active users</Typography>
            <Typography variant="h3">18,765</Typography>
            <Typography variant="body2" color="green">
              +2.6% last 7 days
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5">Total installed</Typography>
            <Typography variant="h3">4,876</Typography>
            <Typography variant="body2" color="green">
              +0.2% last 7 days
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5">Total downloads</Typography>
            <Typography variant="h3">678</Typography>
            <Typography variant="body2" color="red">
              -0.1% last 7 days
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5">Billing Statement</Typography>
            <Typography variant="h3">56</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ height: 400, width: "100%", mt: 3 }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Distributor List
          </Typography>
          <IconButton color="primary" onClick={handleOpen}>
            <AddIcon />
          </IconButton>
        </Toolbar>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          checkboxSelection
        />
      </Box>

      <Modal open={modalOpen} onClose={handleClose}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Register Distributor
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Register
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Distributer;

// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Grid,
//   Paper,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@mui/material";
// import { ResponsiveContainer } from "recharts";
// import axios from "axios";

// const pieData = [
//   { name: "New cars", value: 680 },
//   { name: "Used cars", value: 800 },
// ];

// const lineData = [
//   { name: "Jan", user: 30, pageview: 20 },
//   { name: "Feb", user: 20, pageview: 10 },
//   { name: "Mar", user: 27, pageview: 25 },
//   { name: "Apr", user: 18, pageview: 15 },
//   { name: "May", user: 23, pageview: 20 },
//   { name: "Jun", user: 34, pageview: 30 },
//   { name: "Jul", user: 43, pageview: 35 },
// ];

// const barData = [
//   { name: "Jan", Sale: 4000, Amount: 2400 },
//   { name: "Feb", Sale: 3000, Amount: 1398 },
//   { name: "Mar", Sale: 2000, Amount: 9800 },
//   { name: "Apr", Sale: 2780, Amount: 3908 },
//   { name: "May", Sale: 1890, Amount: 4800 },
//   { name: "Jun", Sale: 2390, Amount: 3800 },
//   { name: "Jul", Sale: 3490, Amount: 4300 },
// ];

// const COLORS = ["#0088FE", "#FF8042"];

// const Distributer = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     axios
//       .get("/api/distributors") // Replace with your API endpoint
//       .then((response) => {
//         setData(response.data);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the data!", error);
//       });
//   }, []);

//   return (
//     <Box sx={{ flexGrow: 1, p: 3 }}>
//       <Grid container spacing={3}>
//         <Grid item xs={12} sm={6} md={3}>
//           <Paper elevation={3} sx={{ padding: 2 }}>
//             <Typography variant="h5">Total active users</Typography>
//             <Typography variant="h3">18,765</Typography>
//             <Typography variant="body2" color="green">
//               +2.6% last 7 days
//             </Typography>
//           </Paper>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Paper elevation={3} sx={{ padding: 2 }}>
//             <Typography variant="h5">Total installed</Typography>
//             <Typography variant="h3">4,876</Typography>
//             <Typography variant="body2" color="green">
//               +0.2% last 7 days
//             </Typography>
//           </Paper>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Paper elevation={3} sx={{ padding: 2 }}>
//             <Typography variant="h5">Total downloads</Typography>
//             <Typography variant="h3">678</Typography>
//             <Typography variant="body2" color="red">
//               -0.1% last 7 days
//             </Typography>
//           </Paper>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Paper elevation={3} sx={{ padding: 2 }}>
//             <Typography variant="h5">Billing Statement</Typography>
//             <Typography variant="h3">56</Typography>
//           </Paper>
//         </Grid>
//       </Grid>

//       {/*   */}
//     </Box>
//   );
// };

// export default Distributer;
