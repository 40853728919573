import React, { useState } from "react";
import { Grid, Typography, TextField, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Notification from "./NotificationAPI";
const RegisterDistributor = () => {
  // State for form data
  const [formData, setFormData] = useState({
    email: "", // compulsory
    password: "", // compulsory
    name: "", // optional
    phone: "", // compulsory
  });

  const [messageAPI, setMessageAPI] = useState(false); // State to control Snackbar visibility
  const [message, setMessage] = useState(""); // State for the message to show
  const [severity, setSeverity] = useState("success"); // State for Snackbar severity

  const navigate = useNavigate();
  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://api.janatarides.com/api/distributors",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json(); // Get the API response in JSON format

      if (response.ok) {
        setMessage(result.message || "Distributor registered successfully!");
        setSeverity("success"); // Show success message
        setMessageAPI(true); // Show notification
        setTimeout(() => {
          navigate("/distributor/login");
        }, 1500);
      } else {
        setMessage(result.message || "Failed to register distributor");
        setSeverity("error"); // Show error message
        setMessageAPI(true); // Show notification
      }
    } catch (error) {
      setMessage("Error occurred. Please try again.");
      setSeverity("error");
      setMessageAPI(true);
    }
  };

  const handleMessageAPI = () => {
    setMessageAPI(false); // Close the Snackbar
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "#f4f5f7",
        flexDirection: { xs: "column", lg: "row" },
      }}>
      {/* Left Section - Illustration */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <img
          src="/distributor_login.svg"
          alt="3D Character"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Grid>

      {/* Right Section - Form */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          padding: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}>
        <Box
          component="form"
          sx={{ maxWidth: "400px", width: "100%", margin: "0 auto" }}
          onSubmit={handleSubmit}>
          <Typography fontWeight={"bold"} fontSize={{ xs: 16, md: 28, xl: 30 }}>
            Distributor Registration
          </Typography>

          {/* Form Fields */}
          <TextField
            fullWidth
            label="Email ID" // compulsory
            name="email"
            variant="outlined"
            type="email"
            margin="normal"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            label="Password" // compulsory
            name="password"
            variant="outlined"
            type="password"
            margin="normal"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            label="Name" // optional
            name="name"
            variant="outlined"
            margin="normal"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Mobile Number" // compulsory
            name="phone"
            variant="outlined"
            type="tel"
            margin="normal"
            value={formData.phone}
            onChange={(e) => {
              // Only allow numbers
              const phone = e.target.value.replace(/\D/g, "");
              // Allow updating phone only if it has up to 10 digits
              if (phone.length <= 10) {
                setFormData({ ...formData, phone });
              }
            }}
            inputProps={{ maxLength: 10 }} // Limit the input to 10 digits
            required
            error={formData.phone.length > 0 && formData.phone.length < 10} // Show error if less than 10 digits
            helperText={
              formData.phone.length > 0 && formData.phone.length < 10
                ? "Phone number must be exactly 10 digits"
                : ""
            }
          />

          {/* Register Button */}
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              backgroundColor: "#FFC107",
              color: "black",
              marginTop: "20px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#FFA000",
              },
            }}>
            Register Now
          </Button>
        </Box>
      </Grid>

      <Notification
        messageAPI={messageAPI}
        message={message}
        severity={severity}
        handleMessageAPI={handleMessageAPI}
      />
    </Grid>
  );
};

export default RegisterDistributor;
