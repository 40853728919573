import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Checkbox,
  IconButton,
  Paper,
  Divider,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { addDriver, updateDriver } from "../../redux/driver/action";
import Webcam from "react-webcam";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Notification from "../NotificationAPI";

// Service types
const serviceTypes = ["InterCity Service"];
const vehicleTypes = ["car", "auto", "bike"];

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
}));
const DistributorForm = ({
  isEditMode = false,
  initialData = {},
  handleClose,
  handleRefernce,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [uploadedFiles, setUploadedFiles] = useState({});
  const [openCamera, setOpenCamera] = useState(false);
  const [currentField, setCurrentField] = useState("");
  const webcamRef = useRef(null);
  const [message, setMessage] = useState("");
  const [messageAPI, setMessageAPI] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: "user",
  };

  useEffect(() => {
    if (isEditMode && initialData) {
      Object.keys(initialData).forEach((key) => {
        setValue(key, initialData[key]);
      });
    }
  }, [isEditMode, initialData, setValue]);

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFiles((prevState) => ({
        ...prevState,
        [fieldName]: file,
      }));
    }
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const file = dataURLtoFile(imageSrc, `${currentField}.jpg`);
    setUploadedFiles((prevState) => ({
      ...prevState,
      [currentField]: file,
    }));
    setOpenCamera(false);
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleMessageAPI = () => {
    setMessageAPI(false); // Close the Snackbar
  };
  //  const onSubmit = async (data) => {
  //   const formData = new FormData();

  //   // Append form data
  //   Object.keys(data).forEach((key) => formData.append(key, data[key]));

  //   // Append uploaded files
  //   Object.keys(uploadedFiles).forEach((field) => {
  //    formData.append(field, uploadedFiles[field]);
  //   });

  //   try {
  //    const response = await fetch("http://localhost:5000/api/drivers/add", {
  //     method: "POST",
  //     body: formData,
  //     headers: {
  //      "x-auth-token": localStorage.getItem("token"), // Include the JWT token in the request headers
  //     },
  //    });

  //    const result = await response.json();
  //    if (response.ok) {
  //     setMessage(result.message);
  //     setSeverity("success");
  //    } else {
  //     setMessage(result.message || "Something went wrong");
  //     setSeverity("error");
  //    }
  //    setMessageAPI(true);
  //   } catch (error) {
  //    setMessage(error.message || "Something went wrong");
  //    setSeverity("error");
  //    setMessageAPI(true);
  //   }
  //  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append driver data
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    // Append uploaded files
    for (const [fieldName, file] of Object.entries(uploadedFiles)) {
      formData.append(fieldName, file);
    }

    try {
      console.log("formData:", formData);
      const response = await dispatch(addDriver(formData));
      console.log("check", response);
      if (response && response.message) {
        // Show success message
        console.log("Response message:", response.message);
        setMessage(response.message);
        setSeverity("success");
      }
    } catch (error) {
      // Show error message
      console.log("Response message:", error.message);
      setMessage(error.message || "Something went wrong");
      setSeverity("error");
    }

    setMessageAPI(true);
    await handleRefernce();
    setTimeout(() => {
      handleClose();
    }, 1500);
  };
  const serviceTypeValues = watch("serviceType") || [];
  //  const handleVehicleTypeChange = (vehicleType) => {
  //     // Toggle vehicle types based on user selection
  //     setSelectedVehicleTypes((prevTypes) =>
  //       prevTypes.includes(vehicleType)
  //         ? prevTypes.filter((type) => type !== vehicleType)
  //         : [...prevTypes, vehicleType]
  //     );
  //   };
  const handleVehicleTypeChange = (vehicleType) => {
    setSelectedVehicleType(vehicleType); // Set the selected vehicle type
  };
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        sx={{
          width: "100%",
          maxWidth: 900,
          maxHeight: "90vh",
          overflowY: "auto",
          borderRadius: 2,
          p: 4,
        }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <IconButton onClick={handleClose} sx={{ color: "red" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }}>
            {isEditMode ? "Update Driver" : "Add New Driver"}
          </Typography>
          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Vehicle Type
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <FormGroup>
              {vehicleTypes.map((vehicleType) => (
                <FormControlLabel
                  key={vehicleType}
                  control={
                    <StyledCheckbox
                      {...register("vehicalType")}
                      value={vehicleType}
                      checked={selectedVehicleType === vehicleType} // Only the selected type will be checked
                      onChange={() => handleVehicleTypeChange(vehicleType)} // Handle single selection
                    />
                  }
                  label={vehicleType}
                />
              ))}
            </FormGroup>
          </Paper>
          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Driver Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Driver Name"
                  {...register("driverName")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Mobile"
                  {...register("mobile", {
                    required: "Mobile number is required", // Custom error message
                    pattern: {
                      value: /^[0-9]{10}$/, // Ensures exactly 10 digits are allowed
                      message: "Mobile number must be 10 digits",
                    },
                    minLength: {
                      value: 10,
                      message: "Mobile number must be at least 10 digits",
                    },
                    maxLength: {
                      value: 10,
                      message: "Mobile number cannot exceed 10 digits",
                    },
                  })}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    maxLength: 10, // Limit to 10 digits
                    inputMode: "numeric", // Brings up number keyboard on mobile devices
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Only allow numbers
                  }}
                  error={!!errors.mobile} // Error state based on validation
                  helperText={errors.mobile ? errors.mobile.message : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Address"
                  {...register("address")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  {...register("email")}
                  required
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Password"
                  {...register("password")}
                  required
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Account Holder Name"
                  {...register("accountHolderName")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Bank Name"
                  {...register("bankName")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="IFSC Code"
                  {...register("ifscCode")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Account Number"
                  {...register("accountNumber", {
                    minLength: {
                      value: 10,
                      message: "Account number must be at least 10 digits",
                    },
                    maxLength: {
                      value: 16,
                      message: "Account number cannot exceed 10 digits",
                    },
                  })}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    maxLength: 16, // Limit to 10 digits
                    inputMode: "numeric", // Brings up number keyboard on mobile devices
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Only allow numbers
                  }}
                  error={!!errors.accountNumber} // Error state based on validation
                  helperText={
                    errors.accountNumber ? errors.accountNumber.message : ""
                  } // Display error message if any
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            {selectedVehicleType === "car" && (
              <Typography variant="h6" gutterBottom>
                Car Information
              </Typography>
            )}
            {selectedVehicleType === "bike" && (
              <Typography variant="h6" gutterBottom>
                Bike Information
              </Typography>
            )}
            {selectedVehicleType === "auto" && (
              <Typography variant="h6" gutterBottom>
                Auto Riksha Information
              </Typography>
            )}
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              {selectedVehicleType === "bike" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Bike Name"
                    {...register("carModelName")}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              )}
              {selectedVehicleType === "bike" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Registration Number"
                    {...register("registrationNumber")}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              )}
              {selectedVehicleType === "car" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Car Model Name"
                    {...register("carModelName")}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              )}
              {selectedVehicleType === "car" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Registration Number"
                    {...register("registrationNumber")}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              )}
              {selectedVehicleType === "auto" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Auto Name"
                    {...register("carModelName")}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              )}
              {selectedVehicleType === "auto" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Registration Number"
                    {...register("registrationNumber")}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              )}
            </Grid>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Document Uploads
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              {[
                { name: "driverProfilePicUrl", label: "Driver Profile" },
                // Conditionally replace RC and Frontend Picture fields based on vehicle type
                selectedVehicleType === "bike"
                  ? { name: "carRcPicUrl", label: "Bike RC" }
                  : selectedVehicleType === "auto"
                  ? { name: "carRcPicUrl", label: "Auto RC" }
                  : { name: "carRcPicUrl", label: "car RC" }, // Default for Car
                selectedVehicleType === "bike"
                  ? { name: "carFrontendPicUrl", label: "Bike Front Picture" }
                  : selectedVehicleType === "auto"
                  ? { name: "carFrontendPicUrl", label: "auto Front Picture" }
                  : { name: "carFrontendPicUrl", label: "Car Frontend" }, // Default for Car
                { name: "checkbookPicUrl", label: "Check Book Pic" },
                { name: "insurancePicUrl", label: "Insurance" },
                { name: "pollutionPicUrl", label: "Pollution" },
                { name: "fitnessPicUrl", label: "Fitness (Optional)" }, // To be excluded for Bike and Auto
                { name: "drivingLicencePicUrl", label: "Driving licence" },
                { name: "aadhaarFrontPicUrl", label: "Aadhaar Front" },
                { name: "aadhaarBackPicUrl", label: "Aadhaar Back" },
                { name: "panCardPicUrl", label: "PAN Card" },
              ]
                // Exclude "fitnessPicUrl" for Bike and Auto
                .filter((field) => {
                  if (
                    selectedVehicleType === "bike" ||
                    selectedVehicleType === "auto"
                  ) {
                    return field.name !== "fitnessPicUrl"; // Exclude Fitness for Bike and Auto
                  }
                  return true; // Return all fields for other vehicle types
                })
                .map((field) => (
                  <Grid item xs={12} sm={6} key={field.name}>
                    <Typography variant="subtitle1" gutterBottom>
                      {field.label}
                    </Typography>
                    <TextField
                      type="text"
                      fullWidth
                      value={uploadedFiles[field.name]?.name || ""}
                      variant="outlined"
                      size="small"
                      disabled
                    />
                    <input
                      type="file"
                      id={`file-input-${field.name}`}
                      accept="image/*;capture=camera"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, field.name)}
                    />
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={() =>
                        document
                          .getElementById(`file-input-${field.name}`)
                          .click()
                      }>
                      <Typography
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <UploadFileIcon fontSize="large" />
                        Upload
                      </Typography>
                    </IconButton>
                    <IconButton
                      color="secondary"
                      component="span"
                      onClick={() => {
                        setCurrentField(field.name);
                        setOpenCamera(true);
                      }}>
                      <Typography
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <CameraAltIcon fontSize="large" />
                        Camera
                      </Typography>
                    </IconButton>
                  </Grid>
                ))}
            </Grid>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Services Offered
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <FormGroup>
              {serviceTypes.map((serviceType) => (
                <FormControlLabel
                  key={serviceType}
                  control={
                    <StyledCheckbox
                      {...register("serviceType")}
                      value={serviceType}
                      checked={serviceTypeValues.includes(serviceType)}
                      onChange={({ target: { checked } }) => {
                        if (checked) {
                          setValue("serviceType", [
                            ...serviceTypeValues,
                            serviceType,
                          ]);
                        } else {
                          setValue(
                            "serviceType",
                            serviceTypeValues.filter(
                              (type) => type !== serviceType
                            )
                          );
                        }
                      }}
                    />
                  }
                  label={serviceType}
                />
              ))}
            </FormGroup>
          </Paper>

          <Box display={"flex"} alignItems={"center"} gap={5}>
            <Button
              fullWidth
              sx={{
                backgroundColor: "#F4E6C4",
                color: "black",
                height: "48px",
                minWidth: "160px",
                fontWeight: "bold",
              }}
              onClick={handleClose}>
              Cancel
            </Button>
            <Button
              fullWidth
              type="submit"
              sx={{
                backgroundColor: "#FFC107",
                color: "black",
                height: "48px",
                minWidth: "160px",
                fontWeight: "bold",
              }}>
              Submit
            </Button>
          </Box>
        </form>

        <Dialog open={openCamera} onClose={() => setOpenCamera(false)}>
          <DialogTitle>Capture Image</DialogTitle>
          <DialogContent>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCamera(false)}>Cancel</Button>
            <Button onClick={handleCapture}>Capture</Button>
          </DialogActions>
        </Dialog>

        <Notification
          messageAPI={messageAPI}
          message={message}
          severity={severity}
          handleMessageAPI={handleMessageAPI}
        />
      </Box>
    </Box>
  );
};

export default DistributorForm;
