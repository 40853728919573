import React, { useEffect } from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    CardActionArea,
    Box,
    Avatar,
    useMediaQuery,
    useTheme,
    styled,
    Paper,
} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchVisitorsOnline,
    fetchWeeklyOrders,
    fetchWeeklySales,
} from "../../redux/sales/action";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import DashboardHeaderCards from "../DashboardHeaderCards";
import DashBoardCards from "../DashBoardCards";

// Register the necessary components from Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const barData = {
    labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG"],
    datasets: [
        {
            label: "CHN",
            backgroundColor: "rgba(75,192,192,1)",
            borderWidth: 1,
            data: [65, 59, 80, 81, 56, 55, 40, 70],
        },
        {
            label: "USA",
            backgroundColor: "rgba(153,102,255,1)",
            borderWidth: 1,
            data: [45, 39, 60, 91, 36, 55, 30, 50],
        },
        {
            label: "UK",
            backgroundColor: "rgba(255,159,64,1)",
            borderWidth: 1,
            data: [35, 29, 50, 71, 46, 35, 20, 40],
        },
    ],
};

const doughnutData = {
    labels: ["Search Engine", "Direct Click", "Bookmark Click"],
    datasets: [
        {
            data: [60, 25, 15],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
    ],
};

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    textAlign: "center",
    maxWidth: "100%",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
    },
}));

const AdminDashboard = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();

    const { weeklySales, weeklyOrders, visitorsOnline } = useSelector(
        (state) => state.sales
    );

    useEffect(() => {
        dispatch(fetchWeeklySales());
        dispatch(fetchWeeklyOrders());
        dispatch(fetchVisitorsOnline());
    }, [dispatch]);

    const handleCardClick = (reportType) => {
        navigate(`/admin/reports?type=${reportType}`);
    };

    const statsData = [
        {
            title: "Weekly Sales",
            value: weeklySales ? `$${weeklySales.weeklyEarnings}` : "Loading...",
            icon: <TrendingUpIcon fontSize="large" />,
            color: "#4caf50",
            reportType: "weekly-sales",
            description: "Total earnings by car this month",
        },
        {
            title: "Weekly Orders",
            value: weeklyOrders ? weeklyOrders.completedRides : "Loading...",
            icon: <AssignmentIcon fontSize="large" />,
            color: "#2196f3",
            reportType: "weekly-orders",
            description: "Rides completed and canceled this week",
        },
        {
            title: "Online Cars",
            value: visitorsOnline ? visitorsOnline.activeCars : "Loading...",
            icon: <PeopleIcon fontSize="large" />,
            color: "#ff9800",
            reportType: "visitors-online",
            description: "Number of cars currently online",
        },
    ];



    const cardData = [
        {
            title: "Total Distributors",
            value: "₹ 0",
            change: "",
            icon: "/weekly_sales.svg",
           
        },
        {
            title: "Distributors Approved",
            value: "0",
            change: "",
            icon: "/weekly_order.svg",
          
        },
        {
            title: "Distributors Pending",
            value: "0",
            icon: "/online_car.svg",
           
            
        },


        {
            title: "Total Drivers/cars ",
            value: "₹ 0",
            change: "",
            icon: "/total_distributors.svg",

          
        },
        {
            title: "Drivers/cars Approved",
            value: "0",
            change: "",
            icon: "/distributors_approve.svg",

            
        },
        {
            title: "Drivers/cars Pending",
            value: "0",
            icon: "/distribytor_reject.svg",

            

        },


        {
            title: "Total Sale ",
            value: "₹ 0",
            change: "",
            icon: "/weekly_sales.svg",
            
        },
        {
            title: "Total Profit",
            value: "0",
            change: "",
            icon: "/weekly_order.svg",
            
        },
        {
            title: "Distributor %",
            value: "0",
            icon: "/online_car.svg",
            

        },



        {
            title: "Total Withdrawal",
            value: "₹ 0",
            change: "",
            icon: "/weekly_sales.svg",
            
        },
        {
            title: "Withdrawal Pending",
            value: "0",
            change: "",
            icon: "/weekly_order.svg",
            
        },
        {
            title: "Driver %",
            value: "0",
            icon: "/online_car.svg",
            

        },
    ];
    return (
        <Box p={isSmDown ? 2 : 4}>
            <Typography variant="h4" gutterBottom>
                Admin Dashboard
            </Typography>

            <Box pb={4}>
                <DashBoardCards cardData={cardData} />

            </Box>


            {/* <Grid container spacing={isSmDown ? 2 : 4}>
                <Grid item xs={12} md={8}>
                    <Item sx={{ height: "100%", maxWidth: "100%", width: "100%" }}>
                        <Typography variant="h6">Visit And Sales Statistics</Typography>
                        <Box sx={{ height: 250, width: "100%", overflowX: "auto" }}>
                            <Bar
                                data={barData}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                }}
                            />
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Item sx={{ height: "100%", maxWidth: "100%", width: "100%" }}>
                        <Typography variant="h6">Traffic Sources</Typography>
                        <Box sx={{ height: 250, width: "100%", overflowX: "auto" }}>
                            <Doughnut
                                data={doughnutData}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                }}
                            />
                        </Box>
                    </Item>
                </Grid>
            </Grid> */}
        </Box>
    );
};

export default AdminDashboard;

// import React, { useEffect } from "react";
// import {
//  Grid,
//  Card,
//  CardContent,
//  Typography,
//  CardActionArea,
//  Box,
//  Avatar,
//  useMediaQuery,
//  useTheme,
//  styled,
//  Paper,
// } from "@mui/material";
// import TrendingUpIcon from "@mui/icons-material/TrendingUp";
// import AssignmentIcon from "@mui/icons-material/Assignment";
// import PeopleIcon from "@mui/icons-material/People";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import {
//  fetchVisitorsOnline,
//  fetchWeeklyOrders,
//  fetchWeeklySales,
// } from "../../redux/sales/action";
// import { Bar, Doughnut } from "react-chartjs-2";
// // import { fetchWeeklySales, fetchWeeklyOrders, fetchVisitorsOnline } from '../redux/actions/reportActions';

// const barData = {
//  labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG"],
//  datasets: [
//   {
//    label: "CHN",
//    backgroundColor: "rgba(75,192,192,1)",
//    borderWidth: 1,
//    data: [65, 59, 80, 81, 56, 55, 40, 70],
//   },
//   {
//    label: "USA",
//    backgroundColor: "rgba(153,102,255,1)",
//    borderWidth: 1,
//    data: [45, 39, 60, 91, 36, 55, 30, 50],
//   },
//   {
//    label: "UK",
//    backgroundColor: "rgba(255,159,64,1)",
//    borderWidth: 1,
//    data: [35, 29, 50, 71, 46, 35, 20, 40],
//   },
//  ],
// };
// const Item = styled(Paper)(({ theme }) => ({
//  padding: theme.spacing(2),
//  display: "flex",
//  flexDirection: "column",
//  alignItems: "center",
//  justifyContent: "center",
//  backgroundColor: theme.palette.background.paper,
//  borderRadius: 10,
//  textAlign: "center",
//  maxWidth: "100%",
//  width: "100%",
//  height: "100%", // Ensure full height utilization
//  boxSizing: "border-box",
//  [theme.breakpoints.down("sm")]: {
//   padding: theme.spacing(1),
//  },
// }));
// const AdminDashboard = () => {
//  const doughnutData = {
//   labels: ["Search Engine", "Direct Click", "Bookmark Click"],
//   datasets: [
//    {
//     data: [60, 25, 15],
//     backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//    },
//   ],
//  };
//  const navigate = useNavigate();
//  const theme = useTheme();
//  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
//  const dispatch = useDispatch();

//  const { weeklySales, weeklyOrders, visitorsOnline } = useSelector(
//   (state) => state.sales
//  );

//  useEffect(() => {
//   dispatch(fetchWeeklySales());
//   dispatch(fetchWeeklyOrders());
//   dispatch(fetchVisitorsOnline());
//  }, [dispatch]);

//  const handleCardClick = (reportType) => {
//   navigate(`/admin/reports?type=${reportType}`);
//  };

//  const statsData = [
//   {
//    title: "Weekly Sales",
//    value: weeklySales ? `$${weeklySales.weeklyEarnings}` : "Loading...",
//    icon: <TrendingUpIcon fontSize="large" />,
//    color: "#4caf50",
//    reportType: "weekly-sales",
//    description: "Total earnings by car this month",
//   },
//   {
//    title: "Weekly Orders",
//    value: weeklyOrders ? weeklyOrders.completedRides : "Loading...",
//    icon: <AssignmentIcon fontSize="large" />,
//    color: "#2196f3",
//    reportType: "weekly-orders",
//    description: "Rides completed and canceled this week",
//   },
//   {
//    title: "Online Cars",
//    value: visitorsOnline ? visitorsOnline.activeCars : "Loading...",
//    icon: <PeopleIcon fontSize="large" />,
//    color: "#ff9800",
//    reportType: "visitors-online",
//    description: "Number of cars currently online",
//   },
//  ];

//  return (
//   <Box p={isSmDown ? 2 : 4}>
//    <Typography variant="h4" gutterBottom>
//     Admin Dashboard
//    </Typography>

//    <Grid container spacing={isSmDown ? 2 : 4}>
//     {statsData?.map((stat) => (
//      <Grid item xs={12} sm={6} md={4} key={stat.title}>
//       <Card>
//        <CardActionArea onClick={() => handleCardClick(stat.reportType)}>
//         <CardContent>
//          <Box display="flex" alignItems="center">
//           <Avatar
//            sx={{
//             bgcolor: stat.color,
//             width: 56,
//             height: 56,
//             mr: 2,
//            }}
//           >
//            {stat.icon}
//           </Avatar>
//           <Box>
//            <Typography variant="h6">{stat.title}</Typography>
//            <Typography variant="h4" color="textPrimary">
//             {stat.value}
//            </Typography>
//            <Typography variant="body2" color="textSecondary">
//             {stat.description}
//            </Typography>
//           </Box>
//          </Box>
//         </CardContent>
//        </CardActionArea>
//       </Card>
//       <Grid item xs={12} md={8}>
//        <Item sx={{ height: "100%", maxWidth: "100%", width: "100%" }}>
//         <Typography variant="h6">Visit And Sales Statistics</Typography>
//         <Box sx={{ height: 250, width: "100%", overflowX: "auto" }}>
//          <Bar
//           data={barData}
//           options={{
//            maintainAspectRatio: false,
//            responsive: true,
//           }}
//          />
//         </Box>
//        </Item>
//       </Grid>
//       <Grid item xs={12} md={4}>
//        <Item sx={{ height: "100%", maxWidth: "100%", width: "100%" }}>
//         <Typography variant="h6">Traffic Sources</Typography>
//         <Box sx={{ height: 250, width: "100%", overflowX: "auto" }}>
//          <Doughnut
//           data={doughnutData}
//           options={{
//            maintainAspectRatio: false,
//            responsive: true,
//           }}
//          />
//         </Box>
//        </Item>
//       </Grid>
//      </Grid>
//     ))}
//    </Grid>
//   </Box>
//  );
// };

// export default AdminDashboard;

// import React from "react";
// import { Box, Grid, Paper, Typography } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import { Bar, Doughnut } from "react-chartjs-2";
// import {
//  Chart as ChartJS,
//  CategoryScale,
//  LinearScale,
//  BarElement,
//  Title,
//  Tooltip,
//  Legend,
//  ArcElement,
// } from "chart.js";

// // Register the necessary components
// ChartJS.register(
//  CategoryScale,
//  LinearScale,
//  BarElement,
//  Title,
//  Tooltip,
//  Legend,
//  ArcElement
// );

// const Item = styled(Paper)(({ theme }) => ({
//  padding: theme.spacing(2),
//  display: "flex",
//  flexDirection: "column",
//  alignItems: "center",
//  justifyContent: "center",
//  backgroundColor: theme.palette.background.paper,
//  borderRadius: 10,
//  textAlign: "center",
//  maxWidth: "100%",
//  width: "100%",
//  height: "100%", // Ensure full height utilization
//  boxSizing: "border-box",
//  [theme.breakpoints.down("sm")]: {
//   padding: theme.spacing(1),
//  },
// }));

// const AdminDashboard = () => {
//  const barData = {
//   labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG"],
//   datasets: [
//    {
//     label: "CHN",
//     backgroundColor: "rgba(75,192,192,1)",
//     borderWidth: 1,
//     data: [65, 59, 80, 81, 56, 55, 40, 70],
//    },
//    {
//     label: "USA",
//     backgroundColor: "rgba(153,102,255,1)",
//     borderWidth: 1,
//     data: [45, 39, 60, 91, 36, 55, 30, 50],
//    },
//    {
//     label: "UK",
//     backgroundColor: "rgba(255,159,64,1)",
//     borderWidth: 1,
//     data: [35, 29, 50, 71, 46, 35, 20, 40],
//    },
//   ],
//  };

//  const doughnutData = {
//   labels: ["Search Engine", "Direct Click", "Bookmark Click"],
//   datasets: [
//    {
//     data: [60, 25, 15],
//     backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//    },
//   ],
//  };

//  return (
//   <Box sx={{ p: 3, bgcolor: "#F4F7FA" }}>
//    <Grid container spacing={3}>
//     <Grid item xs={12} sm={6} md={4}>
//      <Item>
//       <Typography variant="h6" sx={{ color: "#FF6B81" }}>
//        Weekly Sales Report
//       </Typography>
//       <Typography variant="h4" sx={{ color: "#FF6B81" }}>
//        ₹ 15,0000
//       </Typography>
//       <Typography variant="subtitle2" sx={{ color: "#FF6B81" }}>
//        Increased by 60%
//       </Typography>
//      </Item>
//     </Grid>
//     <Grid item xs={12} sm={6} md={4}>
//      <Item>
//       <Typography variant="h6" sx={{ color: "#4A90E2" }}>
//        Weekly Orders
//       </Typography>
//       <Typography variant="h4" sx={{ color: "#4A90E2" }}>
//        45,6334
//       </Typography>
//       <Typography variant="subtitle2" sx={{ color: "#4A90E2" }}>
//        Decreased by 10%
//       </Typography>
//      </Item>
//     </Grid>
//     <Grid item xs={12} sm={6} md={4}>
//      <Item>
//       <Typography variant="h6" sx={{ color: "#50E3C2" }}>
//        Visitors Online
//       </Typography>
//       <Typography variant="h4" sx={{ color: "#50E3C2" }}>
//        95,5741
//       </Typography>
//       <Typography variant="subtitle2" sx={{ color: "#50E3C2" }}>
//        Increased by 5%
//       </Typography>
//      </Item>
//     </Grid>
//     <Grid item xs={12} md={8}>
//      <Item sx={{ height: "100%", maxWidth: "100%", width: "100%" }}>
//       <Typography variant="h6">Visit And Sales Statistics</Typography>
//       <Box sx={{ height: 250, width: "100%", overflowX: "auto" }}>
//        <Bar
//         data={barData}
//         options={{
//          maintainAspectRatio: false,
//          responsive: true,
//         }}
//        />
//       </Box>
//      </Item>
//     </Grid>
//     <Grid item xs={12} md={4}>
//      <Item sx={{ height: "100%", maxWidth: "100%", width: "100%" }}>
//       <Typography variant="h6">Traffic Sources</Typography>
//       <Box sx={{ height: 250, width: "100%", overflowX: "auto" }}>
//        <Doughnut
//         data={doughnutData}
//         options={{
//          maintainAspectRatio: false,
//          responsive: true,
//         }}
//        />
//       </Box>
//      </Item>
//     </Grid>
//    </Grid>
//   </Box>
//  );
// };

// export default AdminDashboard;
