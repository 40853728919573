// src/components/distributer/DistributerNotification.js
import React from "react";
import { Box, Typography, Container } from "@mui/material";
import DistributorNotificationList from "./DistributorNotificationList";

const DistributerNotification = () => {
 return (
  <Container>
   <Box p={2}>
    {/* <Typography variant="h5">Notifications</Typography> */}
    <DistributorNotificationList />
   </Box>
  </Container>
 );
};

export default DistributerNotification;
