import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import NotificationItem from "./NotificationItem";
import { fetchNotifications } from "../../redux/notification/notificationActions";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { messaging, getToken, onMessage } from "../../firebase"; // Updated import

const NotificationList = () => {
  const [openModalDistributor, setOpenModalDistributor] = useState(false);
  const [openModalDriver, setOpenModalDriver] = useState(false);
  const [fcmToken, setFcmToken] = useState(null);
  const [fileNameDistributor, setFileNameDistributor] = useState("");
  const [imageSrcDistributor, setImageSrcDistributor] = useState("");
  const [fileNameDriver, setFileNameDriver] = useState("");
  const [imageSrcDriver, setImageSrcDriver] = useState("");
  const dispatch = useDispatch();
  const { notifications, loading, error } = useSelector(
    (state) => state.notification
  );

  const { user } = useSelector((state) => state.auth);

  console.log("user details", user);
  const handleFileChangeDistributor = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileNameDistributor(file.name);

      // Create a preview URL for the selected file
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrcDistributor(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChangeDriver = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileNameDriver(file.name);

      // Create a preview URL for the selected file
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrcDriver(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    dispatch(fetchNotifications());

    // Request permission and get FCM token
    getToken(messaging, { vapidKey: "YOUR_PUBLIC_VAPID_KEY" })
      .then((currentToken) => {
        if (currentToken) {
          console.log("FCM Token:", currentToken);
          setFcmToken(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.error("An error occurred while retrieving token. ", err);
      });

    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // Handle the notification payload here
    });
  }, [dispatch]);

  if (loading) {
    return <Typography>Loading notifications...</Typography>;
  }

  if (error) {
    return <Typography>Error fetching notifications: {error}</Typography>;
  }

  const handleOpenModalDistributor = () => {
    setOpenModalDistributor(true);
  };

  const handleOpenModalDriver = () => {
    setOpenModalDriver(true);
  };

  const handleCloseModalDistributor = () => setOpenModalDistributor(false);
  const handleCloseModalDriver = () => setOpenModalDriver(false);

  const handleSendNotification = async (type) => {
    if (!fcmToken) {
      console.error("FCM token is not available");
      return;
    }

    try {
      const response = await fetch(
        "https://api.janatarides.com/api/notifications/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            type,
            title: "Your Notification Title",
            message: "Your Notification Message",
            fcmToken: fcmToken,
          }),
        }
      );
      const data = await response.json();
      console.log("Notification sent:", data);
    } catch (err) {
      console.error("Error sending notification:", err);
    }
  };

  return (
    <Box p={4} bgcolor="#f5f7fa" minHeight="100vh">
      <Typography variant="h5" mb={1}>
        Notifications
      </Typography>

      {user.role === "superadmin" && (
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={4}
          py={2}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Fab
              onClick={handleOpenModalDistributor}
              color="primary"
              aria-label="add"
              sx={{
                boxShadow: 3,
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              <AddIcon />
            </Fab>
            <Typography>Distributor</Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Fab
              onClick={handleOpenModalDriver}
              color="primary"
              aria-label="add"
              sx={{
                boxShadow: 3,
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              <AddIcon />
            </Fab>
            <Typography>Driver</Typography>
          </Stack>
        </Stack>
      )}

      {notifications.map((notification, index) => (
        <NotificationItem
          key={index}
          type={notification.type}
          title={notification.title}
          message={notification.message}
          user={notification.user}
          timestamp={notification.timestamp}
        />
      ))}

      <Modal
        open={openModalDistributor}
        onClose={handleCloseModalDistributor}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          width={{ xs: "100%", md: "40%" }}
          margin={{ xs: "auto", md: "100px auto" }}
          sx={{
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            maxHeight: "80vh", // Limit height to 80% of viewport height
            overflowY: "auto", // Enable vertical scrolling
            "&::-webkit-scrollbar": {
              width: "8px", // Make scrollbar smaller
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#aaa",
              borderRadius: "10px",
            },
            "-ms-overflow-style": "none", // IE and Edge
            "scrollbar-width": "none", // Firefox
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseModalDistributor}
            aria-label="close"
            sx={{
              position: "absolute",
              top: 10,
              right: 24,
              color: "#d32f2f",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              py: 4,
              px: 5,
              backgroundColor: "#f9f9f9",
              borderRadius: 2,
            }}
          >
            <Typography fontSize={"1.4rem"} fontWeight={800} textAlign="center">
              Send Notification to Distributor
            </Typography>

            <Stack py={4} spacing={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Company Name"
                    label="Company Name"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Headline"
                    label="Headline"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Date"
                    label="Date"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Time"
                    label="Time"
                    type="time"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>

              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                multiline
                rows={2}
                InputLabelProps={{ shrink: true }}
              />

              <Button
                variant="contained"
                component="label"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                {fileNameDistributor
                  ? `File Selected: ${fileNameDistributor}`
                  : "Upload Image"}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileChangeDistributor}
                />
              </Button>
              {imageSrcDistributor && (
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <img
                    src={imageSrcDistributor}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => handleSendNotification("distributor")}
              >
                Send Notification
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>



      <Modal
        open={openModalDriver}
        onClose={handleCloseModalDriver}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          width={{ xs: "100%", md: "40%" }}
          margin={{ xs: "auto", md: "100px auto" }}
          sx={{
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            maxHeight: "80vh", // Limit height to 80% of viewport height
            overflowY: "auto", // Enable vertical scrolling
            "&::-webkit-scrollbar": {
              width: "8px", // Make scrollbar smaller
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#aaa",
              borderRadius: "10px",
            },
            "-ms-overflow-style": "none", // IE and Edge
            "scrollbar-width": "none", // Firefox
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseModalDriver}
            aria-label="close"
            sx={{
              position: "absolute",
              top: 10,
              right: 24,
              color: "#d32f2f",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              py: 4,
              px: 5,
              backgroundColor: "#f9f9f9",
              borderRadius: 2,
            }}
          >
            <Typography fontSize={"1.4rem"} fontWeight={800} textAlign="center">
              Send Notification to Driver
            </Typography>

            <Stack py={4} spacing={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Company Name"
                    label="Company Name"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Headline"
                    label="Headline"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Date"
                    label="Date"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Time"
                    label="Time"
                    type="time"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>

              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                multiline
                rows={2}
                InputLabelProps={{ shrink: true }}
              />

              <Button
                variant="contained"
                component="label"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                {fileNameDriver
                  ? `File Selected: ${fileNameDriver}`
                  : "Upload Image"}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileChangeDriver}
                />
              </Button>
              {imageSrcDriver && (
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <img
                    src={imageSrcDriver}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => handleSendNotification("driver")}
              >
                Send Notification
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>

    </Box>
  );
};

export default NotificationList;

// // src/components/NotificationList.js
// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import {
//  Box,
//  Button,
//  Fab,
//  Grid,
//  IconButton,
//  Input,
//  Modal,
//  Stack,
//  TextField,
//  Typography,
// } from "@mui/material";
// import NotificationItem from "./NotificationItem";
// import { fetchNotifications } from "../../redux/notification/notificationActions";
// import AddIcon from "@mui/icons-material/Add";
// import CloseIcon from "@mui/icons-material/Close";

// // import { fetchNotifications } from "../redux/actions/notificationActions";

// const NotificationList = () => {
//  const [openModalDistributor, setOpenModalDistributor] = useState(false);
//  const [openModalDriver, setOpenModalDriver] = useState(false);

//  const dispatch = useDispatch();

//  const { notifications, loading, error } = useSelector(
//   (state) => state.notification
//  );

//  const handleOpenModalDistributor = () => {
//   setOpenModalDistributor(true);
//  };

//  const handleOpenModalDriver = () => {
//   setOpenModalDriver(true);
//  };

//  const handleCloseModalDistributor = () => setOpenModalDistributor(false);
//  const handleCloseModalDriver = () => setOpenModalDriver(false);

//  useEffect(() => {
//   dispatch(fetchNotifications());
//  }, [dispatch]);

//  if (loading) {
//   return <Typography>Loading notifications...</Typography>;
//  }

//  if (error) {
//   return <Typography>Error fetching notifications: {error}</Typography>;
//  }

//  return (
//   <Box p={4} bgcolor="#f5f7fa" minHeight="100vh">
//    <Typography variant="h5" mb={1}>
//     Notifications
//    </Typography>

//    <Stack
//     direction={{ xs: "column", md: "row" }}
//     justifyContent="flex-start"
//     alignItems="flex-start"
//     spacing={4}
//     py={2}
//    >
//     <Stack
//      direction="row"
//      justifyContent="center"
//      alignItems="center"
//      spacing={1}
//     >
//      <Fab
//       onClick={handleOpenModalDistributor}
//       color="primary"
//       aria-label="add"
//       sx={{
//        boxShadow: 3,
//        "&:hover": {
//         backgroundColor: "#1565c0",
//        },
//       }}
//      >
//       <AddIcon />
//      </Fab>
//      <Typography>Distributor</Typography>
//     </Stack>
//     <Stack
//      direction="row"
//      justifyContent="center"
//      alignItems="center"
//      spacing={1}
//     >
//      <Fab
//       onClick={handleOpenModalDriver}
//       color="primary"
//       aria-label="add"
//       sx={{
//        boxShadow: 3,
//        "&:hover": {
//         backgroundColor: "#1565c0",
//        },
//       }}
//      >
//       <AddIcon />
//      </Fab>
//      <Typography>Driver</Typography>
//     </Stack>
//    </Stack>

//    {notifications.map((notification, index) => (
//     <NotificationItem
//      key={index}
//      type={notification.type}
//      title={notification.title}
//      message={notification.message}
//      user={notification.user}
//      timestamp={notification.timestamp}
//     />
//    ))}

//    <Modal
//     open={openModalDistributor}
//     onClose={handleCloseModalDistributor}
//     aria-labelledby="modal-title"
//     aria-describedby="modal-description"
//    >
//     <Box
//      width={{ xs: "100%", md: "40%" }}
//      margin={{ xs: "auto", md: "100px auto" }}
//      sx={{
//       bgcolor: "background.paper",
//       borderRadius: 1,
//       boxShadow: 24,
//       p: 2,
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       position: "relative",
//       overflowY: "auto",
//       "&::-webkit-scrollbar": {
//        width: "6px",
//       },
//       "&::-webkit-scrollbar-thumb": {
//        backgroundColor: "#aaa",
//        borderRadius: "10px",
//       },
//       "-ms-overflow-style": "none", // IE and Edge
//       "scrollbar-width": "none", // Firefox
//      }}
//     >
//      <IconButton
//       edge="end"
//       color="inherit"
//       onClick={handleCloseModalDistributor}
//       aria-label="close"
//       sx={{
//        position: "absolute",
//        top: 10,
//        right: 24,
//        color: "#d32f2f",
//       }}
//      >
//       <CloseIcon />
//      </IconButton>
//      <Box
//       sx={{
//        width: "100%",
//        //   maxWidth: "600px",
//        margin: "auto",
//        py: 4,
//        px: 5,
//        backgroundColor: "#f9f9f9",
//        borderRadius: 2,
//       }}
//      >
//       <Typography fontSize={"1.4rem"} fontWeight={800} textAlign="center">
//        Send Notification to Distributor
//       </Typography>

//       <Stack py={4} spacing={3}>
//        <Grid container spacing={3}>
//         <Grid item xs={12} sm={6}>
//          <TextField
//           fullWidth
//           placeholder="Title"
//           label="Title"
//           type="text"
//           variant="outlined"
//           InputLabelProps={{ shrink: true }}
//          />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//          <TextField
//           fullWidth
//           placeholder="Link"
//           label="Link"
//           type="text"
//           variant="outlined"
//           InputLabelProps={{ shrink: true }}
//          />
//         </Grid>

//         <Grid item xs={12} sm={6}>
//          <TextField
//           fullWidth
//           placeholder="Date"
//           label="Date"
//           type="date"
//           variant="outlined"
//           InputLabelProps={{ shrink: true }}
//          />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//          <TextField
//           fullWidth
//           placeholder="Time"
//           label="Time"
//           type="time"
//           variant="outlined"
//           InputLabelProps={{ shrink: true }}
//          />
//         </Grid>
//        </Grid>

//        <TextField
//         fullWidth
//         label="Description"
//         variant="outlined"
//         multiline
//         rows={4}
//         InputLabelProps={{ shrink: true }}
//        />

//        <Button
//         variant="contained"
//         component="label"
//         color="primary"
//         fullWidth
//         sx={{ mt: 2 }}
//        >
//         Upload Image
//         <input type="file" hidden />
//        </Button>

//        <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
//         Send Notification
//        </Button>
//       </Stack>
//      </Box>
//     </Box>
//    </Modal>

//    <Modal
//     open={openModalDriver}
//     onClose={handleCloseModalDriver}
//     aria-labelledby="modal-title"
//     aria-describedby="modal-description"
//    >
//     <Box
//      width={{ xs: "100%", md: "40%" }}
//      margin={{ xs: "auto", md: "100px auto" }}
//      sx={{
//       bgcolor: "background.paper",
//       borderRadius: 1,
//       boxShadow: 24,
//       p: 2,
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       position: "relative",
//       overflowY: "auto",
//       "&::-webkit-scrollbar": {
//        width: "6px",
//       },
//       "&::-webkit-scrollbar-thumb": {
//        backgroundColor: "#aaa",
//        borderRadius: "10px",
//       },
//       "-ms-overflow-style": "none", // IE and Edge
//       "scrollbar-width": "none", // Firefox
//      }}
//     >
//      <IconButton
//       edge="end"
//       color="inherit"
//       onClick={handleCloseModalDriver}
//       aria-label="close"
//       sx={{
//        position: "absolute",
//        top: 10,
//        right: 24,
//        color: "#d32f2f",
//       }}
//      >
//       <CloseIcon />
//      </IconButton>
//      <Box
//       sx={{
//        width: "100%",
//        //   maxWidth: "600px",
//        margin: "auto",
//        py: 4,
//        px: 5,
//        backgroundColor: "#f9f9f9",
//        borderRadius: 2,
//       }}
//      >
//       <Typography fontSize={"1.4rem"} fontWeight={800} textAlign="center">
//        Send Notification to Driver
//       </Typography>

//       <Stack py={4} spacing={3}>
//        <Grid container spacing={3}>
//         <Grid item xs={12} sm={6}>
//          <TextField
//           fullWidth
//           placeholder="Title"
//           label="Title"
//           type="text"
//           variant="outlined"
//           InputLabelProps={{ shrink: true }}
//          />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//          <TextField
//           fullWidth
//           placeholder="Link"
//           label="Link"
//           type="text"
//           variant="outlined"
//           InputLabelProps={{ shrink: true }}
//          />
//         </Grid>

//         <Grid item xs={12} sm={6}>
//          <TextField
//           fullWidth
//           placeholder="Date"
//           label="Date"
//           type="date"
//           variant="outlined"
//           InputLabelProps={{ shrink: true }}
//          />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//          <TextField
//           fullWidth
//           placeholder="Time"
//           label="Time"
//           type="time"
//           variant="outlined"
//           InputLabelProps={{ shrink: true }}
//          />
//         </Grid>
//        </Grid>

//        <TextField
//         fullWidth
//         label="Description"
//         variant="outlined"
//         multiline
//         rows={4}
//         InputLabelProps={{ shrink: true }}
//        />

//        <Button
//         variant="contained"
//         component="label"
//         color="primary"
//         fullWidth
//         sx={{ mt: 2 }}
//        >
//         Upload Image
//         <input type="file" hidden />
//        </Button>

//        <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
//         Send Notification
//        </Button>
//       </Stack>
//      </Box>
//     </Box>
//    </Modal>
//   </Box>
//  );
// };

// export default NotificationList;
