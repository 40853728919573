// components/admin/AdminNotifications.js
import React from "react";
import NotificationList from "../notifications/NotificationList";

function AdminNotifications() {
 return (
  <div>
   <h2>Notifications</h2>
   <ul>
    <NotificationList />
   </ul>
  </div>
 );
}

export default AdminNotifications;
